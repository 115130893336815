/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import MainPage from "../../MainPage";
import { Divider, Table, Tooltip, message } from "antd";
import {
  CallDeclineIcon,
  CallIcon,
  CheckIcon,
  ContactImage,
  MissedCallIcon,
  NewNotificationIcon,
} from "../Common/Icons";
import Loading from "../Common/Loading";
import {
  NotificationTypes,
  PhotoResolution,
  sessionType,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { startVideoCall } from "@commscopemycloud/humaui/Store/videoCallStore";
import { setNotificationLastRead } from "@commscopemycloud/humaui/Utilities/StoreUtilities";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import {
  KnownActionsList,
  isActionAllowed,
  checkRoleAccess,
  Access,
} from "@commscopemycloud/humaui/Utilities/AccessControl";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import useSchedule from "../Hooks/useSchedule";
import { CalendarEventIcon } from "../Common/Icons/CalendarEventIcon";
import { processScheduleNotification } from "./ListNotificationUtil";
import { PersonIcon } from "../Common/Icons/PersonIcon";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { getPermissions } from "@commscopemycloud/humaui/Store/permisssionsStore";
import { getProfilePicUrlFromArray } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { CompassOutlined } from "@ant-design/icons";
import { allowAutoVideoCall } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import useUserInfo from "../Hooks/useUserInfo";
import AutoCallModal from "../Modals/AutoCallModal";
import { getModalDataForCall } from "../Modals/AutoCallModalDataUtils";

const CHECK_INTERVAL_MS = 20000;
const SHOW_STATUS_BEFORE_MINUTES = 15;
const ONGOING_MEETING_DISPLAY_TIME_HOURS = 4;
const SHOW_JOIN_STATUS_TILL_MINUTES = ONGOING_MEETING_DISPLAY_TIME_HOURS * 60;

const ListNotification = ({
  header,
  notifications,
  fetchingNotifications,
  onActionClick,
  openSubMenu = false,
  hubRecord,
  buttonSelected,
  loginUserTimeformat,
}) => {
  const dispatch = useDispatch();
  const trans = useSelector(translator);
  const userApi = useSelector((state) => state.apis.userApi);
  const currentUser = useSelector(getCurrentUser);
  // const useruuid = useMemo(() => currentUser?.useruuid, [currentUser]);

  // Choose useruuid based on hubRecord presence
  const useruuid = useMemo(() => {
    if (hubRecord) {
      return hubRecord.useruuid;
    } else {
      return currentUser?.useruuid;
    }
  }, [currentUser?.useruuid, hubRecord]);

  const latesetNotificationTimestamp = useRef(null);
  const permissions = useSelector(getPermissions());
  const allowCallContact = useMemo(
    () =>
      isActionAllowed(
        currentUser?.usertype,
        KnownActionsList.call_contacts,
        permissions
      ),
    [currentUser?.usertype, permissions]
  );
  const [, scheduleList, fetchSchedule] = useSchedule();
  const [notificationsList, setNotificationsList] = useState(null);
  const [isCallModalOpen, setIsCallModalOpen] = useState(false);
  const [selectedHubRecord, setSelectedHubRecord] = useState(null);
  const [userInfo, , fetchUserInfo] = useUserInfo({
    useruuid: selectedHubRecord?.useruuid,
  });
  const checkAutoCall = useMemo(
    () => checkRoleAccess(Access.videocall.allowAutoCall),
    [currentUser?.roles]
  );

  const allowCall = (hub) =>
    currentUser.useruuid !== hub.useruuid &&
    isActionAllowed(hub.rolename, KnownActionsList.call_contacts, permissions);

  const allowAutoCall = (hub) =>  userInfo?.autoacceptcall && hub?.rolename !== 'Social';
    // allowCall(hub) && allowAutoVideoCall(hub) && checkAutoCall;

  const videoCallNotificationModalVisible = useSelector(
      (state) => state.notification.videoCallNotificationModalVisible
  );
   
  useEffect(() => {
      if (videoCallNotificationModalVisible) {
        setIsCallModalOpen(false);
      }
  }, [videoCallNotificationModalVisible]);

  const handleCallModal = () => {
    setIsCallModalOpen(true);
  };

  const cancelCallModal = () => {
    setIsCallModalOpen(false);
  }


  // Event data for current day only will be shown. For next day data notifications have to be re-opened.
  useEffect(() => {
    fetchSchedule(useruuid, new Date(new Date().setHours(0, 0, 0, 0)));
  }, []);

  useEffect(() => {
    if (notifications.length > 0) {
      latesetNotificationTimestamp.current =
        notifications[0].utc_timestamp_inms;
    }
  }, [notifications]);

  useEffect(() => {
    return () => {
      moment(latesetNotificationTimestamp).isValid() &&
        setNotificationLastRead(latesetNotificationTimestamp.current);
    };
  }, []);

  //  Sample object format coming as part of notiifcations or scheduled events
  //   data = {
  //    key: unique_identifier,
  //    type: <NotificationTypes>,
  //    datetime: String to be displayed in date column,
  //    description_row_1: String to be displayed in description column first row,
  //    description_row_2: String to be displayed in description column second row,
  //    status: String to be displayed in column column first row,
  //    display_event_time: Used for sorting,
  //    otherUserInfo: Holds additional data object as per type of data,
  // };

  useEffect(() => {
    let mList = [];
    let processedScheduleList = [];
    if (notifications !== null) {
      notifications.forEach((notification) => {
        if (notification.type === NotificationTypes.newContact) {
          fetchData(notification.key, notification.otherUserInfo);
        }
      });
    }
    if (scheduleList != null)
      processedScheduleList = processScheduleNotification(
        scheduleList,
        loginUserTimeformat
      );
    mList = [...processedScheduleList, ...notifications];
    setNotificationsList(mList);
  }, [scheduleList, notifications, loginUserTimeformat]);

  useEffect(() => {
    let interval;
    updateStatus();
    interval = setInterval(() => {
      updateStatus();
    }, CHECK_INTERVAL_MS);

    return () => {
      if (interval !== undefined) clearInterval(interval);
    };
  }, [notificationsList]);

  const truncateString = (str, maxLength = 10) => {
    if (!str) return "";
    if (str.length <= maxLength) return str;
    return str.slice(0, maxLength) + "...";
  };
  // To be generalized as useHook
  const fetchData = (key, mUseruuid) => {
    const errorCallback = (error) => {
      if (error.message === "Not Found") {
        console.warn(`User with UUID ${mUseruuid} not found, ignoring.`);
        setNotificationsList((prevState) => prevState.filter((item) => item.key !== key));
        return;
      }
      message.error("Error fetching user information!");
      console.error("Error fetching user information:", error);
    };
    const successCallback = (data) => {
      setNotificationsList((prevState) =>
        prevState.map((item) => {
          if (item.key === key) {
            if (item.description_row_1.includes(data?.user?.firstname) || !data?.user?.firstname) return item;
            let obj = { ...item };
            obj.description_row_1 = (truncateString(data?.user?.firstname)) + " " + (truncateString(data?.user?.lastname)) + " has a " + obj.description_row_1;
            obj.profile_pic_1 = getProfilePicUrlFromArray(data?.user?.resources, PhotoResolution.R64);
            obj.otherUserInfo = data?.user;
            return obj;
          } else return item;
        })
      );
    };
    try {
      if (!mUseruuid) return;
      userApi.getUserById(
        mUseruuid,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };
  const modalDataForCall = useMemo(() =>
    getModalDataForCall(selectedHubRecord, cancelCallModal, dispatch,trans),
    [selectedHubRecord, cancelCallModal, dispatch,trans]
  );

  const updateStatus = () => {
    const now = moment();
    if (notificationsList !== null) {
      let isChanged = false;
      const removeItemKeys = [];
      const newList = notificationsList
        .map((obj) => {
          if (obj.type === NotificationTypes.scheduledEvent) {
            let newObj = { ...obj };
            const eventTime = moment(obj.display_event_time, "HH:mm:ss");
            const diff = eventTime.diff(now, "minute");
            if (
              diff === SHOW_STATUS_BEFORE_MINUTES &&
              obj.status !== "About to start"
            ) {
              isChanged = true;
              newObj.status = "About to start";
            } else if (
              diff < 1 &&
              diff >= -SHOW_JOIN_STATUS_TILL_MINUTES &&
              ((obj.otherUserInfo?.event_type === sessionType.schedule &&
                obj.status !== "Join") ||
                (obj.otherUserInfo?.event_type === sessionType.zoom &&
                  obj.status !== "Zoom Call"))
            ) {
              isChanged = true;
              obj.otherUserInfo?.event_type === sessionType.zoom
                ? (newObj.status = "Zoom Call")
                : (newObj.status = "Join");
            } else if (diff < -SHOW_JOIN_STATUS_TILL_MINUTES) {
              isChanged = true;
              removeItemKeys.push(obj.key);
            }
            return newObj;
          } else return obj;
        })
        .filter((obj) => !removeItemKeys.includes(obj.key));
      if (isChanged) setNotificationsList(newList);
    }
  };

  const columns = [
    {
      title: "ProfilePic",
      dataIndex: "profile_pic_1",
      width: "60px",
      render: (pic, record) =>
        record?.type === NotificationTypes.scheduledEvent ||
        record?.type === NotificationTypes.newEvent ? (
          <></>
        ) : pic ? (
          <img
            src={pic}
            className="user-profile-pic user-profile-image"
            alt=""
          />
        ) : (
          <ContactImage className="user-profile-pic" />
        ),
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "32px",
      render: (type) =>
        type === NotificationTypes.missedCall ? (
          <div className="type_icon red">
            {<MissedCallIcon style={{ width: "23px", height: "16px" }} />}
          </div>
        ) : type === NotificationTypes.declinedCall ? (
          <div className="type_icon grey">
            {
              <CallDeclineIcon
                style={{
                  marginRight: "2px",
                  marginTop: "2px",
                  width: "22px",
                  height: "22px",
                }}
              />
            }
          </div>
        ) : type === NotificationTypes.scheduledEvent ||
          type === NotificationTypes.newEvent ? (
          <div className="type_icon blue">
            {
              <CalendarEventIcon
                style={{ stroke: "white", width: "22px", height: "22px" }}
              />
            }
          </div>
        ) : type === NotificationTypes.newContact ? (
          <div className="type_icon blue">
            {
              <PersonIcon
                style={{ stroke: "white", width: "22px", height: "22px" }}
              />
            }
          </div>
        ) : null,
    },
    {
      title: "Description",
      dataIndex: "type",
      render: (_, { description_row_1, description_row_2 }) => (
        <div className="flex-column">
          <span className="description">{description_row_1}</span>
          <span>{description_row_2}</span>
        </div>
      ),
    },
    {
      title: "GroupProfilePic",
      dataIndex: "profile_pic_2",
      width: "60px",
      render: (pic, record) =>
        record?.type !== NotificationTypes.newContact ? (
          <></>
        ) : pic ? (
          <img
            src={pic}
            className="user-profile-pic user-profile-image"
            alt=""
          />
        ) : (
          <ContactImage className="user-profile-pic" />
        ),
    },
    {
      title: "DateTime",
      dataIndex: "datetime",
      render: (_, record) => {
        const NotificationTimestamp =
          record?.utc_timestamp_inms || record?.otherUserInfo?.event_timestamp;
        const date = moment(NotificationTimestamp);
        const isToday = moment().isSame(date, "day");
        const isYesterday = moment().subtract(1, "days").isSame(date, "day");
        if (isToday) {
          return `Today, ${date.format(
            loginUserTimeformat["is24hoursformat"] ? "HH:mm" : "h:mm A"
          )}`;
        } else if (isYesterday) {
          return `Yesterday, ${date.format(
            loginUserTimeformat["is24hoursformat"] ? "HH:mm, MMMM D" : "h:mm A, MMMM D"
          )}`;
        } else {
          return date.format(
            loginUserTimeformat["is24hoursformat"] ? "HH:mm, MMMM D" : "h:mm A, MMMM D"
          );
        }
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "180px",
      render: (data, record) =>
        data ? (
          record.type === NotificationTypes.scheduledEvent ? (
            <div className="status">
              <span
                className="badge yellow"
                onClick={() => {
                  if (record.otherUserInfo?.event_type === sessionType.schedule)
                    dispatch(
                      startVideoCall({
                        meeting_id: record.otherUserInfo?.schedule_eventuuid,
                        eventInfo: record.otherUserInfo,
                      })
                    );
                  else if (
                    record.otherUserInfo?.event_type === sessionType.zoom
                  )
                    window.open(record.otherUserInfo?.meeting_url);
                }}
              >
                {data}
              </span>
            </div>
          ) : (
            <div className="status">
              <span className="badge">{data}</span>
            </div>
          )
        ) : (
          <></>
        ),
    },
    {
      title: "",
      dataIndex: "actions",
      width: "175px",
      render: (_, record) => (
        <div className="entity-actions-container">
          <Divider type="vertical" className="divider" />
          {allowCallContact &&
            (record.type === NotificationTypes.missedCall ||
              record.type === NotificationTypes.declinedCall ||
              record.type === NotificationTypes.newContact) && (
              <Tooltip title={trans("VIDEOCALL")}>
                <div
                  className="entity-action"
                  onClick={() => {
                    setSelectedHubRecord(record.otherUserInfo);
                    allowAutoCall(record.otherUserInfo) 
                    ? handleCallModal() 
                    : dispatch(startVideoCall({ callees: [record.otherUserInfo] }));
                  }}
                >
                  <CallIcon className="action-icon" />
                </div>
              </Tooltip>
            )}
          <div className="empty-action" />
        </div>
      ),
    },
  ];

  const headerSection = () => {
    return (
      <div className="notification-header">
        <div className="notification-title">
          <div className="hub-icon"><NewNotificationIcon className="hub-notification-icon" /></div>
            <div className="notification-text" >{trans("NOTIFICATIONS")}</div>
        </div>
      </div>
     );
  }; 

  return (
    <>
    {isCallModalOpen && <AutoCallModal data={modalDataForCall}/>}
    <MainPage header={header} hasSider={openSubMenu ? false : true} noModals={openSubMenu ? true : false} buttonSelected="notification">
      <div className="notifications-list-wrapper page-content-wrapper custom-page-wrapper">
        {fetchingNotifications ? (
          <div className="page-wrapper">
            <Loading message="Fetching notifications" />
          </div>
        ) : notificationsList?.length === 0 ? (
          <div className="note-box">
             {openSubMenu ? headerSection(): ""}
            <CheckIcon style={{ marginRight: "10px" }} />
            <span>{trans("NONOTIFICATIONS")}</span>
          </div>
        ) : (
          <div className="page-wrapper">
           {openSubMenu ? headerSection(): ""}
            <Table
              showHeader={false}
              dataSource={notificationsList}
              columns={columns}
              loading={fetchingNotifications}
              bordered={false}
              pagination={false}
              className="entity-table notifications-table"
            />
          </div>
        )}
      </div>
    </MainPage>
    </>
  );
};

ListNotification.propTypes = {
  header: PropTypes.arrayOf(PropTypes.object),
  notifications: PropTypes.arrayOf(PropTypes.object),
  fetchingNotifications: PropTypes.bool,
  onActionClick: PropTypes.func,
};

export default ListNotification;
