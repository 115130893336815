import React from "react";

export const GlucoseMeterIcon = (props) => {
  const { className = "", style = {}, onClick } = props;

  return (
    <svg
      className={className}
      style={style}
      onClick={onClick}
      width="60"
      height="60"
      viewBox="0 -8 18 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_2047_1477" fill="white">
        <rect x="5.18726" y="14.2998" width="4.27528" height="5.70037" rx="1" />
      </mask>
      <rect
        x="5.18726"
        y="14.2998"
        width="4.27528"
        height="5.70037"
        rx="1"
        stroke="#113E57"
        strokeWidth="3.2"
        mask="url(#path-1-inside-1_2047_1477)"
      />
      <path
        d="M3.04956 12.1621H4.47465"
        stroke="#113E57"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M6.61243 12.1621H8.03752"
        stroke="#113E57"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M10.1752 12.1621H11.6003"
        stroke="#113E57"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <mask id="path-5-inside-2_2047_1477" fill="white">
        <rect x="2.69336" y="2.18652" width="9.2631" height="7.83801" rx="1" />
      </mask>
      <rect
        x="2.69336"
        y="2.18652"
        width="9.2631"
        height="7.83801"
        rx="1"
        stroke="#113E57"
        strokeWidth="3.2"
        mask="url(#path-5-inside-2_2047_1477)"
      />
      <path
        d="M7.32477 5.03662C6.10056 6.77083 6.67454 7.17426 7.32477 7.17426C7.94128 7.17426 8.54482 6.75415 7.32477 5.03662Z"
        fill="#113E57"
        stroke="#113E57"
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M11.6002 1.1606H3.04965C2.26259 1.1606 1.33976 2.11182 1.33976 2.89887V14.2996C1.33976 15.0867 2.26259 15.9473 3.04965 15.9473H5.19564V17.1498H3.04965C1.47553 17.1498 0.199463 15.8737 0.199463 14.2996V2.89887C0.199463 1.32476 1.47553 2.10287e-05 3.04965 2.10287e-05H11.6002C13.1743 2.10287e-05 14.4504 1.32476 14.4504 2.89887V14.2996C14.4504 15.8737 13.1743 17.1498 11.6002 17.1498H9.45632V15.9473H11.6002C12.3873 15.9473 13.3218 15.0867 13.3218 14.2996V2.89887C13.3218 2.11182 12.3873 1.1606 11.6002 1.1606Z"
        fill="#113E57"
      />
    </svg>
  );
};
