/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { UserOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Input, Upload, message, Select, Switch } from "antd";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import {
  PhotoResolution,
  Units,
  DefaultUnit,
} from "@commscopemycloud/humaui/Utilities/Constants";
import Pencil from "../../../static/icons/icon_pencil.svg";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import {
  selectProviderData,
  setProviderData,
} from "@commscopemycloud/humaui/Store/providerDataStore";
import MainPage from "../../MainPage";
import useUserInfo from "../Hooks/useUserInfo";
import Loading from "../Common/Loading";
import {
  getProfilePicUrl,
  getBase64,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { getProfilePics } from "@commscopemycloud/humaui/Store/dataStore";
import {
  DashboardIcon,
  NewGearIcon,
  NewCloseIcon,
  NewUpdateIcon,
} from "../Common/Icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { updatePassword } from "@commscopemycloud/humaui/Services/KeycloakService";
import NewCustomButton, { ButtonTypes } from "../Common/NewCustomButton";

/**
 * This component uses "autoacceptcall" (array of roles) plus "revokeautocallroles" (array of roles).
 * Example final payload structure for auto-call toggles:
 * {
 *   autoacceptcall: ["Principal", "Care"],      // the user specifically allows these roles
 *   revokeautocallroles: ["Manage", "Social"],  // the user disallows these roles
 * }
 */
const EditUserProfile = ({
  parentBread,
  hubRecord,
  fetchUserHubs,
  onActionClick,
  handleTabChange,
  userHubCall = false,
  openSubMenu = false,
  showContactingStaff,
  filteredUserHubsData,
  successCallBack = () => {},
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.split("/")[1]?.toLowerCase();
  const [form] = Form.useForm();

  // Access APIs, translation, current user
  const trans = useSelector(translator);
  const userApi = useSelector((state) => state.apis.userApi);
  const currentUser = useSelector(getCurrentUser);

  // Figure out which user's data we're editing
  const useruuid = useMemo(
    () => (hubRecord ? hubRecord.useruuid : currentUser.useruuid),
    [hubRecord, currentUser]
  );

  // Fetch user info
  const [userInfo, fetchingUserInfo, fetchData] = useUserInfo({ useruuid });
  console.log('userInfo-->77', userInfo);
  const currentUserExist = currentUser.useruuid === userInfo.useruuid;

  // For toggles that mirror staffcontact
  const allowedPermissions = 4 - (userInfo?.revoke?.length || 0);
  const toggleUpdateOnToggleAllow =
    allowedPermissions === 0 || allowedPermissions === 4;

  // Basic UI states
  const [areButtonsDisabled, setAreButtonsDisabled] = useState(true);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [isUpdatingData, setIsUpdatingData] = useState(false);

  // Profile photo
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotourl, setProfilePhotoUrl] = useState("");
  const [isProfilePhotoUpdated, setIsProfilePhotoUpdated] = useState(false);

  // 24-hour format
  const [isTimeFormatEnabled, setIsTimeFormatEnabled] = useState(false);

  // ----- NEW auto-call toggles (like staffcontact) -----
  const [autoCallAllow, setAutoCallAllow] = useState(false);
  const [autoCallPrincipal, setAutoCallPrincipal] = useState(false);
  const [autoCallManage, setAutoCallManage] = useState(false);
  const [autoCallCare, setAutoCallCare] = useState(false);
  const [autoCallSocial, setAutoCallSocial] = useState(false);

  // Remember initial states for these toggles
  const [initialAutoCallAllow, setInitialAutoCallAllow] = useState(false);
  const [initialAutoCallPrincipal, setInitialAutoCallPrincipal] = useState(false);
  const [initialAutoCallManage, setInitialAutoCallManage] = useState(false);
  const [initialAutoCallCare, setInitialAutoCallCare] = useState(false);
  const [initialAutoCallSocial, setInitialAutoCallSocial] = useState(false);

  // Existing staffContact toggles
  const [allow, setAllow] = useState(true);
  const [accountPrincipal, setAccountPrincipal] = useState(false);
  const [manage, setManage] = useState(true);
  const [care, setCare] = useState(true);
  const [social, setSocial] = useState(true);

  // Remember initial staffContact states
  const [isAllow, setIsAllow] = useState(false);
  const [initialAccountPrincipal, setInitialAccountPrincipal] = useState(false);
  const [initialManage, setInitialManage] = useState(false);
  const [initialCare, setInitialCare] = useState(false);
  const [initialSocial, setInitialSocial] = useState(false);

  // Normal form data
  const [formData, setFormData] = useState({});
  const formRef = useRef();

  // Get provider config
  const providerData = useSelector(selectProviderData);
  const serviceProviderApi = useSelector((state) => state.apis.serviceProviderApi);
  const providerId = useSelector(getCurrentUser)?.providerid;

  // For images
  const resourcesMap = useSelector(getProfilePics());
  const photoUrl = getProfilePicUrl(useruuid, resourcesMap, PhotoResolution.R256);

  // Default fallback locale/timezone
  const defaultLocaleVal =
    providerData?.supportedlocales?.[0]?.slice(-6, -1) || "en-US";
  const defaultTimezoneVal =
    providerData?.supportedtimezones?.find((tz) => tz[defaultLocaleVal])?.[
      defaultLocaleVal
    ] || "America/Los_Angeles (PDT)";

  // BreadCrumb logic
  const createSearchParams = () => {
    const currentSearchParams = new URLSearchParams(location.search);
    const paramsMap = new Map(currentSearchParams.entries());
    const uniqueSearchParams = new URLSearchParams(paramsMap);
    return uniqueSearchParams.toString();
  };

  // Tab change
  const changeTabKey = (key) => {
    handleTabChange(key);
  };

  // Hook to load Provider data if missing
  useEffect(() => {
    if (!providerData) {
      fetchProviderData();
    }
  }, [providerData]);

  const fetchProviderData = () => {
    const errorCallback = (error) => {
      console.error(error);
    };
    const successCallback = (data) => {
      dispatch(setProviderData(data?.serviceProvider));
    };

    try {
      serviceProviderApi.getServiceproviderById(
        providerId,
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  // Once we have userInfo, fill form fields & toggles
  useEffect(() => {
    const obj = {
      firstName: userInfo?.firstname ?? "",
      lastName: userInfo?.lastname ?? "",
      timeZone: userInfo?.timezone ?? defaultTimezoneVal[0] ?? "",
      units: userInfo?.measurement ?? DefaultUnit.value ?? "",
      latitude: userInfo?.latitude ?? "",
      longitude: userInfo?.longitude ?? "",
      postalCode: userInfo?.postalcode ?? "",
      email: userInfo?.email ?? "",
      staffRole: userInfo?.staffrole ?? "",
      locale: userInfo?.locale ?? defaultLocaleVal,
      is24hoursformat: userInfo?.is24hoursformat ?? false,
    };

    setFormData(obj);
    form.setFieldsValue(obj);
    setProfilePhoto(userInfo.photo);
    setProfilePhotoUrl(photoUrl);

    // staffContact toggles
    const isAllowEnabled = (userInfo?.revoke?.length || 0) < 4;
    setIsAllow(isAllowEnabled);
    setAllow(isAllowEnabled);

    const isAccountPrincipal = !userInfo?.revoke?.includes("Principal");
    const isManageEnabled = !userInfo?.revoke?.includes("Manage");
    const isCareEnabled = !userInfo?.revoke?.includes("Care");
    const isSocialEnabled = !userInfo?.revoke?.includes("Social");

    setInitialAccountPrincipal(isAccountPrincipal);
    setInitialManage(isManageEnabled);
    setInitialCare(isCareEnabled);
    setInitialSocial(isSocialEnabled);

    setAccountPrincipal(isAccountPrincipal);
    setManage(isManageEnabled);
    setCare(isCareEnabled);
    setSocial(isSocialEnabled);

    // 24-hr format
    setIsTimeFormatEnabled(userInfo?.is24hoursformat ?? false);

    // auto-call roles (from userInfo.autoacceptcall, if present)
    const autoCallRoles = userInfo?.allowautocallroles || [];
    console.log("userInfo", userInfo);
    const hasAnyAutoCallRole = autoCallRoles.length > 0;

    setAutoCallAllow(hasAnyAutoCallRole);
    setInitialAutoCallAllow(hasAnyAutoCallRole);

    setAutoCallPrincipal(autoCallRoles.includes("Principal"));
    setAutoCallManage(autoCallRoles.includes("Manage"));
    setAutoCallCare(autoCallRoles.includes("Care"));
    setAutoCallSocial(autoCallRoles.includes("Social"));

    setInitialAutoCallPrincipal(autoCallRoles.includes("Principal"));
    setInitialAutoCallManage(autoCallRoles.includes("Manage"));
    setInitialAutoCallCare(autoCallRoles.includes("Care"));
    setInitialAutoCallSocial(autoCallRoles.includes("Social"));
  }, [userInfo, photoUrl, form, defaultLocaleVal, defaultTimezoneVal]);

  // Manage locale in a local state
  const localeValue = useMemo(() => {
    // If userInfo locale is supported, use it; else fallback
    if (
      providerData?.supportedlocales &&
      providerData.supportedlocales.some((loc) => loc.includes(userInfo?.locale))
    ) {
      return userInfo?.locale;
    } else {
      return defaultLocaleVal;
    }
  }, [userInfo?.locale, providerData, defaultLocaleVal]);

  const [locale, setLocale] = useState(localeValue);

  // If user navigates to the "profile" route, show the breadCrumb differently
  let header = [];
  if (path.includes("profile")) {
    header = (parentBread || []).concat([
      {
        label: "",
        icon: (
          <Link to="/">
            <DashboardIcon />
          </Link>
        ),
      },
      {
        label: trans("EDITPROFILE"),
        icon: <UserOutlined />,
      },
    ]);
  } else {
    header = (parentBread || []).concat([
      {
        label: trans("EDITPROFILE"),
        icon: <UserOutlined />,
      },
    ]);
  }

  // For updating the user’s profile photo in a separate call
  const onProfilePhotoUpdate = (photo) => {
    const errorCallback = (error) => {
      setIsUpdatingProfile(false);
      setIsProfilePhotoUpdated(false);
      console.error("Error uploading picture:", error);
      message.error("Error uploading picture");
      fetchData();
    };
    const successCallback = (data) => {
      setIsUpdatingProfile(false);
      setAreButtonsDisabled(true);
      setIsProfilePhotoUpdated(false);
      message.success("Profile picture updated successfully");
      fetchData();
    };
    try {
      userApi.upload(
        useruuid,
        { profileimage: photo },
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  // Final "Update" click
  const onUpdateHandler = () => {
    const errorCallback = (error) => {
      setIsUpdatingProfile(false);
      console.error("Error updating profile details:", error);
      message.error("Error updating profile details");
    };

    const successCallback = (data) => {
      if (isProfilePhotoUpdated) {
        onProfilePhotoUpdate(profilePhoto);
      } else {
        fetchUserHubs && fetchUserHubs();
        setIsUpdatingProfile(false);
        setAreButtonsDisabled(true);
        fetchData();
        successCallBack();
      }
      setIsUpdatingData(false);
      message.success("Profile details updated successfully");

      if (currentUserExist) {
        navigate("/" + "?" + createSearchParams());
      } else if (userHubCall) {
        onActionClick(null, null, openSubMenu, true)();
      } else {
        changeTabKey("schedule");
      }
    };

    // If we already updated the photo, or not
    if (isProfilePhotoUpdated && !isUpdatingData) {
      onProfilePhotoUpdate(profilePhoto);
    } else {
      form
        .validateFields([
          "firstName",
          "lastName",
          "postalCode",
          "timeZone",
          "units",
          "staffRole",
          "locale",
          "is24hoursformat",
        ])
        .then((values) => {
          try {
            setIsUpdatingProfile(true);

            // Basic user object
            const user = {
              useruuid: useruuid,
              firstname: values.firstName.trim(),
              lastname: values.lastName.trim(),
              postalcode: values.postalCode,
              timezone: values.timeZone,
              measurement: values.units,
              staffrole: values.staffRole,
              locale: values.locale,
              is24hoursformat: isTimeFormatEnabled,
            };

            // Build arrays for staffcontact
            const allowList = [];
            const revokeList = [];

            if (allow) {
              if (accountPrincipal) allowList.push("Principal");
              if (manage) allowList.push("Manage");
              if (care) allowList.push("Care");
              if (social) allowList.push("Social");
            } else {
              // If main "Allow" is off for staffcontact
              revokeList.push("Principal", "Manage", "Care", "Social");
            }
            // If allow is on but a subtoggle is off, revoke that role
            if (allow) {
              if (!accountPrincipal) revokeList.push("Principal");
              if (!manage) revokeList.push("Manage");
              if (!care) revokeList.push("Care");
              if (!social) revokeList.push("Social");
            }

            // Build arrays for autoCall toggles
            // According to your new format:
            // autoacceptcall: []
            // revokeautocallroles: []
            const autoCallArray = [];
            const revokeAutoCallArray = [];

            if (autoCallAllow) {
              // If the main toggle is ON, push sub roles that are ON into autoCallArray
              if (autoCallPrincipal) autoCallArray.push("Principal");
              if (autoCallManage) autoCallArray.push("Manage");
              if (autoCallCare) autoCallArray.push("Care");
              if (autoCallSocial) autoCallArray.push("Social");

              // For any OFF sub toggles, revoke them
              if (!autoCallPrincipal) revokeAutoCallArray.push("Principal");
              if (!autoCallManage) revokeAutoCallArray.push("Manage");
              if (!autoCallCare) revokeAutoCallArray.push("Care");
              if (!autoCallSocial) revokeAutoCallArray.push("Social");
            } else {
              // If main toggle is OFF => revoke ALL
              revokeAutoCallArray.push("Principal", "Manage", "Care", "Social");
            }

            const payload = {
              ...user,
              // staffcontact
              allow: allowList,
              revoke: revokeList,

              // autoCall with the new naming
              allowautocallroles: autoCallArray,
              revokeautocallroles: revokeAutoCallArray,
            };

            userApi.updateUser(
              payload,
              apiCallback({
                translator: trans,
                failCallback: errorCallback,
                errorCallback,
                successCallback,
              })
            );
          } catch (error) {
            errorCallback(error);
          }
        })
        .catch(() => {
          message.error("Please fill in all required fields.");
        });
    }
  };

  // Handling file upload for the avatar
  const handleUploadChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (url) => {
        setIsProfilePhotoUpdated(true);
        setAreButtonsDisabled(false);
        setProfilePhoto(info.file.originFileObj);
        setProfilePhotoUrl(url);
      });
    }
  };

  // Cancel = revert all changes
  const onCancelHandler = () => {
    form.resetFields();
    setProfilePhoto(userInfo.photo);
    setProfilePhotoUrl(photoUrl);
    setAreButtonsDisabled(true);

    if (currentUserExist) {
      navigate("/" + "?" + createSearchParams());
    } else if (userHubCall) {
      onActionClick(null, null, openSubMenu, true)();
    } else {
      changeTabKey("schedule");
    }
  };

  // For "Change Password"
  const onChangePasswordHandler = () => {
    updatePassword();
  };

  // Render the top heading
  const renderHeaderSection = () => {
    return (
      <div className="edit-profile-header">
        <div className="edit-profile-title-container">
          <NewGearIcon
            className="edit-icon"
            style={{ width: "36px", height: "36px", fill: "#f5f5f5" }}
          />
          <p>{trans("EDITPROFILE")}</p>
        </div>
      </div>
    );
  };

  const renderProfileAvatar = () => {
    return (
      <Upload
        onChange={handleUploadChange}
        showUploadList={false}
        customRequest={({ file, onSuccess }) => {
          setTimeout(() => {
            onSuccess("ok");
          }, 0);
        }}
        accept=".png,.jpg,.jpeg,.gif,.jfif,.webp,.svg,.tiff"
      >
        {profilePhotourl ? (
          <div className="profile-avatar-container">
            <img
              className={`profile-avatar ${
                profilePhotourl ? "profile-avatar-border" : ""
              }`}
              src={profilePhotourl}
              alt="Avatar"
            />
            <img className="profile-pencil" src={Pencil} alt="Edit" />
          </div>
        ) : (
          <div className="emptyprofile">
            <div className="empty-profile-container">
              <PlusOutlined />
            </div>
            <img className="profile-pencil" src={Pencil} alt="Edit" />
          </div>
        )}
      </Upload>
    );
  };

  // Called on ANY form field change; we decide if the Update button is active
  const onFormChangeHandler = () => {
    const currentValues = form.getFieldsValue();

    const trimmedFirstName = currentValues.firstName
      ? currentValues.firstName.trim()
      : "";
    const trimmedLastName = currentValues.lastName
      ? currentValues.lastName.trim()
      : "";

    const isFirstNameChanged = hasSignificantChange(
      currentValues.firstName,
      formData.firstName
    );
    const isLastNameChanged = hasSignificantChange(
      currentValues.lastName,
      formData.lastName
    );

    const isFirstNameEmpty = trimmedFirstName === "";
    const isLastNameEmpty = trimmedLastName === "";

    const isOtherFieldsChanged = Object.keys(currentValues).some((key) => {
      if (key === "firstName" || key === "lastName") return false;
      return currentValues[key] !== formData[key];
    });

    const timeZone = formRef.current
      ? formRef.current.getFieldValue("timeZone")
      : "";
    const locale = formRef.current
      ? formRef.current.getFieldValue("locale")
      : "";

    // staffcontact toggles
    const isAccountPrincipalChanged =
      accountPrincipal !== initialAccountPrincipal;
    const isManageChanged = manage !== initialManage;
    const isCareChanged = care !== initialCare;
    const isSocialChanged = social !== initialSocial;
    const isAllowChanged = allow !== isAllow;
    const is24HourFormatChanged =
      isTimeFormatEnabled !== userInfo?.is24hoursformat;

    // autoCall toggles
    const isAutoCallAllowChanged = autoCallAllow !== initialAutoCallAllow;
    const isAutoCallPrincipalChanged =
      autoCallPrincipal !== initialAutoCallPrincipal;
    const isAutoCallManageChanged = autoCallManage !== initialAutoCallManage;
    const isAutoCallCareChanged = autoCallCare !== initialAutoCallCare;
    const isAutoCallSocialChanged = autoCallSocial !== initialAutoCallSocial;

    const changed =
      (isFirstNameChanged && !isFirstNameEmpty) ||
      (isLastNameChanged && !isLastNameEmpty) ||
      isOtherFieldsChanged ||
      isAccountPrincipalChanged ||
      isManageChanged ||
      isCareChanged ||
      isSocialChanged ||
      isAllowChanged ||
      is24HourFormatChanged ||
      isAutoCallAllowChanged ||
      isAutoCallPrincipalChanged ||
      isAutoCallManageChanged ||
      isAutoCallCareChanged ||
      isAutoCallSocialChanged;

    setAreButtonsDisabled(!changed || !(timeZone && locale));
    setIsUpdatingData(true);
  };

  const hasSignificantChange = (currentValue, initialValue) => {
    const trimmedCurrent = (currentValue ?? "").trim();
    const trimmedInitial = (initialValue ?? "").trim();
    return trimmedCurrent !== trimmedInitial;
  };

  // staff check
  const isStaff = (user) => {
    return user?.usertype?.toLowerCase() === "staff";
  };
  const isStaffRolePresent = isStaff(userInfo);

  // Timezone combos
  const getTimezoneOptions = () => {
    const localeVal = formRef.current
      ? formRef.current.getFieldValue("locale")
      : locale;
    const timezones =
      providerData?.supportedtimezones.find((tz) => tz[localeVal]) || {};
    return (
      timezones[localeVal]?.map((timeZone) => ({
        label: timeZone,
        value: timeZone,
      })) ?? []
    );
  };

  const renderProfileDetails = () => {
    return (
      <Form
        form={form}
        initialValues={formData}
        onChange={onFormChangeHandler}
        colon={false}
        ref={formRef}
        onValuesChange={(changedValues) => {
          if (changedValues.hasOwnProperty("locale")) {
            const value = formRef.current
              ? formRef.current.getFieldValue("locale")
              : null;
            setLocale(value);
            // Reset timeZone if locale changes
            form.setFieldsValue({ timeZone: "" });
            setAreButtonsDisabled(true);
          }
        }}
      >
        <div
          className="account-form-row"
          style={{ marginBottom: "120px", marginTop: "25px" }}
        >
          <div className="account-form-group account-form-row">
            {renderProfileAvatar()}
          </div>
          <div className="account-form-group account-form-row">
            <Form.Item label={trans("FIRSTNAME")} name={"firstName"}>
              <Input placeholder="First Name" />
            </Form.Item>
          </div>
          <div className="account-form-group account-form-row">
            <Form.Item label={trans("LASTNAME")} name={"lastName"}>
              <Input placeholder="Last Name" />
            </Form.Item>
          </div>
        </div>

        <div className="account-form-row">
          <div className="account-form-group">
            <Form.Item label={trans("LOCALE/LANGUAGE")} name={"locale"}>
              <Select
                className="form-item-dropdown"
                onSelect={onFormChangeHandler}
                value={defaultLocaleVal}
                placeholder="Locale"
                options={
                  providerData &&
                  providerData.supportedlocales
                    ?.map((item) => ({
                      value: item.slice(-6, -1),
                      label: item,
                    }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                }
              />
            </Form.Item>
          </div>
          <div className="account-form-group">
            <Form.Item name="units" label="Measurement Units">
              <Select
                className="form-item-dropdown"
                onSelect={onFormChangeHandler}
                dropdownStyle={{ padding: 1 }}
                options={Units.map((unit) => ({
                  label: `${unit.label}`,
                  value: `${unit.value}`,
                }))}
              />
            </Form.Item>
          </div>
          <div className="account-form-group">
            <Form.Item
              label={
                <span>
                  {trans("POSTALCODE")}
                  <span
                    style={{
                      color: "#868585",
                      fontSize: "14px",
                      fontWeight: 500,
                      marginLeft: 10,
                    }}
                  >
                    ({trans("OPTIONAL")})
                  </span>
                </span>
              }
              name={"postalCode"}
            >
              <Input placeholder="Postal Code" />
            </Form.Item>
          </div>
        </div>

        <div className="account-form-row">
          <div className="account-form-group">
            <Form.Item name="timeZone" label={trans("TIMEZONE")}>
              <Select
                className="form-item-dropdown custom-select"
                onSelect={onFormChangeHandler}
                dropdownStyle={{ padding: 1 }}
                options={getTimezoneOptions()}
              />
            </Form.Item>
          </div>
          <div
            className="account-form-group"
            style={{ alignItems: "flex-start" }}
          >
            <Form.Item label="" name={"is24hoursformat"}>
              <div className="togglebutton">
                <div>
                  <Switch
                    checked={isTimeFormatEnabled}
                    onChange={(checked) => {
                      setIsTimeFormatEnabled(checked);
                      setAreButtonsDisabled(
                        !form.getFieldValue("timeZone") ||
                          (checked === userInfo?.is24hoursformat &&
                            allow === isAllow &&
                            accountPrincipal === initialAccountPrincipal &&
                            manage === initialManage &&
                            care === initialCare &&
                            social === initialSocial &&
                            autoCallAllow === initialAutoCallAllow &&
                            autoCallPrincipal === initialAutoCallPrincipal &&
                            autoCallManage === initialAutoCallManage &&
                            autoCallCare === initialAutoCallCare &&
                            autoCallSocial === initialAutoCallSocial &&
                            Object.keys(formData).every(
                              (key) => form.getFieldValue(key) === formData[key]
                            ))
                      );
                    }}
                  />
                </div>
                <div className="timeformat-text">24 hour format</div>
              </div>
            </Form.Item>
          </div>
          <div className="account-form-group"></div>
        </div>

        {(hubRecord?.rolename !== undefined || showContactingStaff) && (
          <>
            <div style={{ border: "0.5px solid black", width: "80%" }}></div>
            <div className="account-form-row">
              <div
                className="account-form-group"
                style={{ alignItems: "flex-start" }}
              >
                <Form.Item name="staffcontact" label="Contacting Staff">
                  <div className="contactingstaff">
                    <div className="togglelistbutton">
                      <div className="subtogglebuttonprincipal">
                        <Switch
                          checked={allow}
                          onChange={(checked) => {
                            setAllow(checked);
                            setAccountPrincipal(checked);
                            setManage(checked);
                            setCare(checked);
                            setSocial(checked);
                            setAreButtonsDisabled(
                              !form.getFieldValue("timeZone") ||
                                (checked === isAllow &&
                                  Object.keys(formData).every(
                                    (key) =>
                                      form.getFieldValue(key) === formData[key]
                                  ) &&
                                  isTimeFormatEnabled ===
                                    userInfo?.is24hoursformat &&
                                  autoCallAllow === initialAutoCallAllow &&
                                  autoCallPrincipal ===
                                    initialAutoCallPrincipal &&
                                  autoCallManage === initialAutoCallManage &&
                                  autoCallCare === initialAutoCallCare &&
                                  autoCallSocial === initialAutoCallSocial &&
                                  toggleUpdateOnToggleAllow &&
                                  allowedPermissions === (checked ? 4 : 0))
                            );
                          }}
                        />
                      </div>
                      <div>Allow</div>
                    </div>
                    <div className="subtogglelist">
                      <div className="subtogglebutton">
                        <div className="subtogglebuttonprincipal">
                          <Switch
                            checked={accountPrincipal}
                            onChange={(checked) => {
                              setAccountPrincipal(checked);
                              setAreButtonsDisabled(
                                !form.getFieldValue("timeZone") ||
                                  (checked === initialAccountPrincipal &&
                                    manage === initialManage &&
                                    care === initialCare &&
                                    social === initialSocial &&
                                    allow === isAllow &&
                                    autoCallAllow === initialAutoCallAllow &&
                                    autoCallPrincipal ===
                                      initialAutoCallPrincipal &&
                                    autoCallManage === initialAutoCallManage &&
                                    autoCallCare === initialAutoCallCare &&
                                    autoCallSocial === initialAutoCallSocial &&
                                    Object.keys(formData).every(
                                      (key) =>
                                        form.getFieldValue(key) ===
                                        formData[key]
                                    ) &&
                                    isTimeFormatEnabled ===
                                      userInfo?.is24hoursformat)
                              );
                            }}
                            disabled={!allow}
                          />
                        </div>
                        <div>{trans("ACCOUNTPRINCIPAL")}</div>
                      </div>
                      <div className="subtogglebutton">
                        <div className="subtogglebuttonprincipal">
                          <Switch
                            checked={manage}
                            onChange={(checked) => {
                              setManage(checked);
                              setAreButtonsDisabled(
                                !form.getFieldValue("timeZone") ||
                                  (checked === initialManage &&
                                    accountPrincipal ===
                                      initialAccountPrincipal &&
                                    care === initialCare &&
                                    social === initialSocial &&
                                    allow === isAllow &&
                                    autoCallAllow === initialAutoCallAllow &&
                                    autoCallPrincipal ===
                                      initialAutoCallPrincipal &&
                                    autoCallManage === initialAutoCallManage &&
                                    autoCallCare === initialAutoCallCare &&
                                    autoCallSocial === initialAutoCallSocial &&
                                    Object.keys(formData).every(
                                      (key) =>
                                        form.getFieldValue(key) ===
                                        formData[key]
                                    ) &&
                                    isTimeFormatEnabled ===
                                      userInfo?.is24hoursformat)
                              );
                            }}
                            disabled={!allow}
                          />
                        </div>
                        <div>{trans("MANAGE")}</div>
                      </div>
                      <div className="subtogglebutton">
                        <div className="subtogglebuttonprincipal">
                          <Switch
                            checked={care}
                            onChange={(checked) => {
                              setCare(checked);
                              setAreButtonsDisabled(
                                !form.getFieldValue("timeZone") ||
                                  (checked === initialCare &&
                                    accountPrincipal ===
                                      initialAccountPrincipal &&
                                    manage === initialManage &&
                                    social === initialSocial &&
                                    allow === isAllow &&
                                    autoCallAllow === initialAutoCallAllow &&
                                    autoCallPrincipal ===
                                      initialAutoCallPrincipal &&
                                    autoCallManage === initialAutoCallManage &&
                                    autoCallCare === initialAutoCallCare &&
                                    autoCallSocial === initialAutoCallSocial &&
                                    Object.keys(formData).every(
                                      (key) =>
                                        form.getFieldValue(key) ===
                                        formData[key]
                                    ) &&
                                    isTimeFormatEnabled ===
                                      userInfo?.is24hoursformat)
                              );
                            }}
                            disabled={!allow}
                          />
                        </div>
                        <div>{trans("CARE")}</div>
                      </div>
                      <div className="subtogglebutton">
                        <div className="subtogglebuttonprincipal">
                          <Switch
                            checked={social}
                            onChange={(checked) => {
                              setSocial(checked);
                              setAreButtonsDisabled(
                                !form.getFieldValue("timeZone") ||
                                  (checked === initialSocial &&
                                    accountPrincipal ===
                                      initialAccountPrincipal &&
                                    manage === initialManage &&
                                    care === initialCare &&
                                    allow === isAllow &&
                                    autoCallAllow === initialAutoCallAllow &&
                                    autoCallPrincipal ===
                                      initialAutoCallPrincipal &&
                                    autoCallManage === initialAutoCallManage &&
                                    autoCallCare === initialAutoCallCare &&
                                    autoCallSocial === initialAutoCallSocial &&
                                    Object.keys(formData).every(
                                      (key) =>
                                        form.getFieldValue(key) ===
                                        formData[key]
                                    ) &&
                                    isTimeFormatEnabled ===
                                      userInfo?.is24hoursformat)
                              );
                            }}
                            disabled={!allow}
                          />
                        </div>
                        <div>{trans("SOCIAL")}</div>
                      </div>
                    </div>
                  </div>
                </Form.Item>
              </div>

              {/* NEW auto-call toggles (with autoacceptcall + revokeautocallroles) */}
              <div
                className="account-form-group"
                style={{ alignItems: "flex-start", marginLeft: "40px" }}
              >
                <Form.Item label="Auto Answer (Smart Camera)" name="allowautocallroles">
                  <div className="contactingstaff">
                    <div className="togglelistbutton">
                      <div className="subtogglebuttonprincipal">
                        <Switch
                          checked={autoCallAllow}
                          onChange={(checked) => {
                            setAutoCallAllow(checked);
                            setAutoCallPrincipal(checked);
                            setAutoCallManage(checked);
                            setAutoCallCare(checked);
                            setAutoCallSocial(checked);
                            setAreButtonsDisabled(
                              !form.getFieldValue("timeZone") ||
                                (checked === initialAutoCallAllow &&
                                  autoCallPrincipal ===
                                    initialAutoCallPrincipal &&
                                  autoCallManage === initialAutoCallManage &&
                                  autoCallCare === initialAutoCallCare &&
                                  autoCallSocial === initialAutoCallSocial &&
                                  allow === isAllow &&
                                  accountPrincipal === initialAccountPrincipal &&
                                  manage === initialManage &&
                                  care === initialCare &&
                                  social === initialSocial &&
                                  isTimeFormatEnabled ===
                                    userInfo?.is24hoursformat &&
                                  Object.keys(formData).every(
                                    (key) =>
                                      form.getFieldValue(key) === formData[key]
                                  ))
                            );
                          }}
                        />
                      </div>
                      <div>Allow</div>
                    </div>
                    <div className="subtogglelist">
                      <div className="subtogglebutton">
                        <div className="subtogglebuttonprincipal">
                          <Switch
                            checked={autoCallPrincipal}
                            onChange={(checked) => {
                              setAutoCallPrincipal(checked);
                              setAreButtonsDisabled(
                                !form.getFieldValue("timeZone") ||
                                  (checked === initialAutoCallPrincipal &&
                                    autoCallManage === initialAutoCallManage &&
                                    autoCallCare === initialAutoCallCare &&
                                    autoCallSocial === initialAutoCallSocial &&
                                    autoCallAllow === initialAutoCallAllow &&
                                    allow === isAllow &&
                                    accountPrincipal === initialAccountPrincipal &&
                                    manage === initialManage &&
                                    care === initialCare &&
                                    social === initialSocial &&
                                    isTimeFormatEnabled ===
                                      userInfo?.is24hoursformat &&
                                    Object.keys(formData).every(
                                      (key) =>
                                        form.getFieldValue(key) === formData[key]
                                    ))
                              );
                            }}
                            disabled={!autoCallAllow}
                          />
                        </div>
                        <div>{trans("ACCOUNTPRINCIPAL")}</div>
                      </div>
                      <div className="subtogglebutton">
                        <div className="subtogglebuttonprincipal">
                          <Switch
                            checked={autoCallManage}
                            onChange={(checked) => {
                              setAutoCallManage(checked);
                              setAreButtonsDisabled(
                                !form.getFieldValue("timeZone") ||
                                  (checked === initialAutoCallManage &&
                                    autoCallPrincipal ===
                                      initialAutoCallPrincipal &&
                                    autoCallCare === initialAutoCallCare &&
                                    autoCallSocial === initialAutoCallSocial &&
                                    autoCallAllow === initialAutoCallAllow &&
                                    allow === isAllow &&
                                    accountPrincipal === initialAccountPrincipal &&
                                    manage === initialManage &&
                                    care === initialCare &&
                                    social === initialSocial &&
                                    isTimeFormatEnabled ===
                                      userInfo?.is24hoursformat &&
                                    Object.keys(formData).every(
                                      (key) =>
                                        form.getFieldValue(key) === formData[key]
                                    ))
                              );
                            }}
                            disabled={!autoCallAllow}
                          />
                        </div>
                        <div>{trans("MANAGE")}</div>
                      </div>
                      <div className="subtogglebutton">
                        <div className="subtogglebuttonprincipal">
                          <Switch
                            checked={autoCallCare}
                            onChange={(checked) => {
                              setAutoCallCare(checked);
                              setAreButtonsDisabled(
                                !form.getFieldValue("timeZone") ||
                                  (checked === initialAutoCallCare &&
                                    autoCallPrincipal ===
                                      initialAutoCallPrincipal &&
                                    autoCallManage === initialAutoCallManage &&
                                    autoCallSocial === initialAutoCallSocial &&
                                    autoCallAllow === initialAutoCallAllow &&
                                    allow === isAllow &&
                                    accountPrincipal === initialAccountPrincipal &&
                                    manage === initialManage &&
                                    care === initialCare &&
                                    social === initialSocial &&
                                    isTimeFormatEnabled ===
                                      userInfo?.is24hoursformat &&
                                    Object.keys(formData).every(
                                      (key) =>
                                        form.getFieldValue(key) === formData[key]
                                    ))
                              );
                            }}
                            disabled={!autoCallAllow}
                          />
                        </div>
                        <div>{trans("CARE")}</div>
                      </div>
                      <div className="subtogglebutton">
                        <div className="subtogglebuttonprincipal">
                          <Switch
                            checked={autoCallSocial}
                            onChange={(checked) => {
                              setAutoCallSocial(checked);
                              setAreButtonsDisabled(
                                !form.getFieldValue("timeZone") ||
                                  (checked === initialAutoCallSocial &&
                                    autoCallPrincipal ===
                                      initialAutoCallPrincipal &&
                                    autoCallManage === initialAutoCallManage &&
                                    autoCallCare === initialAutoCallCare &&
                                    autoCallAllow === initialAutoCallAllow &&
                                    allow === isAllow &&
                                    accountPrincipal === initialAccountPrincipal &&
                                    manage === initialManage &&
                                    care === initialCare &&
                                    social === initialSocial &&
                                    isTimeFormatEnabled ===
                                      userInfo?.is24hoursformat &&
                                    Object.keys(formData).every(
                                      (key) =>
                                        form.getFieldValue(key) === formData[key]
                                    ))
                              );
                            }}
                            disabled={!autoCallAllow}
                          />
                        </div>
                        <div>{trans("SOCIAL")}</div>
                      </div>
                    </div>
                  </div>
                </Form.Item>
              </div>
              <div className="account-form-group"></div>
            </div>
          </>
        )}

        {currentUserExist && (
          <div className="account-form-row">
            {isStaffRolePresent && (
              <div className="account-form-group">
                <Form.Item
                  label={
                    <span>
                      {trans("STAFFROLE")}
                      <span
                        style={{
                          color: "#868585",
                          fontSize: "14px",
                          fontWeight: 500,
                          marginLeft: 10,
                        }}
                      >
                        ({trans("OPTIONAL")})
                      </span>
                    </span>
                  }
                  name={"staffRole"}
                >
                  <Input placeholder="Staff Role" />
                </Form.Item>
              </div>
            )}
            <div
              className={`${
                isStaffRolePresent
                  ? "account-form-group emailcenter"
                  : "account-form-group"
              } `}
            >
              {formData.email && (
                <Form.Item label={trans("EMAIL")} name={"email"}>
                  <span style={{ padding: "10px" }}>{formData.email}</span>
                </Form.Item>
              )}
            </div>
            <div className="account-form-group"></div>
          </div>
        )}
      </Form>
    );
  };

  return (
    <MainPage
      header={header}
      hasSider={path.includes("profile") ? true : false}
      buttonSelected="gear"
      noModals={!!openSubMenu}
    >
      <div className="edit-profile-container">
        {fetchingUserInfo ? (
          <Loading message="Fetching User Profile..." />
        ) : isUpdatingProfile ? (
          <Loading message="Updating User Profile..." />
        ) : (
          <>
            {renderHeaderSection()}

            {currentUserExist && (
              <div className="custom-button-container">
                <NewCustomButton
                  label={"Change Password"}
                  className={"password-button"}
                  onClick={onChangePasswordHandler}
                />
              </div>
            )}

            <div className="edit-profile-form-container">
              <div style={{ display: "flex" }}>{renderProfileDetails()}</div>

              <div
                className={`${
                  hubRecord === undefined
                    ? "edit-profile-button-container"
                    : "edit-profile-button-container edit-contact-staff"
                } `}
              >
                <div className="form-button">
                  <NewCustomButton
                    className="cancel"
                    onClick={onCancelHandler}
                    label={trans("CANCEL")}
                    icon={<NewCloseIcon className="cancel-icon" />}
                  />
                </div>

                <div className="form-button">
                  <NewCustomButton
                    type={ButtonTypes.primary}
                    label={trans("UPDATE")}
                    icon={
                      <NewUpdateIcon
                        className={
                          !areButtonsDisabled
                            ? "update-icon-enabled"
                            : "update-icon-disabled"
                        }
                      />
                    }
                    onClick={onUpdateHandler}
                    disabled={areButtonsDisabled}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </MainPage>
  );
};

EditUserProfile.propTypes = {
  parentBread: PropTypes.arrayOf(PropTypes.object),
  onActionClick: PropTypes.func,
};

export default EditUserProfile;