import React from "react";

export const ScheduleAddPlusIcon = (props) => {
    const { className="", style={}, onClick, disabled=false } = props;
    return (
        <svg className={className} style={style} width="112" height="56" viewBox="0 0 112 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="112" height="56" rx="28" fill="#D9ECFA"/>
        <path d="M40.4777 26.0778H37.9453V23.5339C37.9453 22.4634 37.082 21.6001 36.0115 21.6001C34.941 21.6001 34.0777 22.4634 34.0777 23.5339V26.0663H31.5338C30.4633 26.0663 29.6 26.9296 29.6 28.0001C29.6 29.0706 30.4633 29.9339 31.5338 29.9339H34.0662V32.4663C34.0662 33.5368 34.9295 34.4001 36 34.4001C37.0705 34.4001 37.9338 33.5368 37.9338 32.4663V29.9339H40.4662C41.5367 29.9339 42.4 29.0706 42.4 28.0001C42.4 26.9296 41.5367 26.0663 40.4662 26.0663L40.4777 26.0778Z" fill="#113E57"/>
        <path d="M55.984 33.5L60.848 22.14H63.152L68.016 33.5H65.36L64.352 31.1H59.648L58.64 33.5H55.984ZM60.576 28.892H63.424L62 25.468L60.576 28.892ZM71.974 33.676C69.878 33.676 68.262 31.772 68.262 29.5C68.262 27.228 69.878 25.324 71.974 25.324C73.046 25.324 73.83 25.868 74.15 26.3V21.5H76.566V33.5H74.15V32.7C73.83 33.132 73.046 33.676 71.974 33.676ZM72.518 31.484C73.606 31.484 74.342 30.652 74.342 29.5C74.342 28.348 73.606 27.516 72.518 27.516C71.43 27.516 70.694 28.348 70.694 29.5C70.694 30.652 71.43 31.484 72.518 31.484ZM81.7865 33.676C79.6905 33.676 78.0745 31.772 78.0745 29.5C78.0745 27.228 79.6905 25.324 81.7865 25.324C82.8585 25.324 83.6425 25.868 83.9625 26.3V21.5H86.3785V33.5H83.9625V32.7C83.6425 33.132 82.8585 33.676 81.7865 33.676ZM82.3305 31.484C83.4185 31.484 84.1545 30.652 84.1545 29.5C84.1545 28.348 83.4185 27.516 82.3305 27.516C81.2425 27.516 80.5065 28.348 80.5065 29.5C80.5065 30.652 81.2425 31.484 82.3305 31.484Z" fill="#113E57"/>
        </svg>
    );
}

// Figma > contact