import React from "react";

export const ScheduleContactIcon = (props) => {
  const { className = "", style = {}, onClick, disabled = false } = props;
  return (
    <svg
      className={className}
      style={style}
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5637 9.67845C20.9603 9.31412 20.2544 9.04088 19.4916 8.83595C19.5371 8.79041 19.594 8.75625 19.6396 8.71071C20.5276 7.82267 21.0058 6.68415 21.0058 5.43178C21.0058 4.17941 20.539 3.04089 19.6396 2.15284C18.7515 1.2648 17.613 0.786621 16.3606 0.786621C15.1083 0.786621 13.9698 1.25341 13.0817 2.15284C12.5694 2.66518 12.2164 3.25721 11.9887 3.90616C12.1595 4.38434 12.262 4.89667 12.262 5.43178C12.262 5.96688 12.1595 6.47921 11.9887 6.95739C12.2164 7.60635 12.5694 8.19838 13.0817 8.71071C13.1273 8.75625 13.1842 8.79041 13.2411 8.83595C12.7971 8.9498 12.3872 9.09781 12.0001 9.26858C12.2848 9.39382 12.5694 9.51906 12.8312 9.67845C13.1956 9.89477 13.503 10.1452 13.7648 10.3843C14.5276 10.1908 15.3929 10.0883 16.3606 10.0883C18.1595 10.0883 19.6054 10.4185 20.7098 11.0788C21.8142 11.7392 22.3606 12.6386 22.3606 13.7543V14.5741H15.2677C15.2677 15.4849 14.539 16.2135 13.6282 16.2135H22.3606C23.2715 16.2135 24.0001 15.4849 24.0001 14.5741V13.7543C24.0001 12.5589 23.5789 10.8853 21.5523 9.66707L21.5637 9.67845ZM14.2544 3.30275C14.8464 2.71072 15.5523 2.42609 16.372 2.42609C17.1918 2.42609 17.8976 2.7221 18.4897 3.30275C19.0817 3.89478 19.3663 4.60066 19.3663 5.42039C19.3663 6.24012 19.0703 6.95739 18.4897 7.53804C17.8976 8.13006 17.1918 8.41469 16.372 8.41469C15.5523 8.41469 14.8464 8.11868 14.2544 7.53804C13.6624 6.94601 13.3777 6.24012 13.3777 5.42039C13.3777 4.60066 13.6737 3.89478 14.2544 3.30275Z"
        fill="#113E57"
      />
      <path
        d="M13.7761 10.3843C13.5028 10.1339 13.2068 9.89477 12.8425 9.67845C12.5806 9.51906 12.296 9.39382 12.0114 9.26858C11.6243 9.09781 11.203 8.9498 10.7704 8.83595C10.8159 8.79041 10.8729 8.75625 10.9184 8.71071C11.4307 8.19838 11.7837 7.60635 12.0114 6.95739C12.1821 6.47921 12.2846 5.96688 12.2846 5.43178C12.2846 4.89667 12.1821 4.38434 12.0114 3.90616C11.7837 3.25721 11.4307 2.66518 10.9184 2.15284C10.0303 1.2648 8.89183 0.786621 7.63946 0.786621C6.38709 0.786621 5.24857 1.25341 4.36053 2.14146C3.47248 3.0295 2.9943 4.16802 2.9943 5.42039C2.9943 6.67276 3.4611 7.81128 4.36053 8.69932C4.40607 8.74486 4.46299 8.77902 4.51992 8.82456C3.75711 9.02949 3.05123 9.30274 2.44781 9.66707C0.432637 10.8853 0 12.5589 0 13.7543V14.5741C0 15.4849 0.728652 16.2135 1.63947 16.2135H13.6395C14.5503 16.2135 15.2789 15.4849 15.2789 14.5741V13.7543C15.2789 12.7752 14.9829 11.4887 13.7761 10.3729V10.3843ZM5.52181 3.30275C6.11384 2.71072 6.81973 2.42609 7.63946 2.42609C8.45919 2.42609 9.16507 2.7221 9.7571 3.30275C10.3491 3.89478 10.6338 4.60066 10.6338 5.42039C10.6338 6.24012 10.3377 6.95739 9.7571 7.53804C9.16507 8.13006 8.45919 8.41469 7.63946 8.41469C6.81973 8.41469 6.11384 8.11868 5.52181 7.53804C4.92978 6.94601 4.64516 6.24012 4.64516 5.42039C4.64516 4.60066 4.94117 3.89478 5.52181 3.30275ZM13.6395 14.5855H1.63947V13.7657C1.63947 13.4811 1.67362 13.2192 1.74193 12.9688C1.87856 12.4678 2.1518 12.0124 2.56167 11.6253C2.7666 11.4318 3.00569 11.2496 3.27893 11.0902C4.3833 10.4299 5.82921 10.0997 7.62807 10.0997C8.59581 10.0997 9.46109 10.2022 10.2239 10.3957C10.8729 10.5551 11.4649 10.7828 11.9772 11.0902C11.9772 11.0902 11.9772 11.0902 11.9886 11.1016C12.7969 11.5912 13.3093 12.2173 13.5142 12.9688C13.5825 13.2192 13.6167 13.4925 13.6167 13.7657V14.5855H13.6395Z"
        fill="#113E57"
      />
    </svg>
  );
};
// Figma > contact