// WellnessHistoryTableData.jsx

import React, { useEffect, useState } from "react";
import "./Wellness.less";
import { Spin } from "antd";
import unitTitleData from "./statistic-title.json";

const WellnessHistoryTableData = ({ modalLoading, modalData, userMeasurement, deviceObj }) => {
  // Renamed state variable for clarity
  const [wellnessTableData, setWellnessTableData] = useState([]);
  const [wellnessTableLoading, setWellnessTableLoading] = useState(true);

  useEffect(() => {
    if (modalData.length > 0) {
      // Deep clone modalData to avoid mutating props
      const wellnessTableDataClone = JSON.parse(JSON.stringify(modalData));

      // Remove the first element (assumed to be the most recent)
      wellnessTableDataClone.shift();

      setWellnessTableData(wellnessTableDataClone);
    } else {
      setWellnessTableData([]);
    }
  }, [modalData]);

  const formatValue = (value) => {
    if (value === null || value === undefined) return "-";
    if (typeof value === "number") {
      const roundedValue = Math.round(value * 100) / 100; // Round to 2 decimal places
      return roundedValue.toString().length > 5
        ? roundedValue.toFixed(2).slice(0, 5) // Truncate if longer than 5 characters
        : roundedValue.toString();
    }
    return value.toString().slice(0, 5); // For non-numeric values
  };

  useEffect(() => {
    if (modalData.length > 0) {
      const wellnessHistoryData = JSON.parse(JSON.stringify(modalData));
      wellnessHistoryData.shift();
      setWellnessTableData(wellnessHistoryData);
      setWellnessTableLoading(false);
    }
  }, [modalData]);

  return (
    <>
      {modalLoading ? (
        <Spin spinning={modalLoading} style={{ margin: "10rem 9rem" }} />
      ) : modalData.length === 0 ? (
        <div className="tab-content">
          <p className="no_data">No Data Found</p>
        </div>
      ) : (
        <div className="tab-content">
          {/* MAIN DATA DISPLAY (First Entry) */}
          <div className="modal-wellness-item-content">
            <div className="modal-wellness-item-content-header">
              <div className="modal-wellness-item-content-day-info">
                {modalData[0]['formatted_date']}
              </div>
              <div className="modal-wellness-item-content-divider">
                <div className="vertical-divider"></div>
              </div>
              <div className="modal-wellness-item-content-time-info">
                {`${modalData[0]['formatted_time']} ( ${modalData[0]['timeDiffText']} )`}
              </div>
            </div>
            <div className="modal-wellness-item-content-statistic">
              {modalData[0]['data'] && typeof modalData[0]['data'] === "object" ? (
                Object.keys(modalData[0]['data']).map((item, index) => (
                  <div className="modal-wellness-item-content-statistic-info" key={index}>
                    <div className="modal-wellness-item-content-statistic-info-label">
                      {unitTitleData[userMeasurement][item]}
                    </div>
                    <div className="modal-wellness-item-content-statistic-info-value">
                      {formatValue(modalData[0]['data'][item])}
                    </div>
                  </div>
                ))
              ) : (
                <p>No Data Available</p>
              )}
            </div>
            <div className="wellness-item-content-device">
              <div className="wellness-item-content-device-title">Device used</div>
              <div className="wellness-item-content-device-model-info">
                <div className="wellness-item-content-device-model-title">
                  {deviceObj.deviceBrand}
                </div>
                <svg width="2" height="32" viewBox="0 0 2 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 0V56" stroke="#C9C9C9" />
                </svg>
                <div className="wellness-item-content-device-model-model">
                  {deviceObj.deviceModel}
                </div>
              </div>
            </div>
          </div>

          {/* Divider */}
          <div className="modal-horizontal-divider"></div>

          {/* TABLE DATA */}
          <div className="modal-wellness-card-container">
            <div className="modal-table-data-history-container">
              {wellnessTableData.length > 0 ? (
                wellnessTableData.map((items, index) => (
                  <div key={index} className="modal-wellness-list-item-content">
                    <div className="modal-wellness-item-content-header">
                      <div className="modal-wellness-item-content-day-info">
                        {items['formatted_date']}
                      </div>
                      <div className="modal-wellness-item-content-divider">
                        <div className="vertical-divider"></div>
                      </div>
                      <div className="modal-wellness-item-content-time-info">
                        {`${items['formatted_time']} `}
                      </div>
                    </div>
                    <div className="modal-wellness-item-content-statistic">
                      {items.data && typeof items.data === "object" ? (
                        Object.keys(items.data).map((item, idx) => (
                          <div className="modal-wellness-item-content-statistic-info" key={idx}>
                            <div className="modal-wellness-item-content-statistic-info-value">
                              {formatValue(items.data[item])}
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No Data Available</p>
                      )}
                    </div>
                    <div className="wellness-item-content-device">
                      <div className="wellness-item-content-device-title"></div>
                      <div className="wellness-item-content-device-model-info">
                        <div className="wellness-item-content-device-model-title">
                          {deviceObj.deviceBrand}
                        </div>
                        <svg width="2" height="32" viewBox="0 0 2 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 0V56" stroke="#C9C9C9" />
                        </svg>
                        <div className="wellness-item-content-device-model-model">
                          {deviceObj.deviceModel}
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No Historical Data Available</p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WellnessHistoryTableData;
