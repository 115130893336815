import React from "react";

export const NewCircleIcon = (props) => {
  const { className = "", disabled = false, onClick, style = {} } = props;
  return (
    <svg
      className={className}
      style={style}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.3335 2C6.82159 2 2.3335 6.4881 2.3335 12C2.3335 17.5119 6.82159 22 12.3335 22C17.8454 22 22.3335 17.5119 22.3335 12C22.3335 6.4881 17.8454 2 12.3335 2ZM12.3335 19.9405C7.95254 19.9405 4.39302 16.381 4.39302 12C4.39302 7.61905 7.94064 4.05952 12.3335 4.05952C16.7264 4.05952 20.274 7.61905 20.274 12C20.274 16.381 16.7264 19.9405 12.3335 19.9405Z"
        fill="#005D98"
      />
    </svg>
  );
};
