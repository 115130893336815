/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useSelector } from "react-redux";
import SessionStorage from "@commscopemycloud/humaui/Utilities/SessionStorage";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { AppStorageKeys } from "@commscopemycloud/humaui/Utilities/Constants"

import LanguageIcon from "../../../static/icons/language.svg";
import MailIcon from "../../../static/icons/mail.svg";
import CallIcon from "../../../static/icons/call.svg";
import { LoadingOutlined } from "@ant-design/icons";
const ENV_VARS = SessionStorage.get(AppStorageKeys.envVars);

const SupportCardInfo = ({userConfigData, loading}) => {
  const trans = useSelector(translator);

  return (
    <div>
      <h1 className="info-header">{trans("SUPPORT")}</h1>
      {loading ? 
        <div class="data-group"><LoadingOutlined /></div> : (
        <>
          {userConfigData && userConfigData["website"] ? (
            <div className="data-group">
              <img alt="care" src={LanguageIcon} />
               <a href={userConfigData["website"]}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#3FA9F5" }}
                >{userConfigData["website"]}</a>
            </div>) : null}
        </>
      )}
      {loading ?
        <div class="data-group"><LoadingOutlined /></div> : (
          <>
            {userConfigData && userConfigData["email"] ? (
              <div className="data-group">
                <img alt="mail" src={MailIcon} />
                <p>{userConfigData["email"]}</p>
              </div>) : null}
          </>
        )}
      {loading ?
        <div class="data-group"><LoadingOutlined /></div> : (
          <>
            {userConfigData && userConfigData["contact"] ? (
              <div className="data-group">
                <img alt="contact" src={CallIcon} />
                <p>{userConfigData["contact"]}</p>
              </div>) : null}
          </>
        )}
    </div>
  );
};

export default SupportCardInfo;
