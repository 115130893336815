import React from "react";

export const ScheduledIcon = (props) => {
    const { className="", style={}, onClick, disabled=false } = props;
    return (
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"
        className={`${disabled ? "disabled" : ""} ${className}`}
        onClick={disabled ? null: onClick}
        style={style}>
        <path d="M19.8806 5.48671C19.5143 5.14074 19.0768 4.96775 18.5578 4.96775H17.6217V4.12317C17.6217 3.60421 17.2045 3.18701 16.6855 3.18701C16.1665 3.18701 15.7493 3.60421 15.7493 4.12317V4.96775H8.28041V4.12317C8.28041 3.60421 7.86321 3.18701 7.34425 3.18701C6.82529 3.18701 6.40809 3.60421 6.40809 4.12317V4.96775H5.47193C4.96315 4.96775 4.51542 5.14074 4.14909 5.48671C3.78277 5.83268 3.59961 6.24988 3.59961 6.73831V19.1831C3.59961 19.6716 3.78277 20.0888 4.14909 20.4347C4.51542 20.7807 4.95297 20.9537 5.47193 20.9537H18.5069C19.555 20.9537 20.3996 20.1091 20.3996 19.061V6.73831C20.3996 6.24988 20.2164 5.83268 19.8501 5.48671H19.8806ZM18.5578 19.1831H5.49228V10.2998H18.5578M18.5578 8.51905H5.49228V6.73831H18.5578V8.51905Z" fill="#113E57"/>
        <path d="M7.8325 11.5103H6.88617C6.70633 11.5103 6.56055 11.656 6.56055 11.8359V12.7822C6.56055 12.962 6.70633 13.1078 6.88617 13.1078H7.8325C8.01234 13.1078 8.15812 12.962 8.15812 12.7822V11.8359C8.15812 11.656 8.01234 11.5103 7.8325 11.5103Z" fill="#113E57"/>
        <path d="M7.8325 13.8408H6.88617C6.70633 13.8408 6.56055 13.9866 6.56055 14.1664V15.1128C6.56055 15.2926 6.70633 15.4384 6.88617 15.4384H7.8325C8.01234 15.4384 8.15812 15.2926 8.15812 15.1128V14.1664C8.15812 13.9866 8.01234 13.8408 7.8325 13.8408Z" fill="#113E57"/>
        <path d="M7.8325 16.1714H6.88617C6.70633 16.1714 6.56055 16.3172 6.56055 16.497V17.4433C6.56055 17.6232 6.70633 17.769 6.88617 17.769H7.8325C8.01234 17.769 8.15812 17.6232 8.15812 17.4433V16.497C8.15812 16.3172 8.01234 16.1714 7.8325 16.1714Z" fill="#113E57"/>
        <path d="M10.1528 11.5103H9.20648C9.02664 11.5103 8.88086 11.656 8.88086 11.8359V12.7822C8.88086 12.962 9.02664 13.1078 9.20648 13.1078H10.1528C10.3327 13.1078 10.4784 12.962 10.4784 12.7822V11.8359C10.4784 11.656 10.3327 11.5103 10.1528 11.5103Z" fill="#113E57"/>
        <path d="M10.1528 13.8408H9.20648C9.02664 13.8408 8.88086 13.9866 8.88086 14.1664V15.1128C8.88086 15.2926 9.02664 15.4384 9.20648 15.4384H10.1528C10.3327 15.4384 10.4784 15.2926 10.4784 15.1128V14.1664C10.4784 13.9866 10.3327 13.8408 10.1528 13.8408Z" fill="#113E57"/>
        <path d="M10.1528 16.1714H9.20648C9.02664 16.1714 8.88086 16.3172 8.88086 16.497V17.4433C8.88086 17.6232 9.02664 17.769 9.20648 17.769H10.1528C10.3327 17.769 10.4784 17.6232 10.4784 17.4433V16.497C10.4784 16.3172 10.3327 16.1714 10.1528 16.1714Z" fill="#113E57"/>
        <path d="M12.4624 11.5103H11.5161C11.3362 11.5103 11.1904 11.656 11.1904 11.8359V12.7822C11.1904 12.962 11.3362 13.1078 11.5161 13.1078H12.4624C12.6422 13.1078 12.788 12.962 12.788 12.7822V11.8359C12.788 11.656 12.6422 11.5103 12.4624 11.5103Z" fill="#113E57"/>
        <path d="M12.4624 13.8408H11.5161C11.3362 13.8408 11.1904 13.9866 11.1904 14.1664V15.1128C11.1904 15.2926 11.3362 15.4384 11.5161 15.4384H12.4624C12.6422 15.4384 12.788 15.2926 12.788 15.1128V14.1664C12.788 13.9866 12.6422 13.8408 12.4624 13.8408Z" fill="#113E57"/>
        <path d="M12.4624 16.1714H11.5161C11.3362 16.1714 11.1904 16.3172 11.1904 16.497V17.4433C11.1904 17.6232 11.3362 17.769 11.5161 17.769H12.4624C12.6422 17.769 12.788 17.6232 12.788 17.4433V16.497C12.788 16.3172 12.6422 16.1714 12.4624 16.1714Z" fill="#113E57"/>
        <path d="M14.772 11.5103H13.8256C13.6458 11.5103 13.5 11.656 13.5 11.8359V12.7822C13.5 12.962 13.6458 13.1078 13.8256 13.1078H14.772C14.9518 13.1078 15.0976 12.962 15.0976 12.7822V11.8359C15.0976 11.656 14.9518 11.5103 14.772 11.5103Z" fill="#113E57"/>
        <path d="M14.772 13.8408H13.8256C13.6458 13.8408 13.5 13.9866 13.5 14.1664V15.1128C13.5 15.2926 13.6458 15.4384 13.8256 15.4384H14.772C14.9518 15.4384 15.0976 15.2926 15.0976 15.1128V14.1664C15.0976 13.9866 14.9518 13.8408 14.772 13.8408Z" fill="#113E57"/>
        <path d="M14.772 16.1714H13.8256C13.6458 16.1714 13.5 16.3172 13.5 16.497V17.4433C13.5 17.6232 13.6458 17.769 13.8256 17.769H14.772C14.9518 17.769 15.0976 17.6232 15.0976 17.4433V16.497C15.0976 16.3172 14.9518 16.1714 14.772 16.1714Z" fill="#113E57"/>
        <path d="M17.0923 11.5103H16.1459C15.9661 11.5103 15.8203 11.656 15.8203 11.8359V12.7822C15.8203 12.962 15.9661 13.1078 16.1459 13.1078H17.0923C17.2721 13.1078 17.4179 12.962 17.4179 12.7822V11.8359C17.4179 11.656 17.2721 11.5103 17.0923 11.5103Z" fill="#113E57"/>
        <path d="M17.0923 13.8408H16.1459C15.9661 13.8408 15.8203 13.9866 15.8203 14.1664V15.1128C15.8203 15.2926 15.9661 15.4384 16.1459 15.4384H17.0923C17.2721 15.4384 17.4179 15.2926 17.4179 15.1128V14.1664C17.4179 13.9866 17.2721 13.8408 17.0923 13.8408Z" fill="#113E57"/>
        <path d="M17.0923 16.1714H16.1459C15.9661 16.1714 15.8203 16.3172 15.8203 16.497V17.4433C15.8203 17.6232 15.9661 17.769 16.1459 17.769H17.0923C17.2721 17.769 17.4179 17.6232 17.4179 17.4433V16.497C17.4179 16.3172 17.2721 16.1714 17.0923 16.1714Z" fill="#113E57"/>
        </svg>
    );
}

// Figma: scheduled