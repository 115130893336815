import React from "react";

export const HSNewCalendarIcon = (props) => {
    const { className = "", style = {} } = props;
    return (
        <svg className={className} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
            <g clipPath="url(#clip0_9576_11845)">
                <path d="M25.5981 6.10236C25.11 5.62992 24.5115 5.3937 23.8186 5.3937H22.5588V4.25984C22.5588 3.56693 21.9918 3 21.2989 3C20.606 3 20.0391 3.56693 20.0391 4.25984V5.3937H9.96035V4.25984C9.96035 3.56693 9.39342 3 8.7005 3C8.00759 3 7.44066 3.56693 7.44066 4.25984V5.3937H6.18082C5.4879 5.3937 4.88948 5.62992 4.40129 6.10236C3.9131 6.5748 3.66113 7.14173 3.66113 7.80315V24.6063C3.66113 25.2677 3.9131 25.8346 4.40129 26.3071C4.88948 26.7795 5.4879 27.0157 6.18082 27.0157H23.7714C25.1887 27.0157 26.3383 25.8661 26.3383 24.4488V7.80315C26.3383 7.14173 26.0863 6.5748 25.5981 6.10236ZM23.8186 24.5906H6.18082V12.5906H23.8186V24.5906ZM23.8186 10.1969H6.18082V7.80315H23.8186V10.1969Z" fill="#113E57" />
                <path d="M11.6926 13.7871H7.53516V17.9446H11.6926V13.7871Z" fill="#113E57" />
                <path d="M17.0628 13.7871H12.9053V17.9446H17.0628V13.7871Z" fill="#113E57" />
                <path d="M22.4329 13.7871H18.2754V17.9446H22.4329V13.7871Z" fill="#113E57" />
                <path d="M11.6926 18.9844H7.53516V23.1419H11.6926V18.9844Z" fill="#113E57" />
                <path d="M17.0628 18.9844H12.9053V23.1419H17.0628V18.9844Z" fill="#113E57" />
                <path d="M22.4329 18.9844H18.2754V23.1419H22.4329V18.9844Z" fill="#113E57" />
            </g>
            <defs>
                <clipPath id="clip0_9576_11845">
                    <rect width="22.6772" height="24" fill="white" transform="translate(3.66113 3)" />
                </clipPath>
            </defs>
        </svg>
    );
}


