import moment from "moment";
import {
  formatFullNameDisplay,
  getProfilePicUrl,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import desktopNotification from "../Notifications/DesktopNotification";
import { store } from "@commscopemycloud/humaui/Store/Store";
import {
  PhotoResolution,
  VideoCallNotificationTimeout,
} from "@commscopemycloud/humaui/Utilities/Constants";
import ContactNotiIcon from "../../../static/icons/desktop_contactimg_notif.svg";
import ScheduleMeetingDarkThemNotiIcon from "../../../static/icons/deskstop_schedule_darknoti.svg";
import ScheduleMeetingLightThemNotiIcon from "../../../static/icons/deskstop_schedule_lightnoti.svg";
import MissedCallDarkThemeNotiIcon from "../../../static/icons/desktop_missedcall_darknoti.svg";
import IncomingCallDarkThemeNotiIcon from "../../../static/icons/desktop_incomincall_darknoti.svg";
import IncomingCallLightThemeNotiIcon from "../../../static/icons/desktop_incomincall_lightnoti.svg";
import MissedCallLightThemeNotiIcon from "../../../static/icons/desktop_missedcall_lightnoti.svg";

const getIconForNotification = (type) => {
  const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches;
  if (type === "schedule") {
    return isDarkMode
      ? ScheduleMeetingDarkThemNotiIcon
      : ScheduleMeetingLightThemNotiIcon;
  } else if (type === "incoming") {
    return isDarkMode
      ? IncomingCallDarkThemeNotiIcon
      : IncomingCallLightThemeNotiIcon;
  } else if (type === "missed") {
    return isDarkMode
      ? MissedCallDarkThemeNotiIcon
      : MissedCallLightThemeNotiIcon;
  }
};

export const showContactNotification = (contact, currentUser, resourceMap) => {
  const { firstname, lastname } = contact;
  const contactFullName = `${firstname} ${lastname}`;
  const currentUserFullName = currentUser?.name;
  const userPhoto = getProfilePicUrl(
    currentUser?.useruuid,
    resourceMap,
    PhotoResolution.R64
  );
  desktopNotification.showNotification({
    key: contact?.useruuid,
    title: "New Contact Added",
    options: {
      body: `${contactFullName}\n${currentUserFullName}`,
      icon: userPhoto || ContactNotiIcon,
      requireInteraction: false,
      renotify: true,
      silent: true,
    },
  });
};

export const showScheduleNotification = (event) => {
  const scheduleEvent = event.schedule_event;
  if (!scheduleEvent) {
    // prettier-ignore
    console.error("Error@showScheduleNotification: Invalid event data: schedule_event is missing");
    return;
  }

  let eventname = scheduleEvent.event_name;
  let eventdetails = "";
  const separator = ", ";

  // Parse the event time properly
  if (scheduleEvent.event_timestamp) {
    // Assume `event_timestamp` is in milliseconds
    const timestamp = parseInt(scheduleEvent.event_timestamp, 10); 
    const eventDate = moment(timestamp); // Use the timestamp directly
    const now = moment();
    if (!eventDate.isValid()) {
      console.error("Invalid event date:", eventDate);
      return;
    }
    const diffDays = eventDate.diff(now, "days");
    if (diffDays === 0) {
      eventdetails = `Today ${eventDate.format("h:mm A")}`;
    } else if (diffDays === 1) {
      eventdetails = `Tomorrow ${eventDate.format("h:mm A")}`;
    } else if (diffDays > 1 && diffDays <= 7) {
      eventdetails = `${eventDate.format("dddd")} ${eventDate.format("h:mm A")}`;
    } else {
      eventdetails = `${eventDate.format("MMMM D")} ${eventDate.format("h:mm A")}`;
    }
  } else {
    if (scheduleEvent.recurring) {
    // Handle recurring events
      let reccuringtime = moment(event.schedule_event.event_time, "HH:mm");
      reccuringtime.add(reccuringtime.utcOffset(), "minutes");
      let recurringDays = scheduleEvent.schedule?.days_of_week || [];
      const capitalizedDays = recurringDays.map(
        (day) => day.charAt(0).toUpperCase() + day.slice(1).toLowerCase()
      );
      const recurringDaysStr = capitalizedDays.join(separator);
      eventdetails = `Every ${recurringDaysStr} ${reccuringtime.format("h:mm A")}`;
    }
  }

  const icon = getIconForNotification("schedule");
  desktopNotification.showNotification({
    key: scheduleEvent.schedule_eventuuid,
    title: "New Scheduled Call",
    options: {
      body: `${eventname}\n${eventdetails}`,
      icon: icon,
      requireInteraction: false,
      renotify: true,
      silent: true,
    },
  });
};

export const showMissedCallNotification = ({
  meeting_id,
  useruuid,
  userInfo,
}) => {
  if (!meeting_id || !useruuid) {
    // prettier-ignore
    console.error("Error@showMissedCallNotification: invalid details", meeting_id, useruuid);
  }
  if (!userInfo) {
    const state = store.getState();
    userInfo = state.data?.userInfo ?? {};
  }
  const userDetails = userInfo[useruuid] || {};
  const { firstname, lastname } = userDetails;
  const fullName = formatFullNameDisplay(firstname, lastname);
  const icon = getIconForNotification("missed");
  desktopNotification.showNotification({
    key: meeting_id,
    title: "Missed Call",
    options: {
      tag: meeting_id,
      body: `${fullName}`,
      icon: icon,
      requireInteraction: true,
      renotify: true,
      silent: true,
    },
  });
};

export const showIncomingCallNotification = ({
  meeting_id,
  useruuid,
  userInfo,
}) => {
  if (!meeting_id || !useruuid) {
    // prettier-ignore
    console.error("Error@showMissedCallNotification: invalid details", meeting_id, useruuid);
  }
  if (!userInfo) {
    const state = store.getState();
    userInfo = state.data?.userInfo ?? {};
  }
  const userDetails = userInfo[useruuid] || {};
  const { firstname, lastname } = userDetails;
  const fullName = formatFullNameDisplay(firstname, lastname);
  const icon = getIconForNotification("incoming");
  desktopNotification.showNotification({
    key: meeting_id,
    title: "Incoming Call",
    options: {
      tag: meeting_id,
      body: `${fullName}`,
      icon: icon,
      requireInteraction: true,
      renotify: true,
      silent: true,
    },
    timeout: VideoCallNotificationTimeout * 1000,
  });
};
