/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect, useCallback } from "react";
import { Divider, Dropdown, Skeleton, Tooltip } from "antd";
import {
  Actions,
  DateFormat,
  PhotoResolution,
  sessionType,
} from "@commscopemycloud/humaui/Utilities/Constants";
import MainPage from "../../MainPage";
import {
  ScheduleContactIcon,
  ScheduleNewContactIcon,
  SocialIcon,
  CallIcon,
  TaskIcon,
  ScheduleAddPlusIcon,
  CheckIcon,
  ScheduleListNewIcon,
  RightArrowIcon,
  LeftArrowIcon,
  JoinCallIcon,
  DoneIcon
} from "../Common/Icons";
import {
  getDateFormat,
  getTimeFormat,
  getTimeZoneFormat,
} from "@commscopemycloud/humaui/Utilities/DateTime";
import {
  getProfilePicUrl,
  getUsername,
  isSocial,
} from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { getContacts } from "./Contacts";
import { HSDatePicker } from "../Common/HSWidget";
import { UserImage } from "./UserImage";
import { getProfilePics } from "@commscopemycloud/humaui/Store/dataStore";
import { useDispatch, useSelector } from "react-redux";
import {
  Access,
  KnownActionsList,
  checkRoleAccess,
  isActionAllowed,
} from "@commscopemycloud/humaui/Utilities/AccessControl";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import moment from "moment";
import { startVideoCall } from "@commscopemycloud/humaui/Store/videoCallStore";
import { getPermissions } from "@commscopemycloud/humaui/Store/permisssionsStore";
import { Tabs } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const CHECK_INTERVAL_MS = 20000;
const SHOW_STATUS_BEFORE_MINUTES = 15;
const ONGOING_MEETING_DISPLAY_TIME_HOURS = 4;
const SHOW_JOIN_STATUS_TILL_MINUTES = ONGOING_MEETING_DISPLAY_TIME_HOURS * 60;

const ListSchedule = (props) => {
  const {
    onlyContent,
    parentBread,
    selectedDate,
    setSelectedDate,
    scheduleList,
    fetchingScheduleList,
    onActionClick,
    contacts,
    userInfo,
    userHubsData,
    rolename,
    hubRecord,
    openSubMenu = false,
    loginUserTimeformat,
    setParentSelectedTab, // Add callback prop to set selectedTab in Schedule
  } = props;

  const trans = useSelector(translator);
  const dispatch = useDispatch();
  const resourcesMap = useSelector(getProfilePics());
  const header = parentBread || []; 
  const currentUser = useSelector(getCurrentUser);
  const useruuid = useMemo(() => currentUser?.useruuid, [currentUser]);
  const permissions = useSelector(getPermissions());
  const currentUserData = useSelector((user) => user.data.userInfo);
  const currentUserDataInfo = currentUserData[ openSubMenu ? hubRecord?.useruuid :  useruuid ];
  const userTimezone =  getTimeZoneFormat(currentUserDataInfo.timezone);
  const [buttonStatus, setButtonStatus] = useState(false);
  const items = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "call",
      label: "Calls",
    },
    {
      key: "task",
      label: "Tasks",
    },
    {
      key: "reminder",
      label: "Reminder",
    },
  ];
  const [selectedTab, setSelectedTab] = useState("all"); // Default to "All" // "all" for All, "calls" for Calls, etc.

  useEffect(() => {
    // Notify parent component about selectedTab changes
    if (setParentSelectedTab) setParentSelectedTab(selectedTab);
  }, [selectedTab]);


  const getRelativeTime = (eventTimestamp, timeZone) => {
    const now = timeZone ? dayjs().tz(timeZone) : dayjs();
    const eventTime = timeZone ? dayjs(eventTimestamp).tz(timeZone) : dayjs(eventTimestamp);
  
    const diffInMinutes = now.diff(eventTime, "minute");
  
    if (diffInMinutes < 0) return null; // Event is in the future
    if (diffInMinutes < 60) return `${diffInMinutes} min${diffInMinutes > 1 ? "s" : ""} ago`;
  
    const diffInHours = now.diff(eventTime, "hour");
    if (diffInHours < 24) return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
  
    return null; // Older than 24 hours
  };

  const allowAddEvent = useMemo(
    () =>
      (rolename === undefined ||
        isActionAllowed(
          rolename,
          KnownActionsList.create_events,
          permissions
        )) &&
      hubRecord,
    [rolename, permissions, hubRecord]
  );

  const allowAddTask = useMemo(
    () => checkRoleAccess(Access.beta.labsPreview) && hubRecord,
    [hubRecord]
  );

  const dropdown = [
    !fetchingScheduleList &&
      allowAddEvent && {
        label: (
          <div onClick={onActionClick(Actions.addEvent, userInfo)}>
            Add Call
          </div>
        ),
        key: "addevent",
      },
    !fetchingScheduleList &&
      allowAddEvent && {
        label: (
          <div onClick={onActionClick(Actions.addEvent, userInfo)}>
            Add Reminder
          </div>
        ),
        key: "editevent",
      },
     {
        label: (
          <div onClick={onActionClick(Actions.addTask, userInfo)}>Add Task</div>
        ),
        key: "addtask",
      },
  ];
 
  const formatDateToDisplayEventDate =(date)=> {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const formatList = useCallback(
    (scheduleList) => {
      const selectedDateTime = new Date(selectedDate);

      let filteredList = scheduleList.filter((event) => {
        const eventDate = new Date(event.event_timestamp);
        if (isNaN(eventDate.getTime())) {
          console.error("Invalid event timestamp:", event.event_timestamp);
          return false; // Skip this event
        }
        // Compare formatted dates
        const isMatchingDate =
          event.display_event_date ===
          formatDateToDisplayEventDate(selectedDateTime);

        return isMatchingDate;
      });

    const list = filteredList.reduce((acc, event) => {
      const dateObj = getDateFormat(event.event_timestamp, userTimezone);
      const date = dateObj.date;

      if (!acc[date]) {
        acc[date] = [];
      }

      const duplicate = acc[date].some(
        (e) => e.schedule_eventuuid === event.schedule_eventuuid
      );

      if (!duplicate) {
        acc[date].push(event);
      }

      return acc;
    }, {});

    return Object.values(list).sort((a, b) => {
      return new Date(a[0]?.event_timestamp) - new Date(b[0]?.event_timestamp);
    });
  }, [selectedDate, userTimezone]);

  const formattedList = useMemo(() => formatList(scheduleList), [scheduleList, formatList]);

  const [filteredEventListonTab, setFilteredEventListonTab] = useState({
    data: [],
    count: 0,
  });

  const filteredListonTab = (selectedTab) => {
    let filteredListData;
    
    setFilteredEventListonTab({
      data: [],
    count: 0,
    })

    if (selectedTab === "call") {
      filteredListData = formattedList.map((group) =>
        group.filter(
          (item) =>
            item?.event_type === "schedule" || item?.event_type === "zoom"
        )
      );
    } else if (selectedTab === "task") {
      filteredListData = formattedList.map((group) =>
        group.filter((item) => !item?.event_type)
      );      
    } else if (selectedTab === "reminder") {
      filteredListData = formattedList.map((group) =>
        group.filter((item) => item?.event_type === "reminder")
      );
    } else {
      filteredListData = formattedList; // Default case
    }
    // Flatten the array and calculate total count
    const totalFilteredCount = filteredListData.flat().length;

    setFilteredEventListonTab({
      data: filteredListData,
      count: totalFilteredCount,
    }); // Update state
  };
  
  const handleTabChange = (selectedTab) => {
    setSelectedTab(selectedTab); // Update the selected tab
    setFilteredEventListonTab({
      data: [],
      count: 0,
    }); // Clear the list to ensure fresh data
    filteredListonTab(selectedTab); // Filter data based on selectedTab
  };

  useEffect(() => {
    if (formattedList.length > 0) {
      filteredListonTab(selectedTab); // Apply filter on load or when dependencies change
    }
  }, [selectedTab, formattedList, selectedDate]);  

  const renderEventType = (eventType) => {
    switch (eventType) {
      case "schedule":
      case "zoom": // Handles both "schedule" and "zoom" types
        return (
          <div className="event-type">
            <span style={{justifyContent: "center", display: "flex"}} >
              <CallIcon className="event-type-icon event-icon-color"
              />{" "}
            </span>
            <span>Call</span>
          </div>
        );
        case "tasks":
        return (
          <div className="event-type">
            <TaskIcon className="event-type-icon"             
            />{" "}
            Task
          </div>
        );
      case "reminder":
        return (
          <div className="event-type">
            <SocialIcon className="event-type-icon event-icon-color "
            />{" "}
            Reminder
          </div>
        );
      default:
        return null; // Return null for unsupported types
    }
  };

  const handleDateSelect = useCallback(
    (date) => {
      setSelectedDate(date);
      setFilteredEventListonTab({
        data: [],
        count: 0,
      }); // Clear outdated data
      filteredListonTab(selectedTab); // Reapply filtering
    },
    [selectedTab, filteredListonTab]
  );
  
  const contactImage = useCallback(
    (contact) => {
      let displayName = getUsername(contact);
      const url = getProfilePicUrl(
        contact.useruuid,
        resourcesMap,
        PhotoResolution.R64
      );
      return <UserImage name={displayName} url={url} />;
    },
    [resourcesMap]
  );

  const renderDateListItem = useCallback(
    (item) => {
      let dateObj = getDateFormat(item.event_timestamp, userTimezone);
      return (
        <div
          key={dateObj.dayDateformat}
          className={
            dateObj.dayDateformat.startsWith("Today")
              ? "schedule-list-date-newui schedule-list-today-newui"
              : "schedule-list-date-newui"
          }
        >
          <div className="schedule-date-newui">{dateObj.dayDateformat}</div>
        </div>
      );
    },
    [userTimezone]
  );

  const renderInvitedList = useCallback(
    (invitedUseruuid) => {
      let invitedContacts = getContacts(invitedUseruuid, [
        ...(contacts ?? []),
        ...(userHubsData ?? []),
        ...(userInfo ? [userInfo] : []),
      ]);

      const count = invitedContacts.length;

      const invitedContactsData = invitedContacts
        .slice(0, 3)
        .map((contact, index) => (
          <div key={index} className="user-image-container">
            {contact && contactImage(contact)}
          </div>
        ));
      return { invitedContactsData, count };
    }, [contacts, userHubsData, userInfo, contactImage]
  );

  const handleButtonClick = useCallback(
    (item) => {
      if (item.event_type?.toLowerCase() === sessionType.zoom) {
        window.open(item.meeting_url);
      } else {
        dispatch(
          startVideoCall({
            meeting_id: item.schedule_eventuuid,
            eventInfo: item,
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setButtonStatus((prevStatus) => !prevStatus);
    }, 2000);
    setButtonStatus((prevStatus) => !prevStatus);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const DaysOfWeekRow = (daysOfWeek) => {
    return (
      <div className="days_of_week">
        {daysOfWeek?.map((day, index) => (
          <div key={index} className="day_info">
            {day.charAt(0).toUpperCase() + day.slice(1, 3).toLowerCase()}
          </div>
        ))}
      </div>
    );
  };

  const capitalizeFirstLetter = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  };

  const EventDaysInfo = (daysOfWeek) => {
    if (!daysOfWeek || daysOfWeek.length === 0) return ""; // No days
    const capitalizedDays = daysOfWeek.map(capitalizeFirstLetter); // Capitalize each day
    
    if (capitalizedDays.length === 1) return `Repeats ${capitalizedDays[0]}`; // Only one day
    if (capitalizedDays.length === 2) return `Repeats ${capitalizedDays[0]} and ${capitalizedDays[1]}`; // Two days

    // More than two days
    const lastDay = capitalizedDays[capitalizedDays.length - 1]; // Get last day
    const otherDays = capitalizedDays.slice(0, -1).join(", "); // Join all except the last
    return `Repeats ${otherDays} and ${lastDay}`; // Add 'and' before the last day
  };

  const renderButtonStatus = useCallback(
    (item) => {
      if (item.event_type?.toLowerCase() === "reminder") {
        return null;
      }
      if (!item.invited_contacts.includes(useruuid)) {
        return null;
      }

      const now = moment();
      const eventTime = moment(item.event_timestamp);
      const diff = eventTime.diff(now, "minute");

      if (diff > 5 && diff <= SHOW_STATUS_BEFORE_MINUTES) {
        return (
          <div className="status" onClick={() => handleButtonClick(item)}>
            About to Start
          </div>
        );
      } else if (diff <= 5 && diff >= -SHOW_JOIN_STATUS_TILL_MINUTES) {
        return (
          <div className="join_btn" onClick={() => handleButtonClick(item)}>
            <JoinCallIcon  style={{width: "120px", cursor: "pointer"}}/>
          </div>
        );
      } else {
        return null;
      }
    },
    [handleButtonClick, useruuid]
  );

  const renderNoList = useCallback(() => {
    let currentDate = getDateFormat(moment(), userTimezone);
    return (
      <div
        className={
          currentDate.dayDateformat.startsWith("Today")
            ? "schedule-list-date-newui schedule-list-today-newui"
            : "schedule-list-date-newui"
        }
      >
        {formattedList.length === 0 && (
          <div className="no-events">
            {`${trans("TOTAL")}${
            " ( " + formattedList?.length + " )"
          }`}
          </div>
        )}
      </div>
    );
  }, [formattedList, userTimezone, trans]);

  const renderScheduleList = useCallback(() => {
    return (
      <div className="schedule-list-container-newui">
        <div className="tab_view">
          <Tabs defaultActiveKey="1" items={items} onChange={handleTabChange} />
          <Dropdown
            menu={{
              items: dropdown,
            }}
            trigger={["click"]}
            placement="bottomRight"
            overlayClassName="event_dropdown"
            overlayStyle={{ minWidth: "120px !important" }}
          >
            <a onClick={(e) => e.preventDefault()}>
              {openSubMenu && (
                <ScheduleAddPlusIcon />
              )}
            </a>
          </Dropdown>
        </div>
        {fetchingScheduleList ? (
          <div className="schedule-container-newui skeleton-container-newui">
            <Skeleton active avatar paragraph={false} />
          </div>
        ) : (filteredEventListonTab?.data).length === 0 ? (
          renderNoList()
        ) : (
          filteredEventListonTab?.data?.map((list, index) => (
            <div key={"schedule_day_" + index} className="schedule-list-data">
              <div className="filtered_event_count">
                {`${trans("TOTAL")}${
                  " ( " + filteredEventListonTab?.count + " )"
                }`}
              </div>
              {list.map((item) => (
                <div
                  key={item.schedule_eventuuid}
                  className="schedule-container-newui"
                >
                  <div className="schedule-list-row1-newui">
                    <div
                      onClick={onActionClick(Actions.startEvent, item)}
                      className="container-1"
                    >
                      <div className="eventype-container">
                        {renderEventType(item.event_type?.toLowerCase())}
                      </div>
                      {(item.eventType === "schedule" || item.eventType === "zoom") ? (
                        <ScheduleNewContactIcon />
                      ) : (
                        <div
                          className="empty-action"
                          style={{ width: "30px", marginLeft: "10px" }}
                        ></div>
                      )}
                      <div className="schedule-eventname-newui">
                        {item.event_name}
                      </div>
                    </div>
                    <div className="container-2">
                      <div
                        title={userTimezone}
                        className="schedule-eventtime-newui"
                      >
                        {loginUserTimeformat &&
                        loginUserTimeformat?.is24hoursformat
                          ? getTimeFormat(
                              item.event_timestamp,
                              userTimezone,
                              loginUserTimeformat?.is24hoursformat
                            )
                          : getTimeFormat(
                              item.event_timestamp,
                              userTimezone,
                              loginUserTimeformat?.is24hoursformat
                            )}
                      </div>
                      {selectedTab === "task" && ( /* Task Status Icons */
                        item?.task_status === "completed" ? (
                          <DoneIcon />
                          ) : item?.task_status === "dismissed" ? (
                           null
                          ) : (
                            null // Render nothing if no task status
                          )
                      )} 
                    </div>
                  </div>
                  <div className="schedule-list-row2-newui">
                    <div className="schedule-list-row2-container1">
                    <div className="schedule-meeting-count">
                      <ScheduleContactIcon className="account-icon" />
                      {renderInvitedList(item.invited_contacts).count > 0 && (
                        <div className="invited-count">
                          {renderInvitedList(item.invited_contacts).count}
                        </div>
                      )}
                    </div>
                    <Divider type="vertical" />
                    <div className="invited-list">
                      {
                        renderInvitedList(item.invited_contacts)
                          .invitedContactsData
                      }
                      {renderInvitedList(item.invited_contacts).count > 3 && (
                        <div className="user-image-container user-more">
                          + {renderInvitedList(item.invited_contacts).count - 3}
                        </div>
                      )}
                    </div>
                    {getRelativeTime(item.event_timestamp, userTimezone) && (
                      <>
                        <Divider type="vertical" />
                        <div className="reminder_data">
                          <span>Reminders:</span>
                          <span className="hour_data">{getRelativeTime(item.event_timestamp, userTimezone)}</span>
                        </div>
                      </>
                     )}
                    {item.days_of_week && (
                    <>
                    <Divider type="vertical" />
                    <div className="event_days_info">
                      <span>Repeats:</span>
                      <Tooltip title={EventDaysInfo(item.days_of_week)} placement="bottom">
                        <>{DaysOfWeekRow(item.days_of_week)}</>
                      </Tooltip>
                    </div>
                    </>)}
                    </div>
                    {item?.schedule_eventuuid &&
                      moment(item?.event_timestamp).isSame(moment(), "day") && (
                        <div className="schedule-list-row2-container2">{renderButtonStatus(item)}</div>
                      )}
                  </div>
                </div>
              ))}
            </div>
          ))
        )}
      </div>
    );
  }, [fetchingScheduleList, 
      allowAddEvent, 
      allowAddTask, 
      formattedList, 
      onActionClick, 
      renderDateListItem, 
      renderButtonStatus, 
      renderInvitedList, 
      handleDateSelect, 
      trans, 
      userInfo, 
      selectedDate, 
      userTimezone,
      filteredEventListonTab,
      renderNoList
      ]);

  const headerSection = useCallback(() => {
    return (
      <div className="schedule-header-newui">
        <div className="schedule-title-newui">
          <div className="schedule-text-newui">
            {openSubMenu ? (
              <div className="hub-schedule-header">
                <div className="hub-icon">
                  <ScheduleListNewIcon className="hub-schedule-icon" />
                </div>
                <div className="hub-schedule-title">{trans("SCHEDULE")}</div>
              </div>
            ) : (
              trans("MYSCHEDULE")
            )}
          </div>
        </div>
        <div className="date-picker-container">
          <HSDatePicker
            DateFormat={DateFormat}
            timeZone={userTimezone}
            defaultValue={selectedDate}
            onDateSelect={handleDateSelect}
          />
        </div>
      </div>
    );
  }, [openSubMenu, trans]);

  return onlyContent ? (
    <div className="list-schedule-container-newui page-content-wrapper">
      {headerSection()}
      <div className={`${!openSubMenu ? "schedule-content-newui" : ""}`}>
        {renderScheduleList()}
      </div>
    </div>
  ) : (
    <MainPage
      hasSider={!openSubMenu}
      header={header}
      noModals={!!openSubMenu}
    >
      <div className="list-schedule-container-newui page-content-wrapper">
        {headerSection()}
        <div className={`${!openSubMenu ? "schedule-content-newui" : ""}`}>
          {renderScheduleList()}
        </div>
      </div>
    </MainPage>
  );
};

export default ListSchedule;