import React, { useEffect, useState, useMemo } from "react";
import { Line } from "react-chartjs-2";
import { Radio, Spin } from "antd";
import "chart.js/auto";
import moment from "moment";

const TrendChart = ({
  outdoorData = {},
  indoorData = {},
  deviceUuidToShowTrend,
  tempUnit,
  location,
  loginUserTimeformat,
  selectedRange,
  setSelectedRange,
}) => {
  const [view, setView] = useState("temperature");
  // We'll store chartData as an object: { labels: [...], datasets: [...], rawTimestamps: [...] }
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Build chartData in useEffect
  useEffect(() => {
    const dataMap = {};

    // -- Process outdoor data
    Object.keys(outdoorData).forEach((date) => {
      const outdoor = outdoorData[date].outdoor?.environData;
      if (!outdoor) return;

      Object.keys(outdoor.hourlyTemp).forEach((hour) => {
        const dateTime = moment(date).hour(hour).format("YYYY-MM-DD HH:mm");
        if (!dataMap[dateTime]) {
          dataMap[dateTime] = { dateTime };
        }
        dataMap[dateTime].outdoorTemperature = outdoor.hourlyTemp[hour];
        dataMap[dateTime].outdoorHumidity = outdoor.hourlyHumidity[hour];
      });
    });

    // -- Process indoor data
    Object.keys(indoorData).forEach((date) => {
      const indoor = indoorData[date][deviceUuidToShowTrend];
      if (!indoor) return;

      const environData = indoor.environData;
      Object.keys(environData.hourlyTemp).forEach((hour) => {
        const dateTime = moment(date).hour(hour).format("YYYY-MM-DD HH:mm");
        if (!dataMap[dateTime]) {
          dataMap[dateTime] = { dateTime };
        }
        dataMap[dateTime].indoorTemperature = environData.hourlyTemp[hour];
        dataMap[dateTime].indoorHumidity = environData.hourlyHumidity[hour];
      });
    });

    // Sort the combined data by dateTime
    const sortedData = Object.values(dataMap).sort(
      (a, b) => new Date(a.dateTime) - new Date(b.dateTime)
    );

    // Convert temperature based on unit
    const convertTemperature = (temp) => {
      return tempUnit === "C" ? ((temp - 32) * 5) / 9 : temp;
    };

    const createDataset = (label, dataArr, borderColor, borderDash) => ({
      label,
      data: dataArr.map((temp) =>
        temp !== undefined ? convertTemperature(temp) : null
      ),
      borderColor,
      borderDash,
      borderWidth: 2,
      fill: false,
      tension: 0.4,
      spanGaps: true,
      pointRadius: 0,
      pointHoverRadius: 6,
      pointStyle: "circle",
    });

    // Filter by selectedRange
    const today = new Date();
    const rangeDays =
      selectedRange === "today"
        ? 1
        : selectedRange === "weekly"
        ? 7
        : selectedRange === "monthly"
        ? 30
        : 30;
    const cutoff = new Date(today.setDate(today.getDate() - rangeDays));

    const filtered = sortedData.filter(
      (entry) => new Date(entry.dateTime) >= cutoff
    );

    // Build the Chart.js-compatible object
    // We'll store an extra array 'rawTimestamps' so we can retrieve original dateTime for the tooltip
    const labels = filtered.map((entry) =>
      moment(entry.dateTime).format("MMM DD, YYYY")
    );
    const rawTimestamps = filtered.map((entry) => entry.dateTime);

    const chartMappedData = {
      labels,
      rawTimestamps, // Not a standard Chart.js field, but we can store custom data
      datasets:
        view === "temperature"
          ? [
              createDataset(
                "Outdoor Temperature",
                filtered.map((e) => e.outdoorTemperature),
                "darkgray",
                [5, 5]
              ),
              createDataset(
                "Indoor Temperature",
                filtered.map((e) => e.indoorTemperature),
                "orange"
              ),
            ]
          : [
              createDataset(
                "Outdoor Humidity",
                filtered.map((e) => e.outdoorHumidity),
                "darkgray",
                [5, 5]
              ),
              createDataset(
                "Indoor Humidity",
                filtered.map((e) => e.indoorHumidity),
                "orange"
              ),
            ],
    };

    setChartData(chartMappedData);
    setLoading(false);
  }, [outdoorData, indoorData, view, selectedRange, tempUnit]);

  // Use useMemo for chartOptions so it doesn't recreate on every render
  const chartOptions = useMemo(() => {
    if (!chartData) return {};

    return {
      scales: {
        x: {
          ticks: {
            maxTicksLimit: 10,
          },
        },
      },
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            label: function (context) {
              // 'context.raw' is the Y-value
              const value =
                context.raw !== null ? context.raw.toFixed(1) : "N/A";

              // Instead of referencing chartData as an array of objects,
              // use the 'rawTimestamps' array we created
              const timeString = chartData.rawTimestamps?.[context.dataIndex];
              const formattedTime = timeString
                ? moment(timeString).format(
                    loginUserTimeformat?.is24hoursformat
                      ? "HH:mm"
                      : "h:mm A"
                  )
                : "N/A";

              if (context.dataset.label.includes("Humidity")) {
                return [
                  `${context.dataset.label}: ${value}%`,
                  `Time: ${formattedTime}`,
                ];
              } else {
                return [
                  `${context.dataset.label}: ${value}°${tempUnit}`,
                  `Time: ${formattedTime}`,
                ];
              }
            },
          },
        },
        legend: {
          display: true,
        },
        datalabels: {
          display: false, // Explicitly disable any data labels
        },
      },
      interaction: {
        mode: "nearest",
        intersect: false,
      },
      elements: {
        point: {
          radius: 0,
          hoverRadius: 6,
        },
      },
      animation: false,
    };
  }, [chartData, loginUserTimeformat, tempUnit]);

  // Range selection
  const handleRangeChange = (range) => {
    setSelectedRange(range);
  };

  return (
    <div>
      <div className="graph-location-container">
        <span className="graph-location-title">
          Location : {location ? location : "-"}
        </span>
      </div>

      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Radio.Group
          value={selectedRange}
          onChange={(e) => handleRangeChange(e.target.value)}
        >
          <Radio.Button value="today">Today</Radio.Button>
          <Radio.Button value="weekly">Weekly</Radio.Button>
          <Radio.Button value="monthly">Monthly</Radio.Button>
        </Radio.Group>
      </div>

      <div style={{ textAlign: "center", marginBottom: "20px" }}>
        <Radio.Group value={view} onChange={(e) => setView(e.target.value)}>
          <Radio.Button value="temperature">Temperature</Radio.Button>
          <Radio.Button value="humidity">Humidity</Radio.Button>
        </Radio.Group>
      </div>

      {loading ? (
        <Spin spinning={loading} style={{ margin: "70px -20px" }} />
      ) : chartData ? (
        <Line data={chartData} options={chartOptions} />
      ) : (
        <p>Data Not found.</p>
      )}
    </div>
  );
};

export default TrendChart;
