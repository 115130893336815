import React, { useEffect, useState, useRef, useMemo } from "react";
import "./MotionActivity.less";
import { useSelector, useDispatch } from "react-redux";
import {
  MotionActivityIcon2,
  NewHC200Icon,
  ArrowRightIcon,
  ArrowLeftIcon,
  MorningIcon,
  NightIcon,
  MotionEmpty,
  BatteryHighIcon,
  BatteryMediumIcon,
  BatteryLowIcon,
  BatteryZeroIcon,
  ArrowUpMotion,
  ArrowDownMotion,
} from "../Common/Icons";
import { Spin, Tooltip } from "antd";
import MotionCalendar from "./MotionCalendar";
import { DefaultTimezone } from "@commscopemycloud/humaui/Utilities/Constants";
import moment from "moment";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import {
  get_utc_offset,
  processMotionData,
  sortByModelAndLocation,
  dayORNight,
  convertTo24HourFormat,
  timeArray,
  processHistMotionData,
  processPastEnvironmentalData,
  MINIMUM_MOTION_COUNT_PERHOUR,
} from "./MotionActivityUtils";
import {
  setHistoricalData,
  selectHistoricalData,
} from "@commscopemycloud/humaui/Store/historicalDataStore";
import MotionLineChart from "./MotionLineChart";
import MOTION_RANGES from "../../../motionRanges.json";

const MotionActivity = ({
  hubRecord,
  timezone,
  deviceStatus,
  loginUserTimeformat,
  isTrend = null,
}) => {
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateToDisplay, setDateToDisplay] = useState(new Date().toDateString());
  const timezoneOffset = useMemo(
    () => get_utc_offset(timezone, DefaultTimezone),
    [timezone]
  );
  const [activityDateStart, setActivityDateStart] = useState(
    moment().startOf("day").format()
  );
  const [activityDateEnd, setActivityDateEnd] = useState(
    moment().endOf("day").format()
  );
  const [shiftDate, setShiftDate] = useState(null);
  const [dataPerHour, setDataPerHour] = useState(null);
  const [hideLeftArrow, setHideLeftArrow] = useState(false);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const [allDevicesFetched, setAllDevicesFetched] = useState({});
  const [showPastDataDevice, setShowPastDataDevice] = useState([]);
  const [pastDataPerHour, setPastDataPerHour] = useState(null);
  const [pastDataLoaded, setPastDataLoaded] = useState(false);
  const [lightData, setLightData] = useState({});
  const calendarRef = useRef(null);

  const smallRangeMin = MOTION_RANGES?.SMALL_RANGE_MIN || 0;
  const smallRangeMax = MOTION_RANGES?.SMALL_RANGE_MAX || 50;
  const smallRangeVal = MOTION_RANGES?.SMALL_RANGE_VALUE || 8;
  const mediumRangeMin = MOTION_RANGES?.MEDIUM_RANGE_MIN || 50;
  const mediumRangeMax = MOTION_RANGES?.MEDIUM_RANGE_MAX || 100;
  const mediumRangeVal = MOTION_RANGES?.MEDIUM_RANGE_VALUE || 13;
  const largeRangeMin = MOTION_RANGES?.LARGE_RANGE_MIN || 100;
  const largeRangeMax = MOTION_RANGES?.LARGE_RANGE_MAX || 200;
  const largeRangeVal = MOTION_RANGES?.LARGE_RANGE_VALUE || 18;
  const xLargeRangeMin = MOTION_RANGES?.XLARGE_RANGE_MIN || 200;
  const xLargeRangeVal = MOTION_RANGES?.XLARGE_RANGE_VALUE || 28;

  const motionBubble = (occ) => {
    if (occ > smallRangeMin && occ < smallRangeMax)
      return smallRangeVal; // small
    else if (occ >= mediumRangeMin && occ < mediumRangeMax)
      return mediumRangeVal; // medium
    else if (occ >= largeRangeMin && occ < largeRangeMax)
      return largeRangeVal; // large
    else if (occ >= xLargeRangeMin) return xLargeRangeVal; //x-large
  };

  const userEventsApi = useSelector((state) => state.apis.userEventsApi);
  const trans = useSelector(translator);
  const dispatch = useDispatch();

  const storedDataSelector = (state, useruuid) => {
    const historicalData = {};
    const daysToFetch = [1, 2, 3, 4, 5, 6];
    daysToFetch.forEach((i) => {
      const date = moment(dateToDisplay)
        .subtract(i, "days")
        .format("YYYY-MM-DD");
      if (!historicalData[useruuid]) {
        historicalData[useruuid] = {};
      }
      historicalData[useruuid][date] = selectHistoricalData(
        state,
        useruuid,
        date
      );
    });
    return historicalData;
  };

  const storedData = useSelector((state) =>
    storedDataSelector(state, hubRecord.useruuid)
  );

  const fetchMotionData = () => {
    setDataLoaded(false);
    const applyTimezoneOffset = (date) =>
      date.replace(/Z|(\+|-)\d\d:\d\d$/, timezoneOffset);
    const activityDateStartWithOffset = applyTimezoneOffset(activityDateStart);
    const activityDateEndWithOffset = applyTimezoneOffset(activityDateEnd);

    const errorCallback = (error) => {
      console.error(error);
    };
    const successCallback = (data) => {
      console.log("motion data", data);
      processMotionData(
        data,
        dateToDisplay,
        timezone,
        timezoneOffset,
        allDevicesFetched,
        setDataPerHour,
        setDataLoaded,
        loginUserTimeformat
      );
    };

    try {
      userEventsApi.userMotionEventsByTimeRange(
        hubRecord.useruuid,
        activityDateStartWithOffset,
        activityDateEndWithOffset,
        {},
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const fetchHistoricalDataForPastSixDays = async (storedData, key) => {
    const useruuid = hubRecord.useruuid;
    const historicalData = {};
    const applyTimezoneOffset = (date) =>
      date.replace(/Z|(\+|-)\d\d:\d\d$/, timezoneOffset);
    const daysToFetch = [1, 2, 3, 4, 5, 6];
    const promises = [];

    for (const i of daysToFetch) {
      const date = moment(dateToDisplay)
        .subtract(i, "days")
        .format("YYYY-MM-DD");

      if (storedData[useruuid] && storedData[useruuid][date]) {
        historicalData[date] = storedData[useruuid][date];
        continue;
      }

      const activityDateStart = moment(date).startOf("day").format();
      const activityDateEnd = moment(date).endOf("day").format();

      const activityDateStartWithOffset =
        applyTimezoneOffset(activityDateStart);
      const activityDateEndWithOffset = applyTimezoneOffset(activityDateEnd);

      const errorCallback = (error) => {
        console.error(`Error fetching data for ${date}:`, error);
      };

      const successCallback = (data) => {
        try {
          console.log(`Historical motion data for ${date}`, data);
          historicalData[date] = data;
          dispatch(
            setHistoricalData({ useruuid: hubRecord.useruuid, date, data })
          );
        } catch (parseError) {
          console.error(`Error parsing data for ${date}:`, parseError);
        }
      };

      const fetchPromise = new Promise((resolve, reject) => {
        try {
          userEventsApi.userDeviceEventsByTypesAndTimeRange(
            hubRecord.useruuid,
            activityDateStartWithOffset,
            activityDateEndWithOffset,
            {
              deviceuuid: Object.keys(allDevicesFetched),
              eventtypes: ["motion_detected"],
            },
            apiCallback({
              translator: trans,
              failCallback: (error) => {
                errorCallback(error);
                reject(error);
              },
              errorCallback: (error) => {
                errorCallback(error);
                reject(error);
              },
              successCallback: (data) => {
                successCallback(data);
                resolve(data);
              },
            })
          );
        } catch (error) {
          errorCallback(error);
          reject(error);
        }
      });

      promises.push(fetchPromise);
    }

    await Promise.all(promises);
    return historicalData;
  };

  useEffect(() => {
    if (!!Object.keys(allDevicesFetched).length) {
      isTrend && fetchPastEnvironmentalData();
    }
  }, [isTrend, dateToDisplay, allDevicesFetched]);

  const fetchPastEnvironmentalData = () => {
    const applyTimezoneOffset = (date) =>
      date.replace(/Z|(\+|-)\d\d:\d\d$/, timezoneOffset);
    const activityDateStartWithOffset = applyTimezoneOffset(activityDateStart);
    const activityDateEndWithOffset = applyTimezoneOffset(activityDateEnd);

    const errorCallback = (error) => {
      console.error(error);
    };
    const successCallback = (data) => {
      processPastEnvironmentalData(
        data,
        dateToDisplay,
        timezone,
        timezoneOffset,
        setLightData
      );
      console.log("historical motion data", data);
    };

    try {
      userEventsApi.userDeviceEventsByTypesAndTimeRange(
        hubRecord.useruuid,
        activityDateStartWithOffset,
        activityDateEndWithOffset,
        {
          deviceuuid: Object.keys(allDevicesFetched), //["5a3b04b4-347b-4358-ba1d-66538a5154cd"],
          eventtypes: ["environment_status"],
        },
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  const renderMotionAsPercent = (
    deviceModel,
    hoursIn24Format,
    dataPerHour,
    isTrend
  ) => {
    if (dataPerHour == null) return;
    var visible = isTrend ? "hidden" : "";
    const motionCount = dataPerHour[hoursIn24Format] || 0;
    const shouldHideBubble =
      deviceModel === "HC200" && motionCount <= MINIMUM_MOTION_COUNT_PERHOUR;

    const radiusBubble = dataPerHour.hasOwnProperty(hoursIn24Format)
      ? motionBubble(motionCount)
      : (visible = "hidden");

    const commonStyles = {
      border: "0px",
      borderRadius:
        hoursIn24Format === "0"
          ? "5px 0px 0px 5px"
          : hoursIn24Format === "23"
          ? "0px 5px 5px 0px"
          : "",
    };

    return visible === "hidden" ? (
      <div
        className="motion-empty-block"
        style={isTrend ? { ...commonStyles, height: "64px" } : commonStyles}
      >
        {isTrend ? null : <MotionEmpty style={{ margin: "6px" }} />}
      </div>
    ) : (
      <div className="motion-percent-block">
        <div
          className={`motion-bubble ${shouldHideBubble ? "hide-bubble" : ""}`}
          style={{
            width: `${radiusBubble}px`,
            height: `${radiusBubble}px`,
          }}
        ></div>
      </div>
    );
  };

  const renderMorningNightIcon = (hour) => {
    const icon =
      hour === "6 AM" ? (
        <MorningIcon />
      ) : hour === "6 PM" ? (
        <NightIcon />
      ) : null;

    return <div style={{ width: "12px", height: "16px" }}>{icon}</div>;
  };

  const lastHourEntry = useMemo(() => {
    if (!dataPerHour || Object.keys(dataPerHour).length === 0) return -1;

    const [{ hourlyData = {} } = {}] = Object.values(dataPerHour);
    const keys = Object.keys(hourlyData?.hourlyData);

    if (!keys.length) return -1;
    const lastKey = parseInt(keys[keys.length - 1], 10);
    return isNaN(lastKey) ? -1 : lastKey;
  }, [dataPerHour]);

  const renderHours = (
    deviceModel = null,
    hour,
    dataPerHour,
    timeRanges,
    lastHourEntry,
    type = null,
    lightData = {}
  ) => {
    const hoursIn24Format = convertTo24HourFormat(hour);

    const currentDay = moment.tz(timezone.split(" ")[0]).format("YYYY-MM-DD");
    const displayDay = moment(dateToDisplay).format("YYYY-MM-DD");

    const isToday = type === "past" ? false : currentDay === displayDay;
    const isLaterHour = isToday && parseInt(hoursIn24Format) > lastHourEntry;

    const hasZeroData =
      dataPerHour.hasOwnProperty(hoursIn24Format) &&
      dataPerHour[hoursIn24Format] === 0;

    const timeOfDay =
      hoursIn24Format >= 6 && hoursIn24Format < 12
        ? "Morning"
        : hoursIn24Format >= 12 && hoursIn24Format < 18
        ? "Afternoon"
        : hoursIn24Format >= 18 && hoursIn24Format < 24
        ? "Evening"
        : "Night";
    const timeRange =
      timeRanges && timeRanges[hoursIn24Format]
        ? `${timeRanges[hoursIn24Format].firstReportedTime} - ${timeRanges[hoursIn24Format].lastReportedTime}`
        : "N/A";
    const event_value = dataPerHour.hasOwnProperty(hoursIn24Format)
      ? dataPerHour[hoursIn24Format]
      : "--";

    const activity_value =
      event_value === "--"
        ? "--"
        : event_value === 0
        ? "None"
        : event_value > smallRangeMin && event_value < smallRangeMax
        ? "Low"
        : event_value >= mediumRangeMin && event_value < mediumRangeMax
        ? "Medium"
        : event_value >= largeRangeMin && event_value < largeRangeMax
        ? "High"
        : "Very High";

    const tooltipContent = (
      <div className="tooltip-content">
        <div className="tooltip-info">
          <div className="tooltip-label">
            <div>{timeOfDay}</div>
            <div>{timeRange}</div>
          </div>
          <div className="tooltip-activity">
            <div>Activity: {activity_value}</div>
            <div>Events: {event_value}</div>
            {isTrend && <div>Ambient Light: {lightData[hoursIn24Format]}</div>}
          </div>
        </div>
      </div>
    );

    return (
      <div key={hour}>
        <div
          className={`main-hour-container ${isTrend ? "trend" : "not-trend"}`}
        >
          {type !== "past" && (
            <>
              <div className="day-night-theme">
                {renderMorningNightIcon(hour)}
              </div>
              <div className="day-night-time">
                {loginUserTimeformat && loginUserTimeformat?.is24hoursformat
                  ? convertTo24HourFormat(
                      hour,
                      loginUserTimeformat?.is24hoursformat
                    )
                  : (() => {
                      if (
                        hour === "12 AM" ||
                        hour === "6 AM" ||
                        hour === "12 PM" ||
                        hour === "6 PM"
                      ) {
                        return hour;
                      } else {
                        return hour.replace(/AM|PM/, "");
                      }
                    })()}
              </div>
            </>
          )}
          {!isLaterHour && (
            <Tooltip
              title={tooltipContent}
              placement="bottom"
              overlayClassName="custom-tooltip"
            >
              <div
                className={`
                  ${
                    dayORNight(hour) === "night"
                      ? "night-time-block"
                      : "day-time-block"
                  }
                  ${isTrend ? "trend" : "not-trend"}
                  ${hour === "12 AM" ? "start" : hour === "11 PM" ? "end" : ""}
                `}
              >
                {isTrend ? (
                  <div className="motion-activity-block">
                    {renderMotionAsPercent(
                      deviceModel,
                      hoursIn24Format,
                      dataPerHour,
                      isTrend
                    )}
                  </div>
                ) : (
                  <div className="motion-activity-block">
                    {!hasZeroData
                      ? renderMotionAsPercent(
                          deviceModel,
                          hoursIn24Format,
                          dataPerHour
                        )
                      : null}
                  </div>
                )}
              </div>
            </Tooltip>
          )}
          {isLaterHour && (
            <div
              className={`
              future-hour-block
              ${isTrend ? "trend" : "not-trend"}
              ${hour === "12 AM" ? "start" : hour === "11 PM" ? "end" : ""}
            `}
            ></div>
          )}
        </div>
      </div>
    );
  };

  const renderSevenDaysData = async (key) => {
    console.log("Device to show past data: ", key);
    const histData = await fetchHistoricalDataForPastSixDays(storedData, key);
    processHistMotionData(
      histData,
      timezone,
      timezoneOffset,
      allDevicesFetched,
      setPastDataPerHour,
      setPastDataLoaded,
      loginUserTimeformat
    );
  };

  const renderActivityItem = (key) => {
    const deviceKey = key;
    const item = dataPerHour[deviceKey];
    const isExpanded = showPastDataDevice.includes(deviceKey);

    return (
      <div
        className={`main-activity-container ${
          isExpanded ? "expanded" : "collapsed"
        }`}
        key={deviceKey}
      >
        {renderDeviceIcon(
          item.serialNumber,
          item.location,
          item.model,
          item.connectionStatus,
          item.batteryLevel,
          deviceKey
        )}
        <div
          className="motion-activity-container"
          style={{ display: "flex", flexDirection: "column" }}
        >
          {renderMotionActivity(item)}
          <Spin
            spinning={isExpanded && !pastDataLoaded}
            className="spin-margin"
          >
            {isExpanded &&
              pastDataLoaded &&
              Object.keys(pastDataPerHour)
                .sort((a, b) => new Date(b) - new Date(a))
                .map((dateKey) => {
                  if (pastDataPerHour[dateKey][deviceKey]) {
                    return renderPastMotionData(dateKey, deviceKey);
                  }
                  return null;
                })}
          </Spin>
        </div>

        {!isTrend && (
          <div className="past-render-btn">
            <div
              className="btn-text"
              onClick={() => handleClickPastDataToggle(deviceKey)}
            >
              {isExpanded ? (
                <>
                  <ArrowUpMotion />
                  <div>View today only</div>
                </>
              ) : (
                <>
                  <ArrowDownMotion />
                  <div>View past week</div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderActivityItemTrend = (key) => {
    const item = dataPerHour[key];
    if (item.model !== "EM200") return null;
    return (
      <div
        className="main-activity-container"
        key={key}
        style={{ minWidth: "1135px", height: "220px" }}
      >
        {renderDeviceIcon(
          item.serialNumber,
          item.location,
          item.model,
          item.connectionStatus,
          item.batteryLevel
        )}
        <div className="motion-activity-container">
          <div className="trend-legends">
            <div className="motion-legend">Motion</div>
            <div className="ambient-light-legend">Ambient Light</div>
          </div>
          {renderMotionActivityTrend(item, key)}
        </div>
      </div>
    );
  };

  const DeviceInfoContainer = ({
    deviceLocation,
    keyProp,
    serialNum,
    children,
  }) => {
    const formattedDate = moment(dateToDisplay).format("dddd MMMM D");
    const formattedStartDate = moment(dateToDisplay)
      .subtract(6, "days")
      .format("dddd MMMM D");
    const formattedEndDate = formattedDate;

    const [day, month, date] = formattedDate.split(" ");
    const [startDay, startMonth, startDate] = formattedStartDate.split(" ");
    const [endDay, endMonth, endDate] = formattedEndDate.split(" ");

    return (
      <div className="device-info-container">
        <div className="device-id-info">
          <div className="device-info-header">{deviceLocation}</div>
          <div className="device-serial-number">{serialNum}</div>
        </div>
        <div className="date-range">
          {showPastDataDevice.includes(keyProp) ? (
            <span>
              <span className="day-bold">{startDay}</span> {startMonth}{" "}
              {startDate} to <span className="day-bold">{endDay}</span>{" "}
              {endMonth} {endDate}
            </span>
          ) : (
            <span>
              <span className="day-bold">{day}</span> {month} {date}
            </span>
          )}
        </div>
        <div className="device-icon">{children}</div>
      </div>
    );
  };

  const renderDeviceIcon = (
    serialNumber = null,
    deviceLocation,
    deviceModel,
    connectionStatus,
    batteryLevel,
    key
  ) => {
    let icon;
    if (deviceModel === "HC200") {
      icon = <NewHC200Icon status={deviceStatus} className="icon" />;
    } else {
      if (!batteryLevel) {
        icon = <BatteryZeroIcon connectionStatus={connectionStatus} />;
      } else if (batteryLevel < 35) {
        icon = <BatteryLowIcon connectionStatus={connectionStatus} />;
      } else if (batteryLevel <= 49) {
        icon = <BatteryMediumIcon connectionStatus={connectionStatus} />;
      } else {
        icon = <BatteryHighIcon connectionStatus={connectionStatus} />;
      }
    }

    return (
      <DeviceInfoContainer
        deviceLocation={deviceLocation}
        keyProp={key}
        serialNum={serialNumber}
      >
        {icon}
      </DeviceInfoContainer>
    );
  };

  const renderMotionActivity = (deviceData) => {
    return (
      <div
        style={{ display: "flex", flexDirection: "row", marginLeft: "auto" }}
      >
        <div className="past-motion-container" style={{ marginTop: "30px" }}>
          {moment(dateToDisplay).format("ddd M/D")}
        </div>
        {timeArray.map((hour) =>
          renderHours(
            deviceData.model,
            hour,
            deviceData.hourlyData?.hourlyData,
            deviceData.hourlyData?.timeRanges,
            lastHourEntry,
            "current"
          )
        )}
      </div>
    );
  };

  const renderMotionActivityTrend = (deviceData, deviceUuid) => {
    return (
      <div className="motion-trend-container">
        <div className="motion-trend-hours">
          {timeArray.map((hour) =>
            renderHours(
              deviceData.model,
              hour,
              deviceData.hourlyData?.hourlyData,
              deviceData.hourlyData?.timeRanges,
              lastHourEntry,
              null,
              lightData[deviceUuid]
            )
          )}
        </div>
        <div className="motion-trend-graph">
          {Object.keys(lightData).length > 0 && (
            <MotionLineChart
              dataPerHourArray={[
                deviceData.hourlyData.hourlyData,
                lightData[deviceUuid],
              ]}
              colors={["steelblue", "black"]}
            />
          )}
        </div>
      </div>
    );
  };

  const renderPastMotionData = (dateKey, deviceKey) => {
    const deviceData = pastDataPerHour[dateKey][deviceKey];
    return (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="past-motion-container">
          {moment(dateKey).format("ddd M/D")}
        </div>
        {timeArray.map((hour) =>
          renderHours(
            deviceData.model,
            hour,
            deviceData.hourlyData?.hourlyData,
            deviceData.hourlyData?.timeRanges,
            lastHourEntry,
            "past"
          )
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!!Object.keys(allDevicesFetched).length) {
      fetchMotionData();
    }
  }, [allDevicesFetched]);

  const fetchUserAccessories = () => {
    const errorCallback = (error) => {
      console.error(error);
    };
    const successCallback = (data) => {
      console.log("UserAccessories data", data);
      const deviceObject = data["user_accessories"].reduce((obj, device) => {
        if (device.modelnumber === "HC200" || device.modelnumber === "EM200") {
          obj[device.deviceuuid] = {
            serialnumber: device?.device_serial_number,
            modelnumber: device.modelnumber,
            location: device.location,
            battery_level: device?.device_battery_level,
            connectionStatus:
              device.modelnumber === "HC200"
                ? device.deviceconnectstatus
                : device.paired_status === "UNPAIRED"
                ? false
                : device.device_connected
                ? "CONNECTED"
                : "DISCONNECTED",
          };
        }
        return obj;
      }, {});
      console.log("Devices fetched", deviceObject);
      setAllDevicesFetched(deviceObject);
    };

    try {
      userEventsApi.getUserAccessories(
        hubRecord.useruuid,
        {
          eventtypes: ["environment_status"],
        },
        apiCallback({
          translator: trans,
          failCallback: errorCallback,
          errorCallback,
          successCallback,
        })
      );
    } catch (error) {
      errorCallback(error);
    }
  };

  useEffect(() => {
    fetchUserAccessories();
  }, [activityDateStart, activityDateEnd]);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const toggleArrowVisibility = (date) => {
    const today = moment().startOf("day");

    const shouldHideRightArrow = moment(date).isSame(today, "day");
    const shouldHideLeftArrow = moment(date).isSame(
      today.clone().subtract(1, "month"),
      "day"
    );

    setHideRightArrow(shouldHideRightArrow);
    setHideLeftArrow(shouldHideLeftArrow);
  };

  const onActionClick =
    (date, activityDateStart, activityDateEnd, toggle = false) =>
    () => {
      setDataLoaded(false);
      console.log("selected date", date);
      if (!!date) {
        toggleArrowVisibility(date);
        setDateToDisplay(date.toDateString());
        setActivityDateStart(activityDateStart);
        setActivityDateEnd(activityDateEnd);
        setShowPastDataDevice([]);
        setPastDataLoaded(false);
      }
      toggle && toggleCalendar();
    };

  const handleArrowClick = (direction) => {
    setDataLoaded(false);
    const currentDate = moment(dateToDisplay);
    const nextDate =
      direction === "forward"
        ? currentDate.add(1, "day").toDate()
        : currentDate.subtract(1, "day").toDate();

    setDateToDisplay(nextDate.toDateString());
    setActivityDateStart(moment(nextDate).startOf("day").format());
    setActivityDateEnd(moment(nextDate).endOf("day").format());
    setShiftDate(direction);
    toggleArrowVisibility(nextDate);
    setShowPastDataDevice([]);
    setPastDataLoaded(false);
  };

  const handleClickOutsideCalendar = (e) => {
    if (calendarRef.current && !calendarRef.current.contains(e.target)) {
      setShowCalendar(false);
    }
  };

  const handleClickPastDataToggle = async (key) => {
    setShowPastDataDevice((prevState) => {
      const isKeyIncluded = prevState.includes(key);
      if (!isKeyIncluded) {
        renderSevenDaysData(key);
      }
      return isKeyIncluded
        ? prevState.filter((item) => item !== key)
        : [...prevState, key];
    });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideCalendar);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideCalendar);
    };
  }, []);

  return (
    <div style={{ fontFamily: "Euclid Circular" }}>
      {!isTrend && (
        <div className="motion-activity-header">
          <MotionActivityIcon2 />
          <div className="motion-activity-title">{trans("MOTIONACTIVITY")}</div>
        </div>
      )}
      <div className="motion-calendar-container">
        <div className="motion-calendar-date-display">{dateToDisplay}</div>
        <div className="calendar-nav-icons">
          <div className="arrow-icons">
            {!hideLeftArrow && (
              <ArrowLeftIcon
                className="calendar-nav-icon"
                onClick={() => handleArrowClick("back")}
              />
            )}
          </div>
          <button onClick={toggleCalendar} className="button-select-date">
            {showCalendar ? "Select Date" : "Select Date"}
          </button>
          <div className="arrow-icons">
            {!hideRightArrow && (
              <ArrowRightIcon
                className="calendar-nav-icon"
                onClick={() => handleArrowClick("forward")}
              />
            )}
          </div>
        </div>
        {showCalendar && (
          <div ref={calendarRef}>
            <MotionCalendar
              isTrend={isTrend}
              onActionClick={onActionClick}
              shiftDate={shiftDate}
              dateToDisplay={dateToDisplay}
            />
          </div>
        )}
      </div>

      <Spin spinning={!dataLoaded} style={{ marginTop: "100px" }}>
        {dataLoaded &&
          sortByModelAndLocation(dataPerHour).map(
            isTrend ? renderActivityItemTrend : renderActivityItem
          )}
      </Spin>
    </div>
  );
};

export default MotionActivity;
