import React from "react";

export const SaveChangesIcon = (props) => {
    const { className = "", style={}, disabled = false, onClick } = props;
    return (
      
        <svg className={className} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={style}>
        <g clip-path="url(#clip0_27181_9977)">
        <path d="M16.5739 4.98532L13.0041 1.41558C12.4745 0.885951 11.7648 0.589355 11.0127 0.589355H3.41775C1.86063 0.599948 0.600098 1.86048 0.600098 3.4176V14.5929C0.600098 16.15 1.86063 17.4105 3.41775 17.4105H14.593C16.1502 17.4105 17.4107 16.15 17.4107 14.5929V6.98734C17.4107 6.23526 17.1141 5.52555 16.5845 4.99591L16.5739 4.98532ZM10.8432 15.4933H7.16756V12.7498H10.8432V15.4933ZM15.4934 14.5823C15.4934 15.0801 15.0909 15.4933 14.5824 15.4933H12.7393V11.7964C12.7393 11.2668 12.3156 10.8431 11.786 10.8431H6.20363C5.67399 10.8431 5.25029 11.2668 5.25029 11.7964V15.4933H3.40716C2.9093 15.4933 2.49619 15.0907 2.49619 14.5823V3.4176C2.49619 2.91975 2.89871 2.50663 3.40716 2.50663H11.0127C11.2563 2.50663 11.4894 2.60197 11.6589 2.77145L15.2286 6.34118C15.3981 6.51067 15.4934 6.74371 15.4934 6.98734V14.5929V14.5823Z" fill={className}/>
        </g>
        <defs>
        <clipPath id="clip0_27181_9977">
        <rect width="16.8" height="16.8" fill="white" transform="translate(0.600098 0.600098)"/>
        </clipPath>
        </defs>
        </svg>      
    );
}