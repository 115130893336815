import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons"; // Ant Design icons for arrows
import WellnessListItem from "./WellnessListItem";
import { WellnessDatePicker } from "../Common/WellnessDatePicker";
import { DateFormat } from "@commscopemycloud/humaui/Utilities/Constants";
import {  message } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import "./Wellness.less";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const WellnessList = ({ showViewHistoryModal, mappedData, loading, timeZone, setDateHandler }) => {
    const [wellnessListData, setWellnessListData] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const userTimezone = timeZone ? timeZone?.split(" ")[0] : LocalTimezone;
    const [wellnessTotalCount, setWellnessTotalCount] = useState(0);


    useEffect(() => {
        if (mappedData?.medical_accessories && loading === false) {
            const WellnessListData = [];
            const listData = { ...mappedData };
            const mapDataArrayKey = Object.keys(mappedData.medical_accessories);

            mapDataArrayKey.forEach((item) => {
                const accessory = JSON.parse(JSON.stringify(listData.medical_accessories[item]));
                if (accessory) {
                    const listObj = {
                        title: item,
                        day: accessory?.day || "-",
                        timeDifference: accessory?.timeDiff || "-",
                        time: accessory?.time || "-",
                        data: accessory?.data || "-",
                        device: "Device used",
                        deviceBrand: accessory?.deviceManufacture || "-",
                        deviceModel: accessory?.deviceModel || "-",
                        deviceuuid: accessory?.device_uuid || "-",
                        eventName: accessory?.eventName || "-",
                    };
                    WellnessListData.push(listObj);
                }
            });

            setWellnessListData(WellnessListData);
            setWellnessTotalCount(WellnessListData.length)
        }

    }, [mappedData, loading]);

    const handleDateSelect = (date) => {
        if (!date || !date.isValid()) {
            console.error('Invalid date passed to handleDateSelect:', date);
            return;
        }
        const selectedDate = date.startOf('day').toDate(); // Convert dayjs to Date
        setSelectedDate(selectedDate);

        const startDateTime = date.startOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        const endDateTime = date.endOf('day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        setDateHandler(startDateTime, endDateTime); // Pass formatted strings
    };


    const handleNextDate = () => {
        const nextDate = dayjs(selectedDate).add(1, "day").startOf("day");
        const today = dayjs().startOf("day");
        if (nextDate.isAfter(today)) {
        console.log("Cannot select a date in the future!");
        message.error("Cannot select a date in the future!");
        return;
        }
        setSelectedDate(nextDate);
        handleDateSelect(nextDate);
        console.log("NextDate", nextDate.format("YYYY-MM-DD"));
    };

    const handlePreviousDate = () => {
        const prevDate = dayjs(selectedDate).subtract(1, "day").startOf("day");
        setSelectedDate(prevDate);
        handleDateSelect(prevDate);
        console.log("prevDate", prevDate.format("YYYY-MM-DD"));
    };



    const disabledFutureDate = (current) => {
        return current && current > moment().endOf("day");
    };

    return (
        <>
            <>
                <div className="wellness-count">
                    <div className="wellness-count-title">
                        <label>Total ({wellnessTotalCount})</label>
                    </div>
                    <div className="wellness-actions">
                        <LeftOutlined className="date-arrow" onClick={handlePreviousDate} />
                        <WellnessDatePicker
                            DateFormat={DateFormat}
                            timeZone={userTimezone}
                            defaultValue={selectedDate}
                            onDateSelect={handleDateSelect}
                            disabledDate={disabledFutureDate}
                            value={selectedDate ? dayjs(selectedDate).tz(userTimezone, true) : null}
                        />
                        <RightOutlined className="date-arrow" onClick={handleNextDate} />
                    </div>
                </div>
                {loading ? (
                    <Spin spinning={loading} style={{ display: "flex", justifyContent: "center", marginTop: "20rem" }} />
                ) : (
                    <div className="wellness-list-container">
                        {wellnessListData.length > 0 ? (
                            wellnessListData.map((item, index) => (
                                <WellnessListItem
                                    key={index}
                                    showViewHistoryModal={showViewHistoryModal}
                                    title={item.title}
                                    day={item.day}
                                    timeDifference={item.timeDifference}
                                    time={item.time}
                                    data={item.data}
                                    device={item.device}
                                    deviceBrand={item.deviceBrand}
                                    deviceModel={item.deviceModel}
                                    deviceuuid={item.deviceuuid}
                                    eventName={item.eventName}
                                    showViewHistory={true}
                                />
                            ))
                        ) : (
                            <div className="note-box no-events">
                                <span>{"No Wellness Device Found."}</span>
                            </div>
                        )}
                    </div>
                )}
            </>
        </>
    );
};

export default WellnessList;
