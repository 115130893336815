import React from "react";

export const ScheduleListNewIcon = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      className={className}
      width="28"
      height="31"
      viewBox="0 0 28 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M8.00979 11.4782C8.00979 10.9471 7.58806 10.5254 7.05699 10.5254H5.61998C5.08891 10.5254 4.66718 10.9471 4.66718 11.4782V12.9152C4.66718 13.4463 5.08891 13.868 5.61998 13.868H7.05699C7.58806 13.868 8.00979 13.4463 8.00979 12.9152V11.4782ZM7.11947 12.9308C7.11947 12.9308 7.08823 12.9933 7.05699 12.9933L5.5575 12.9308L5.61998 11.4313L7.11947 11.4938V12.9308Z"
        fill="#113E57"
      />
      <path
        d="M19.6623 11.5249H9.38455C9.08262 11.5249 8.83786 11.7697 8.83786 12.0716V12.3527C8.83786 12.6547 9.08262 12.8994 9.38455 12.8994H19.6623C19.9642 12.8994 20.209 12.6547 20.209 12.3527V12.0716C20.209 11.7697 19.9642 11.5249 19.6623 11.5249Z"
        fill="#113E57"
      />
      <path
        d="M8.00979 15.9465C8.00979 15.4154 7.58806 14.9937 7.05699 14.9937H5.61998C5.08891 14.9937 4.66718 15.4154 4.66718 15.9465V17.3835C4.66718 17.9145 5.08891 18.3363 5.61998 18.3363H7.05699C7.58806 18.3363 8.00979 17.9145 8.00979 17.3835V15.9465ZM7.11947 17.3835C7.11947 17.3835 7.08823 17.4459 7.05699 17.4459L5.5575 17.3835L5.61998 15.884L7.11947 15.9465V17.3835Z"
        fill="#113E57"
      />
      <path
        d="M14.4141 15.9771H9.38455C9.08262 15.9771 8.83786 16.2218 8.83786 16.5237V16.8049C8.83786 17.1068 9.08262 17.3516 9.38455 17.3516H14.4141C14.716 17.3516 14.9608 17.1068 14.9608 16.8049V16.5237C14.9608 16.2218 14.716 15.9771 14.4141 15.9771Z"
        fill="#113E57"
      />
      <path
        d="M7.05699 19.4443H5.61998C5.08891 19.4443 4.66718 19.8661 4.66718 20.3971V21.8342C4.66718 22.3652 5.08891 22.787 5.61998 22.787H7.05699C7.58806 22.787 8.00979 22.3652 8.00979 21.8342V20.3971C8.00979 19.8661 7.58806 19.4443 7.05699 19.4443ZM7.11947 21.8342C7.11947 21.8342 7.08823 21.8966 7.05699 21.8966L5.5575 21.8342L5.61998 20.3347L7.11947 20.3971V21.8342Z"
        fill="#113E57"
      />
      <path
        d="M12.1649 20.4287H9.38455C9.08262 20.4287 8.83786 20.6735 8.83786 20.9754V21.2566C8.83786 21.5585 9.08262 21.8032 9.38455 21.8032H12.1649C12.4668 21.8032 12.7115 21.5585 12.7115 21.2566V20.9754C12.7115 20.6735 12.4668 20.4287 12.1649 20.4287Z"
        fill="#113E57"
      />
      <path
        d="M21.2085 16.8511C17.5691 16.8511 14.617 19.8032 14.617 23.4426C14.617 27.082 17.5691 30.0341 21.2085 30.0341C24.8479 30.0341 27.8 27.082 27.8 23.4426C27.8 19.8032 24.8479 16.8511 21.2085 16.8511ZM24.8479 22.2555L20.9742 26.1292C20.7711 26.3322 20.49 26.4416 20.2245 26.4416C19.9589 26.4416 19.6778 26.3322 19.4747 26.1292L17.5535 24.208C17.1474 23.8018 17.1474 23.1302 17.5535 22.7085C17.9596 22.3024 18.6312 22.3024 19.053 22.7085L20.2245 23.8799L23.3484 20.756C23.7545 20.3499 24.4262 20.3499 24.8479 20.756C25.2696 21.1621 25.254 21.8338 24.8479 22.2555Z"
        fill="#113E57"
      />
      <path
        d="M13.3049 25.5205H3.08963V6.41759H4.52664V7.51097C4.52664 8.30757 5.16705 8.94798 5.96366 8.94798C6.76026 8.94798 7.40067 8.30757 7.40067 7.51097V6.41759H9.30627V7.51097C9.30627 8.30757 9.94668 8.94798 10.7433 8.94798C11.5399 8.94798 12.1803 8.30757 12.1803 7.51097V6.41759H14.0859V7.51097C14.0859 8.30757 14.7263 8.94798 15.5229 8.94798C16.3195 8.94798 16.9599 8.30757 16.9599 7.51097V6.41759H18.8655V7.51097C18.8655 8.30757 19.5059 8.94798 20.3026 8.94798C21.0992 8.94798 21.7396 8.30757 21.7396 7.51097V6.41759H23.1766V15.5395C23.6764 15.602 24.1606 15.7113 24.6448 15.8519C25.129 15.9925 25.582 16.1643 26.035 16.3673V6.41759C26.035 5.66784 25.7538 5.02743 25.1915 4.49636C24.6292 3.96529 23.9576 3.69976 23.161 3.69976H21.7239V2.40332C21.7239 1.60672 21.0835 0.966309 20.2869 0.966309C19.4903 0.966309 18.8499 1.60672 18.8499 2.40332V3.69976H16.9443V2.40332C16.9443 1.60672 16.3039 0.966309 15.5073 0.966309C14.7107 0.966309 14.0703 1.60672 14.0703 2.40332V3.69976H12.1647V2.40332C12.1647 1.60672 11.5243 0.966309 10.7277 0.966309C9.93106 0.966309 9.29066 1.60672 9.29066 2.40332V3.69976H7.38505V2.40332C7.38505 1.60672 6.74464 0.966309 5.94804 0.966309C5.15143 0.966309 4.51102 1.60672 4.51102 2.40332V3.69976H3.07401C2.29302 3.69976 1.60576 3.96529 1.04345 4.49636C0.481136 5.02743 0.199982 5.66784 0.199982 6.41759V25.5205C0.199982 26.2702 0.481136 26.9107 1.04345 27.4417C1.60576 27.9728 2.2774 28.2383 3.07401 28.2383H14.6795C14.3671 27.8322 14.1015 27.3949 13.8672 26.9419C13.6329 26.4889 13.4455 26.0047 13.2737 25.5049L13.3049 25.5205Z"
        fill="#113E57"
      />
    </svg>
  );
};
