/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWS, { ReadyState } from "react-use-websocket";
import SessionStorage from "@commscopemycloud/humaui/Utilities/SessionStorage";
import { AppStorageKeys } from "@commscopemycloud/humaui/Utilities/Constants";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import { getMeetingInfo } from "@commscopemycloud/humaui/Store/videoCallStore";
import { processMessage } from "./WebsocketHelper";
import { getProfilePics } from "@commscopemycloud/humaui/Store/dataStore";
import {
  sendMetricsInfo,
  sendCallInfo,
  sendAttendeeInfo,
} from "./TelemetryUtils";
import { checkForMaintenance } from "./WebsocketHelper";
import useUserInfo from "../Hooks/useUserInfo";

const ENV_VARS = SessionStorage.get(AppStorageKeys.envVars);
const WEBSOCKET_URL = ENV_VARS.WEBSOCKET_URL;
const WEBSOCKET_PORT = ENV_VARS.WEBSOCKET_PORT;

const useWebsocket = (hostname, token, setAnnouncement) => {
  const dispatch = useDispatch();
  const [connect, setConnect] = useState(true);
  const currentUser = useSelector(getCurrentUser);
  const resourceMap = useSelector(getProfilePics());
  const meetingInfo = useSelector(getMeetingInfo);
  const videoCallModalVisible = useSelector(
    (state) => state.videoCall.videoCallModalVisible
  );
  const videoCallNotificationModalVisible = useSelector(
    (state) => state.notification.videoCallNotificationModalVisible
  );

  const useruuid = useMemo(() => currentUser.useruuid, [currentUser]);
  const WS_URL = useMemo(
    () => (WEBSOCKET_URL || "wss://" + hostname) + ":" + WEBSOCKET_PORT,
    [hostname]
  );

  const [userInfo] = useUserInfo({ useruuid: currentUser.useruuid });

  const maintenanceApi = useSelector((state) => state.apis.maintenanceApi);
  const trans = useSelector((state) => state.translator);

  const onMessage = async (e) => {
    try {
      console.info("Websocket message received:", e);
      const message = JSON.parse(e.data) || {};
      if (message.type === "pong") {
        console.info("Websocket ping response received!", moment().toString());
      } else if (message.type === "check_for_maintenance") {
        checkForMaintenance(userInfo, setAnnouncement, maintenanceApi, trans);
      } else {
        processMessage({
          dispatch,
          message,
          sendMessage,
          meetingInfo,
          videoCallModalVisible,
          videoCallNotificationModalVisible,
          currentUser,
          resourceMap,
        });
      }
    } catch (error) {
      console.error("Error parsing websocket message:", error);
    }
  };

  useEffect(() => setConnect(false), [token]);

  useEffect(() => {
    if (connect === false) {
      setConnect(true);
    }
  }, [connect]);

  const { sendMessage: sendMessageToWS, readyState } = useWS(
    WS_URL,
    {
      protocols: token,
      shouldReconnect: (closeEvent) => {
        console.log("ws shouldReconnect, code:", closeEvent.code);
        return closeEvent.code !== 1000 && closeEvent.code !== 1001;
      },
      reconnectInterval: 5000 /* 5 sec */,
      reconnectAttempts: Infinity,
      heartbeat: {
        message: JSON.stringify({ type: "ping" }),
        returnMessage: JSON.stringify({ type: "pong" }),
        interval: 120 * 1000 /* 120 sec */,
        timeout: 250 * 1000 /* 250 sec */,
      },
      onOpen: (e) => {
        console.info("Websocket connection opened:", e);
      },
      onClose: (e) => {
        console.info("Websocket connection closed:", e);
      },
      onError: (e) => {
        console.error("Websocket connection error:", e);
      },
      onMessage: onMessage,
    },
    connect
  );

  const sendMessage = (message, type = "message", topic) => {
    console.debug("Websocket message tobe sent:", message);
    /* ToDo: message to be zipped and encrypted */
    const msgObject = { type, id: useruuid, message, topic };
    if (readyState !== ReadyState.OPEN) {
      console.error(
        "Websocket connection is not open, can't send message!",
        readyState
      );
      return;
    }
    sendMessageToWS(JSON.stringify(msgObject));
  };
  return {
    sendMessage,
    sendMetricsInfo: (audioVideo) => sendMetricsInfo(audioVideo, sendMessage),
    sendAttendeeInfo: (attendeeInfo) =>
      sendAttendeeInfo(sendMessage, attendeeInfo),
    sendCallInfo: (audioVideo) => sendCallInfo(audioVideo, sendMessage),
    readyState,
  };
};

export default useWebsocket;
