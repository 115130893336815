import React from "react";
import "./EnvironmentalCard.less";
import {
  BatteryHighIcon,
  BatteryMediumIcon,
  BatteryLowIcon,
  BatteryZeroIcon,
  TemperatureIcon,
  HumidityIcon,
  HighLightIcon,
  MediumLightIcon,
  LowLightIcon,
  ZeroLightIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  EnvironmentalTrendIcon,
} from "../Common/Icons";
import {
  _1,
  _2,
  _3,
  _4,
  _5,
  _6,
  _7,
  _8,
  _11,
  _12,
  _13,
  _14,
  _15,
  _16,
  _17,
  _18,
  _19,
  _20,
  _21,
  _22,
  _23,
  _24,
  _25,
  _26,
  _29,
  _30,
  _31,
  _32,
  _33,
  _34,
  _35,
  _36,
  _37,
  _38,
  _39,
  _40,
  _41,
  _42,
  _43,
  _44,
} from "../Common/Icons/WeatherIcons";
import environmentalRanges from "../../../environmentalRanges.json";
import { AccuweatherIcon } from "./AccuweatherIcon";
import * as Icons from "../../../weatherIcons.json";
import { Tooltip } from "antd";
import { postalCodeStatus, onTrendIconClick } from "./EnvironmentalUtils";
import { useSelector } from "react-redux";
import { translator } from "@commscopemycloud/humaui/Store/configStore";

const componentMap = {
  BatteryZeroIcon,
  BatteryLowIcon,
  BatteryMediumIcon,
  BatteryHighIcon,
  ZeroLightIcon,
  LowLightIcon,
  MediumLightIcon,
  HighLightIcon,
};

const weatherIconComponentMap = {
  _1,
  _2,
  _3,
  _4,
  _5,
  _6,
  _7,
  _8,
  _11,
  _12,
  _13,
  _14,
  _15,
  _16,
  _17,
  _18,
  _19,
  _20,
  _21,
  _22,
  _23,
  _24,
  _25,
  _26,
  _29,
  _30,
  _31,
  _32,
  _33,
  _34,
  _35,
  _36,
  _37,
  _38,
  _39,
  _40,
  _41,
  _42,
  _43,
  _44,
};

const EnvironmentalCard = ({
  deviceUuid = null,
  location,
  deviceId,
  temperatureVal,
  humidityVal,
  lightVal,
  batteryStatus,
  connectionStatus,
  trend,
  time,
  timezone,
  grey,
  outdoorWeatherIcon,
  postalCodeValid = null,
  setPastDataProcessed,
  useruuid,
  timezoneOffset,
  setDeviceUuidToShowTrend,
  loginUserTimeformat,
  allowShowTrend,
  setIsTrendGraphLoading,
  setShowPastTrend,
  selectedRange,
}) => {
  const userEventsApi = useSelector((state) => state.apis.userEventsApi);
  const weatherApi = useSelector((state) => state.apis.weatherApi);
  const trans = useSelector(translator);

  const greyStyle = grey ? { color: "#808080" } : {};
  const WeatherIconToRender =
    outdoorWeatherIcon && weatherIconComponentMap[outdoorWeatherIcon];

  const renderWeatherIcon = (weatherIcon) => {
    if (!weatherIcon) return;
    const weatherIconKey = weatherIcon.toString();
    const WeatherIcon =
      weatherIconComponentMap[Icons[weatherIconKey]["WeatherIconID"]];

    return <WeatherIcon />;
  };

  const trendIcon = () => {
    if (trend === undefined) return null;
    return trend === "up" ? <ArrowUpIcon /> : <ArrowDownIcon />;
  };

  const getIconComponent = (levels, value) => {
    if (value === null || value === undefined) {
      return componentMap["BatteryZeroIcon"];
    }
    for (const level of levels) {
      if (
        (level.max !== undefined && value <= level.max) ||
        (level.min !== undefined &&
          value >= level.min &&
          level.max === undefined)
      ) {
        return componentMap[level.component];
      }
    }
    return null;
  };

  const battery = (batteryLevel) => {
    const IconComponent = getIconComponent(
      environmentalRanges.environmentalRanges.batteryLevels,
      batteryLevel
    );
    return IconComponent ? (
      <IconComponent
        connectionStatus={connectionStatus}
        style={greyStyle}
        env={
          batteryLevel !== null &&
          batteryLevel !== undefined &&
          batteryLevel <= 49
        }
      />
    ) : null;
  };

  const truncatedDeviceLocation = (location) => {
    if (!location) return;
    return location?.length <= 15
      ? location
      : location?.substring(0, 15) + "...";
  };

  const light = (val) => {
    if (val === null) return;
    const IconComponent = getIconComponent(
      environmentalRanges.environmentalRanges.lightLevels,
      val
    );
    return IconComponent ? (
      <IconComponent fill={grey ? "grey" : undefined} />
    ) : null;
  };

  const getLightLabel = (lightVal) => {
    const { lightLevels } = environmentalRanges.environmentalRanges;
    for (const level of lightLevels) {
      if (
        (level.max !== undefined && lightVal <= level.max) ||
        (level.min !== undefined &&
          lightVal >= level.min &&
          level.max === undefined)
      ) {
        return level.label;
      }
    }
    return null;
  };

  const is24hoursformat = loginUserTimeformat?.is24hoursformat;
  return location === "Outdoor" ? (
    <div className="card-container" style={{ background: "#113e57" }}>
      <div className="heading-container">
        <div className="location" style={{ color: "white" }}>
          {location}
        </div>
      </div>
      <Tooltip
        placement="bottom"
        title={
          postalCodeValid === postalCodeStatus.valid
            ? grey
              ? "Weather data not available"
              : ""
            : postalCodeValid === postalCodeStatus.empty
            ? "Postal code is empty"
            : "Postal code is not valid"
        }
      >
        <div className="content-container">
          <div className="battery-container">
            <div className="content-value">
              <div className="accuweather-container">
                <AccuweatherIcon />
              </div>
            </div>
          </div>
          <div className="temperature-container">
            <div className="content-icon">
              <TemperatureIcon
                style={{
                  width: "15.26px",
                  height: "32px",
                }}
                fill={grey ? "#808080" : "white"}
              />
            </div>
            <div
              className="content-value"
              style={grey ? greyStyle : { color: "white" }}
            >
              {temperatureVal !== null
                ? `${Math.floor(temperatureVal)}°`
                : `--°`}
            </div>
            <div className="trendIcon"></div>
          </div>
          <div className="humidity-container">
            <div className="content-icon">
              <HumidityIcon
                style={{
                  width: "27.53px",
                  height: "32px",
                }}
                fill={grey ? "#808080" : "white"}
              />
            </div>
            <div
              className="content-value"
              style={grey ? greyStyle : { color: "white" }}
            >
              {humidityVal !== null ? humidityVal : "--"}
              <span className="superscript">%</span>
            </div>
          </div>
          <div className="light-container">
            <div className="content-icon">
              <div className="nightcloudicon-container">
                {renderWeatherIcon(outdoorWeatherIcon)}
              </div>
            </div>
            <div className="content-value" style={greyStyle}>
              <span className="superscript"></span>
            </div>
            <div className="content-category"></div>
          </div>
        </div>
      </Tooltip>
      <div
        className="time-container"
        style={grey ? greyStyle : { color: "white" }}
      >
        {postalCodeValid === postalCodeStatus.valid &&
          new Date(time).toLocaleString("en-US", {
            timeZone: timezone,
            hour: "numeric",
            minute: "numeric",
            hour12: !is24hoursformat,
          })}
      </div>
    </div>
  ) : (
    <div className="card-container">
      <div className="heading-container">
        <Tooltip
          placement="bottom"
          title={location?.length > 15 ? location : ""}
        >
          <div className="location" style={greyStyle}>
            {truncatedDeviceLocation(location)}
          </div>
        </Tooltip>
        {deviceId && (
          <div className="deviceId" style={greyStyle}>
            {deviceId}
          </div>
        )}
      </div>
      <Tooltip
        placement="bottom"
        title={!time ? "Weather Data Not Available" : ""}
      >
        <div className="content-container">
          <div className="battery-container">
            <div className="content-value">{battery(batteryStatus)}</div>
          </div>
          <div className="temperature-container">
            <div className="content-icon">
              <TemperatureIcon
                style={{
                  width: "15.26px",
                  height: "32px",
                  fill: grey ? "grey" : undefined,
                }}
                fill={grey ? "grey" : undefined}
              />
            </div>
            <div className="content-value" style={greyStyle}>
              {temperatureVal !== null
                ? `${Math.floor(temperatureVal)}°`
                : `--°`}
            </div>
            <div className="trendIcon">{!grey && trendIcon()}</div>
          </div>
          <div className="humidity-container">
            <div className="content-icon">
              <HumidityIcon
                style={{
                  width: "27.53px",
                  height: "32px",
                  fill: grey ? "grey" : undefined,
                }}
                fill={grey ? "grey" : undefined}
              />
            </div>
            <div className="content-value" style={greyStyle}>
              {humidityVal !== null ? humidityVal : "--"}
              <span className="superscript">%</span>
            </div>
          </div>
          <div className="light-container">
            <div className="content-icon">{light(lightVal)}</div>
            <div className="content-value" style={greyStyle}>
              {lightVal}
              <span className="superscript">{lightVal !== null && "lx"}</span>
            </div>
            <div className="content-category">
              {!grey && getLightLabel(lightVal)}
            </div>
          </div>
        </div>
      </Tooltip>
      <div
        className="past-trends-container"
        onClick={() =>
          onTrendIconClick(
            useruuid,
            deviceUuid,
            userEventsApi,
            weatherApi,
            trans,
            setPastDataProcessed,
            timezoneOffset,
            setDeviceUuidToShowTrend,
            setIsTrendGraphLoading,
            setShowPastTrend,
            selectedRange
          )
        }
      >
        { allowShowTrend ? <EnvironmentalTrendIcon style={{ cursor: "pointer" }} /> : ""}
      </div>
      <div className="time-container" style={greyStyle}>
        {time
          ? grey
            ? new Date(time).toLocaleString("en-US", {
                timeZone: timezone,
                month: "short",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: !is24hoursformat,
              })
            : new Date(time).toLocaleTimeString("en-US", {
                timeZone: timezone,
                hour: "numeric",
                minute: "numeric",
                hour12: !is24hoursformat,
              })
          : null}
      </div>
    </div>
  );
};

export default EnvironmentalCard;
