import "../../styles/HSDayPicker.less";
import React, { useEffect, useState } from "react";
const shortWeekDays = ["sun", "mon", "tues", "wed", "thurs", "fri", "sat"];
const weekDays = ["sunday","monday","tuesday","wednesday","thursday","friday","saturday"];

export const HSDayPicker = (props) => {
  const { days, onDayChange, disabled = false } = props;

  // Initialize selected days
  const defaultSelectedDays = Array.isArray(days)
    ? days
        .map((item) => shortWeekDays[weekDays.indexOf(item)])
        .filter((item) => item)
    : [];

  const [selectedDays, setSelectedDays] = useState(defaultSelectedDays);

  const handleClick = (index) => {
    if (disabled) return; // Prevent selection when disabled is true
    const day = shortWeekDays[index]; // Get the full day abbreviation from the index
    setSelectedDays((prev) => {
      if (prev.includes(day)) {
        return prev.filter((item) => item !== day);
      } else {
        return [...prev, day];
      }
    });
  };

  useEffect(() => {
    // Convert selected days back to full week day names
    const values = selectedDays
      .map((item) => weekDays[shortWeekDays.indexOf(item)])
      .filter((item) => item);
    onDayChange(values);
  }, [selectedDays, onDayChange]);

  return (
    <div className={` hs-daypicker ${disabled ? "list-item-disabled" : ""}`}>
      {shortWeekDays.map((value, index) => (
        <div
          onClick={() => handleClick(index)}
          key={index}
          className={`${
            selectedDays.includes(value) ? "list-item-selected" : "list-item"
          }`}
          style={
            disabled ? { cursor: "not-allowed", pointerEvents: "none" } : {}
          }
        >
          {value.charAt(0)}
        </div>
      ))}
    </div>
  );
};
