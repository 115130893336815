import React from "react";

export const TaskIcon = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      className={className}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M12.0661 8.88623C10.078 8.88623 8.46533 10.4989 8.46533 12.487C8.46533 14.4752 10.078 16.0879 12.0661 16.0879C14.0543 16.0879 15.667 14.4752 15.667 12.487C15.667 10.4989 14.0543 8.88623 12.0661 8.88623ZM13.6703 13.1867H12.7573V14.0997C12.7573 14.4837 12.4501 14.7909 12.0661 14.7909C11.6822 14.7909 11.375 14.4837 11.375 14.0997V13.1867H10.462C10.078 13.1867 9.77084 12.8795 9.77084 12.4956C9.77084 12.1116 10.078 11.8044 10.462 11.8044H11.375V10.8914C11.375 10.5074 11.6822 10.2003 12.0661 10.2003C12.4501 10.2003 12.7573 10.5074 12.7573 10.8914V11.8044H13.6703C14.0543 11.8044 14.3614 12.1116 14.3614 12.4956C14.3614 12.8795 14.0543 13.1867 13.6703 13.1867Z"
        fill="#0A0A0A"
      />
      <path
        d="M4.59993 5.95946C4.59993 5.66935 4.36955 5.43896 4.07943 5.43896H3.29442C3.00431 5.43896 2.77393 5.66935 2.77393 5.95946V6.74447C2.77393 7.03458 3.00431 7.26497 3.29442 7.26497H4.07943C4.36955 7.26497 4.59993 7.03458 4.59993 6.74447V5.95946ZM4.11356 6.753C4.11356 6.753 4.0965 6.78714 4.07943 6.78714L3.26029 6.753L3.29442 5.93386L4.11356 5.96799V6.753Z"
        fill="#0A0A0A"
      />
      <path
        d="M10.9654 5.98486H5.35089C5.18595 5.98486 5.05225 6.11857 5.05225 6.28351V6.4371C5.05225 6.60203 5.18595 6.73574 5.35089 6.73574H10.9654C11.1304 6.73574 11.2641 6.60203 11.2641 6.4371V6.28351C11.2641 6.11857 11.1304 5.98486 10.9654 5.98486Z"
        fill="#0A0A0A"
      />
      <path
        d="M4.59993 8.39989C4.59993 8.10978 4.36955 7.87939 4.07943 7.87939H3.29442C3.00431 7.87939 2.77393 8.10978 2.77393 8.39989V9.1849C2.77393 9.47501 3.00431 9.7054 3.29442 9.7054H4.07943C4.36955 9.7054 4.59993 9.47501 4.59993 9.1849V8.39989ZM4.11356 9.1849C4.11356 9.1849 4.0965 9.21903 4.07943 9.21903L3.26029 9.1849L3.29442 8.36576L4.11356 8.39989V9.1849Z"
        fill="#0A0A0A"
      />
      <path
        d="M8.09843 8.41748H5.35089C5.18595 8.41748 5.05225 8.55119 5.05225 8.71613V8.86972C5.05225 9.03465 5.18595 9.16836 5.35089 9.16836H8.09843C8.26337 9.16836 8.39707 9.03465 8.39707 8.86972V8.71613C8.39707 8.55119 8.26337 8.41748 8.09843 8.41748Z"
        fill="#0A0A0A"
      />
      <path
        d="M4.07943 10.311H3.29442C3.00431 10.311 2.77393 10.5414 2.77393 10.8315V11.6165C2.77393 11.9067 3.00431 12.137 3.29442 12.137H4.07943C4.36955 12.137 4.59993 11.9067 4.59993 11.6165V10.8315C4.59993 10.5414 4.36955 10.311 4.07943 10.311ZM4.11356 11.6165C4.11356 11.6165 4.0965 11.6507 4.07943 11.6507L3.26029 11.6165L3.29442 10.7974L4.11356 10.8315V11.6165Z"
        fill="#0A0A0A"
      />
      <path
        d="M6.86972 10.8481H5.35089C5.18595 10.8481 5.05225 10.9819 5.05225 11.1468V11.3004C5.05225 11.4653 5.18595 11.599 5.35089 11.599H6.86972C7.03465 11.599 7.16836 11.4653 7.16836 11.3004V11.1468C7.16836 10.9819 7.03465 10.8481 6.86972 10.8481Z"
        fill="#0A0A0A"
      />
      <path
        d="M7.49245 13.6302H1.91205V3.19472H2.69706V3.79201C2.69706 4.22718 3.0469 4.57702 3.48207 4.57702C3.91724 4.57702 4.26708 4.22718 4.26708 3.79201V3.19472H5.30807V3.79201C5.30807 4.22718 5.65792 4.57702 6.09309 4.57702C6.52825 4.57702 6.8781 4.22718 6.8781 3.79201V3.19472H7.91909V3.79201C7.91909 4.22718 8.26893 4.57702 8.7041 4.57702C9.13927 4.57702 9.48911 4.22718 9.48911 3.79201V3.19472H10.5301V3.79201C10.5301 4.22718 10.8799 4.57702 11.3151 4.57702C11.7503 4.57702 12.1001 4.22718 12.1001 3.79201V3.19472H12.8851V8.17783C13.1582 8.21196 13.4227 8.27169 13.6872 8.34848C13.9517 8.42528 14.1992 8.51914 14.4466 8.63006V3.19472C14.4466 2.78515 14.293 2.43531 13.9859 2.14519C13.6787 1.85508 13.3118 1.71002 12.8766 1.71002H12.0916V1.00181C12.0916 0.566639 11.7417 0.216797 11.3066 0.216797C10.8714 0.216797 10.5216 0.566639 10.5216 1.00181V1.71002H9.48058V1.00181C9.48058 0.566639 9.13074 0.216797 8.69557 0.216797C8.2604 0.216797 7.91056 0.566639 7.91056 1.00181V1.71002H6.86956V1.00181C6.86956 0.566639 6.51972 0.216797 6.08455 0.216797C5.64938 0.216797 5.29954 0.566639 5.29954 1.00181V1.71002H4.25855V1.00181C4.25855 0.566639 3.90871 0.216797 3.47354 0.216797C3.03837 0.216797 2.68853 0.566639 2.68853 1.00181V1.71002H1.90352C1.47688 1.71002 1.10144 1.85508 0.794263 2.14519C0.487085 2.43531 0.333496 2.78515 0.333496 3.19472V13.6302C0.333496 14.0398 0.487085 14.3897 0.794263 14.6798C1.10144 14.9699 1.46835 15.1149 1.90352 15.1149H8.24333C8.07268 14.8931 7.92762 14.6542 7.79963 14.4067C7.67164 14.1593 7.56925 13.8948 7.47539 13.6217L7.49245 13.6302Z"
        fill="#0A0A0A"
      />
    </svg>
  );
};