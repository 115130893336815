import { AppStorageKeys } from "./Constants";

class LocalStorage {
  static get = (key) => {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (error) {
      switch (key) {
        case AppStorageKeys.language:
          return "";
        case AppStorageKeys.translation:
          return "";
        default:
          return {};
      }
    }
  };

  static set = (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error("Error setting local storage:", key);
    }
  };

  static remove = (key) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error("Error removing local storage:", key);
    }
  };

  static clear = () => {
    localStorage.clear();
  };
}

export default LocalStorage;
