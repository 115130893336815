/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import dayjs from "dayjs";
import MainPage from "../../MainPage";
import { Divider, Form, Input, TimePicker, message, Select, Spin, Modal, Button, } from "antd";
import {
  Actions,
  DateFormat,
} from "@commscopemycloud/humaui/Utilities/Constants";
import { HSDatePicker, HSDayPicker } from "../Common/HSWidget";
import { apiCallback } from "@commscopemycloud/humaui/Services/Common";
import { objectSort } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import { translator } from "@commscopemycloud/humaui/Store/configStore";
import { ButtonTypes } from "../Common/CustomButton";
import {
  CloseIcon,
  AddTaskIcon,
  NewCloseIcon,
  NewUpdateIcon,
  SaveChangesIcon,
  DeleteIcon,
} from "../Common/Icons";
import ContactSelectList from "../Common/ContactSelectList";
import CustomDateRangePicker from "../Common/CustomDateRangePicker";
import NewCustomButton from "../Common/NewCustomButton";

export const TaskForm = (props) => {
  console.debug("TaskForm props", props);
  const {
    onlyContent,
    action,
    hubRecord,
    userInfo,
    taskRecord,
    onActionClick,
    openSubMenu = false,
    showHeader = false,
    loginUserTimeformat,
    eventRecord,
  } = props;
  const [form] = Form.useForm();
  const trans = useSelector(translator);
  const taskApi = useSelector((state) => state.apis.taskApi);

  const TaskCategories = {
    medication: "Medication",
    exercise: "Exercise",
    diet: "Diet",
  };

  const TaskRepeats = {
    none: "Doesn't repeat",
    daily: "Daily",
    weekly: "Weekly (day)",
    custom: "Customize",
  };

  const SortedRepeats = Object.keys(TaskRepeats)
    .map((key) => ({ value: key, label: TaskRepeats[key] }));

  const SortedCategories = Object.keys(TaskCategories)
    .map((key) => ({ value: key, label: TaskCategories[key] }));

  const ReminderDuration = [5, 10, 15, 30, 60];

  const labelMapping = {
    5: "5 minutes",
    10: "10 minutes",
    15: "15 minutes",
    30: "30 minutes",
    60: "1 hour",
  };

  const FormattedReminderDuration = ReminderDuration.map((d) => ({
    value: d,
    label: labelMapping[d] || `${d} minutes`, // Use default if the value isn't in the mapping
  }));

  const formatDateTime = (dateMomentObj, hours = 0, minutes = 0) => {
    return moment
      .utc()
      .year(dateMomentObj.year())
      .month(dateMomentObj.month())
      .date(dateMomentObj.date())
      .hours(hours)
      .minutes(minutes)
      .seconds(0)
      .milliseconds(0)
      .format();
  };

  const FormItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const LocalTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const isEditForm = useMemo(() => action === Actions.editTask, [action]);

  const [processing, setProcessing] = useState(false);
  const [isReoccuring, setIsRecouring] = useState(
    taskRecord?.recurring || false
  );
  const [formData, setFormData] = useState({
    reminder: isEditForm
      && eventRecord?.reminder
        ? eventRecord?.reminder / 60
      : 30,
    category: isEditForm ? eventRecord?.category : TaskCategories.medication,
    repeat: isEditForm ? eventRecord?.repeat : TaskRepeats.none,
    event_name: isEditForm ? eventRecord?.event_name : "",
    event_description: isEditForm ? eventRecord?.event_description : "",
    starttime: isEditForm
      ? dayjs(eventRecord?.event_starttime, "HH:mm:ss")
      : null,
    endtime: isEditForm ? dayjs(eventRecord?.event_endtime, "HH:mm:ss") : null,
  });
  const [checkedDays, setCheckedDays] = useState(
    isEditForm ? eventRecord?.days_of_week : []
  );
  const [activeTab, setActiveTab] = useState("schedule");
  const [selectedDates, setSelectedDates] = useState(
    isEditForm
      ? [
          dayjs(eventRecord.event_startdate), // Convert start date to Day.js object
          dayjs(eventRecord.event_enddate), // Convert end date to Day.js object
        ]
      : []
  );
  // Track initial form data (to compare changes)
  const [initialFormData] = useState(formData);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    deleteTasks();
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const deleteTasks = () => {
    setProcessing(false);
    const errorCallback = (error) => {
      setProcessing(false);
      message.error("Error deleting Event!");
      console.error("Error deleting Event:", error);
    };
    const successCallback = (data) => {
      setProcessing(false);
      message.success("Event deleted successfully!");
      console.info("Event deleted successfully:", data);
      onActionClick(null, null, activeTab, true)();
    };
    try {
      setProcessing(true);
      eventRecord?.recurring
        ? taskApi.cancelAllRecurringTasks(
            eventRecord?.useruuid,
            eventRecord?.schedule_eventuuid,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          )
        : taskApi.cancelTask(
            eventRecord?.useruuid,
            eventRecord?.schedule_eventuuid,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          );
    } catch (error) {
      errorCallback(error);
    }
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
    onActionClick(null, null, key)();
  };

  /* Function to get UTC offset abbreviation for a time zone */
  const get_utc_offset = (timeZone) => {
    return Intl.DateTimeFormat(navigator.language, {
      timeZoneName: "longOffset",
      timeZone,
    })
      .formatToParts()
      .find((i) => i.type === "timeZoneName").value;
  };
  const timezonedisplay = LocalTimezone + ` (${get_utc_offset(LocalTimezone)})`;

  const handleInputChange = (e) => {
    const { name, value } = e?.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (dates) => {
    setSelectedDates(dates); // Update the state with selected dates
  };

  useEffect(() => {
    const { event_name, starttime, endtime, repeat } = formData;
    // Ensure required fields are filled
    const areRequiredFieldsFilled = !!(
      (event_name && starttime && endtime && selectedDates?.length === 2) // Ensure both start and end dates are selected
    );
    // Additional validation for repeat = custom
    const isRepeatValid = repeat !== "custom" || checkedDays?.length > 0;

    // Update the button's disabled state
    setIsCreateButtonDisabled(!(areRequiredFieldsFilled && isRepeatValid));
  }, [formData, checkedDays, selectedDates]);

  useEffect(() => {
    // Check if any individual field has changed
    const isFormChanged = Object.keys(formData).some((key) => {
      if (key === "starttime" || key === "endtime") {
        // Special handling for starttime and endtime (Date objects)
        return !dayjs(formData[key]).isSame(dayjs(initialFormData[key]), 'minute');
      } else {
        // For other fields, compare directly
        return formData[key] !== initialFormData[key];
      }
    });

    // Update button state based on changes
    setIsSaveButtonEnabled(isFormChanged);
  }, [formData, initialFormData]);
  

  const handleValueChange = (name, value) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleOnDayChange = (days) => {
    const lowercaseCheckedValues = days.map((value) => value.toLowerCase());
    setCheckedDays(lowercaseCheckedValues);
  };

  const handleSendClick = () => {
    form
      .validateFields()
      .then(() => onFormSubmit())
      .catch((error) => message.error("Please fill in all required fields"));
  };

  const onFormSubmit = () => {
    const errorCallback = (error) => {
      setProcessing(false);
      const msg = `Error ${isEditForm ? "Updating" : "Creating"} Task!`;
      message.error(msg);
      console.error(msg, error);
    };
    const successCallback = (data) => {
      setProcessing(false);
      const msg = `Task ${isEditForm ? "Updated" : "Created"} Successfully!`;
      message.success(msg);
      console.info(msg, data);
      onActionClick(null, null, true)();
    };
    try {
      setProcessing(true);
      const userUuid = hubRecord.useruuid;
      // Convert selectedDates to YYYY-MM-DD format
      const formattedDates = selectedDates.map((date) =>
        moment(date.$d).format("YYYY-MM-DD")
      );

      // Convert start and end times to 24-hour format
      const startTime24 = dayjs(formData.starttime.$d).format("HH:mm");
      const endTime24 = dayjs(formData.endtime.$d).format("HH:mm");

      const opts = {
        createTask: {
          contacts: [{ useruuid: userUuid }],
          event_name: formData.event_name,
          event_description: formData.event_description,
          category: formData.category,
          ...(formData.repeat !== TaskRepeats.none && {
            repeat: formData.repeat,
            days_of_week: checkedDays, // Only include if it's not a one-time task
            event_enddate: formattedDates[1], // Include enddate only if repeat is not one-time
          }),
          timezone: timezonedisplay,
          reminder:
            formData.reminder > 0 ? formData.reminder * 60 : formData.reminder, // Convert minutes to seconds
          snoozable: formData.reminder > 0 ? true : false,
          event_startdate: formattedDates[0],
          event_starttime: startTime24,
          event_endtime: endTime24,
        },
      };

      isEditForm
        ? taskApi.updateTask(
            userUuid,
            eventRecord?.schedule_eventuuid,
            opts,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          )
        : taskApi.createTask(
            userUuid,
            opts,
            apiCallback({
              failCallback: errorCallback,
              errorCallback,
              successCallback,
            })
          );
    } catch (error) {
      errorCallback(error);
    }
  };

  const render = (
    <div className="taskform-container">
      <Spin size="large" spinning={processing} tip={trans("PROCESSING")}>
        <Modal
          title="Delete Task"
          centered
          open={isModalOpen}
          footer={null}
          closable={false}
        >
          <div className="taskmodal">
            <div className="taskmodaldescription">
              <p>
                You are about to delete this task.
                <br />
                It will be removed from the schedule.
              </p>
              <span>Are you sure?</span>
            </div>
            <div className="taskmodalbutton">
              <Button className="custom-no-button" onClick={handleCancel}>
                <span className="custom-no-label">Cancel</span>
              </Button>
              <Button className="custom-yes-button" onClick={handleOk}>
                <span className="custom-yes-label">Yes</span>
              </Button>
            </div>
          </div>
        </Modal>
        <Form
          form={form}
          initialValues={formData}
          {...FormItemLayout}
          className="taskform-container"
        >
          <div className="taskform-card">
            <div className="task-title">
              <div className="form-title">
                <AddTaskIcon />
                <span>{isEditForm ? "Edit task" : "Create task"}</span>
              </div>
              <div>
                <CloseIcon
                  style={{ height: "20px", width: "20px", cursor: "pointer" }}
                  onClick={onActionClick(null, null)}
                />
              </div>
            </div>
            <div className="form-items-container">
              <div className="column-1">
                <span className="general-info-text">Task Informations</span>
                <div className="form-group">
                  <Form.Item
                    name="category"
                    label="Category*"
                    className="form-group-select"
                  >
                    <Select
                      onChange={(e) => handleValueChange("category", e)}
                      defaultValue={formData.category}
                      value={formData.category}
                      name="category"
                      className="select-type"
                      options={SortedCategories}
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item
                    label="Name*"
                    name="event_name"
                    rules={[
                      {
                        required: true,
                        message: trans("PLEASEENTERTHETASKNAME"),
                      },
                    ]}
                  >
                    <Input
                      placeholder="Task name"
                      name="event_name"
                      onChange={handleInputChange}
                      defaultValue={formData.event_name}
                      // maxLength={45}
                    />
                  </Form.Item>
                </div>
                <div className="form-row">
                  <Form.Item
                    name="repeat"
                    className="form-group-select"
                    label={"Repeats*"}
                  >
                    <Select
                      onChange={(e) => handleValueChange("repeat", e)}
                      defaultValue={formData.repeat}
                      value={formData.repeat}
                      name="repeat"
                      className="select-type"
                      options={SortedRepeats}
                      disabled={isEditForm}
                    />
                  </Form.Item>
                </div>
                {formData.repeat === "custom" && (
                  <div className="form-row flex-space-between">
                    <div
                      className="form-group flex-row"
                      style={{
                        gap: "16px",
                        alignItems: "baseline",
                        marginBottom: "-30px",
                      }}
                    >
                      <Form.Item name="days_of_week">
                        <HSDayPicker
                          onDayChange={handleOnDayChange}
                          name="days_of_week"
                          days={checkedDays}
                          disabled={isEditForm}
                        />
                      </Form.Item>
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <Form.Item label={"Date*"} name="date">
                    <CustomDateRangePicker
                      onDateChange={handleDateChange}
                      selectedDates={selectedDates}
                      disabled={isEditForm}
                      // repeatValue={formData.repeat === TaskRepeats.none ? true : false}
                      // repeatKey={formData.repeat}
                    />
                  </Form.Item>
                </div>
                <div className="form-group" style={{display: "flex", marginTop: "0px", gap: "30px"}}>
                  <Form.Item
                    label={"Start time*"}
                    name="starttime"
                    rules={[
                      {
                        type: "object",
                        message: trans("PLEASESELECTTIME"),
                      },
                    ]}
                  >
                    <TimePicker
                      name="starttime"
                      allowClear={false}
                      required={true}
                      format="h:mm a"
                      size="large"
                      onChange={(value) =>
                        handleValueChange("starttime", value)
                      }
                      minuteStep={15}
                      suffixIcon={
                        <span style={{ color: "black" }}>
                          {timezonedisplay}
                        </span>
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    label={"End time*"}
                    name="endtime"
                    rules={[
                      {
                        type: "object",
                        message: trans("PLEASESELECTTIME"),
                      },
                    ]}
                  >
                    <TimePicker
                      name="endtime"
                      allowClear={false}
                      required={true}
                      format="h:mm a"
                      size="large"
                      onChange={(value) => handleValueChange("endtime", value)}
                      minuteStep={15}
                      suffixIcon={
                        <span style={{ color: "black" }}>
                          {timezonedisplay}
                        </span>
                      }
                    />
                  </Form.Item>
                </div>
                <div className="form-row ">
                  <Form.Item
                    name="reminder"
                    className="form-group-select"
                    label={"Time between reminders*"}
                  >
                    <Select
                      onChange={(e) => handleValueChange("reminder", e)}
                      value={formData.reminder}
                      name="reminder"
                      className="select-type"
                      options={FormattedReminderDuration}
                    />
                  </Form.Item>
                  <div className="reminder-text">Applies if the user chooses to snooze an alert.</div>
                </div>
                <div className="form-group">
                  <Form.Item name="event_description" label={"Description"}>
                    <Input.TextArea
                      placeholder={trans("ADDDESCRIPTION")}
                      name="event_description"
                      onChange={handleInputChange}
                      defaultValue={formData.event_description}
                      className="form-description"
                      // maxLength={100}
                    />
                  </Form.Item>
                </div>
                <div className="manadatory-text">
                  *These fields are mandatory to create an account
                </div>
              </div>
              <div className="column-2">
                <ContactSelectList
                  displayOnlySelected={true}
                  fetchingContacts={[]}
                  selectedContactList={[hubRecord]}
                  selectedListHeader={`${trans("PARTICIPANTS")}`}
                  selectionListHeader={trans("ADDCONTACTS")}
                  selectionContactList={[]}
                  showRemoveIcon={false}
                  showRelationship={false}
                  hubRecord={hubRecord}
                  starteventAction={false}
                />
              </div>
            </div>
            <div className="taskform-action-button-container">
              {isEditForm ? (
                <>
                  <Form.Item>
                    <NewCustomButton
                      label={"Delete Task"}
                      icon={<DeleteIcon className="delete-icon" />}
                      onClick={showModal}
                    />
                  </Form.Item>
                  <Form.Item>
                    <NewCustomButton
                      disabled={!isSaveButtonEnabled}
                      type={ButtonTypes.primary}
                      label={"Save changes"}
                      icon={<SaveChangesIcon   className={
                            !isSaveButtonEnabled
                              ? "save-icon-disabled"
                              : "save-icon-enabled"
                      }/>}
                      onClick={handleSendClick}
                    />
                  </Form.Item>
                </>
              ) : (
                <>
                  <Form.Item>
                    <NewCustomButton
                      label={trans("CANCEL")}
                      icon={<NewCloseIcon className="cancel-icon" />}
                      onClick={onActionClick(null, null)}
                    />
                  </Form.Item>
                  <Form.Item>
                    <NewCustomButton
                      disabled={isCreateButtonDisabled}
                      type={ButtonTypes.primary}
                      label={"Create"}
                      icon={
                        <NewUpdateIcon
                          className={
                            !isCreateButtonDisabled
                              ? "update-icon-enabled"
                              : "update-icon-disabled"
                          }
                        />
                      }
                      onClick={handleSendClick}
                    />
                  </Form.Item>
                </>
              )}
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );

  return onlyContent ? (
    render
  ) : (
    <MainPage
      hasSider={showHeader ? false : true}
      activeKey={activeTab}
      openSubMenu={openSubMenu}
      onChange={handleTabChange}
      hubUserRole={hubRecord?.rolename}
    >
      {render}
    </MainPage>
  );
};

TaskForm.propTypes = {
  onlyContent: PropTypes.bool,
  parentBread: PropTypes.arrayOf(PropTypes.object),
  action: PropTypes.string,
  eventRecord: PropTypes.object,
  userInfo: PropTypes.object,
  contacts: PropTypes.arrayOf(PropTypes.object),
  fetchingContacts: PropTypes.bool,
  staffList: PropTypes.arrayOf(PropTypes.object),
  fetchingStaffList: PropTypes.bool,
  onActionClick: PropTypes.func,
};

export default TaskForm;
