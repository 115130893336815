/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import HubDetailsComponent from "./HubDetails.component";
import { Actions } from "@commscopemycloud/humaui/Utilities/Constants";
import { Contacts } from "../Contacts/Contacts";
import Schedule from "../Schedule/Schedule";
import ContactForm from "../Contacts/ContactForm";
import { getName } from "@commscopemycloud/humaui/Utilities/CommonUtilities";
import EditUserProfile from "../UserProfile/EditUserProfile";
import SendMessage from "../SendMessage/SendMessage";
import CallActivity from "../CallActivity/CallActivity";

const HubDetails = (props) => {
  console.debug("HubDetails props", props);
  const {
    hubRecord,
    parentBread,
    userHubsData,
    fetchUserHubs,
    openSubMenu = false,
    activeSubMenu,
    activeSubMenuContent,
    onActionClick: parentActionClick,
    userHubCall = false,
    sendMsgDisplay,
    navigationKey,
    offset,
    limit,
    showContactingStaff = false,
    loginUserTimeformat,
  } = props;

  const [action, setAction] = useState(null);
  const [record, setRecord] = useState(hubRecord);
  const [tabSwitch, setTabSwitch] = useState(activeSubMenu ?? "schedule");

  const rolename = hubRecord?.rolename;
  const onActionClick =
    (action, record, tabSwitch, refresh = false) =>
    () => {
      setAction(action);
      setRecord(record);
      setTabSwitch(tabSwitch);
    };

  const header = useMemo(() => {
    return (parentBread || []).concat({
      label: (
        <span
          style={{ fontWeight: "normal", fontSize: "24px", color: "#F1F1F1" }}
        >
          {getName(hubRecord) !== "Unknown"
            ? getName(hubRecord)
            : hubRecord.accountname}
        </span>
      ),
      onClick: onActionClick(null, null),
    });
  }, [hubRecord]);

  return !action ? (
    <HubDetailsComponent
      header={header}
      action={action}
      activeSubMenuContent={activeSubMenuContent}
      hubRecord={hubRecord}
      openSubMenu={openSubMenu}
      onActionClick={parentActionClick || onActionClick}
      rolename={rolename}
      tabSwitch={tabSwitch}
      userHubCall={userHubCall}
      activeSubMenu={activeSubMenu}
      sendMsgDisplay={sendMsgDisplay}
      navigationKey={navigationKey}
      userHubsData={userHubsData} 
      offset={offset}
      limit={limit} 
      showContactingStaff={showContactingStaff}
      loginUserTimeformat={loginUserTimeformat}
      fetchUserHubs={fetchUserHubs}
  />
  ) : action === Actions.listContacts ? (
    <Contacts parentBread={header} openSubMenu={openSubMenu} hubRecord={record} />
  ) : action === Actions.inviteContact ? (
    <ContactForm
      parentBread={header}
      hubRecord={record}
      onActionClick={onActionClick}
    />
  ) : action === Actions.editProfile ? (
    <EditUserProfile
      parentBread={header}
      hubRecord={record}
      onActionClick={onActionClick}
      fetchUserHubs={fetchUserHubs}
      openSubMenu={openSubMenu}
    />
  ) : action === Actions.addEvent ||
    action === Actions.editEvent ||
    action === Actions.startEvent ? (
    <Schedule
      action={action}
      parentBread={header}
      onActionClick={onActionClick}
      hubRecord={hubRecord}
      eventRecord={record}
      userHubsData={userHubsData}
      rolename={rolename}
      openSubMenu={openSubMenu}
      loginUserTimeformat={loginUserTimeformat}
    />
  ) : action === Actions.sendMessage ? (
    <SendMessage
      record={record}
      parentBread={header}
      onActionClick={onActionClick}
    />
  ) : action === Actions.callActivity ? (
    <CallActivity
      parentBread={header}
      onActionClick={onActionClick}
      hubRecord={hubRecord}
    />
  ) : null;
};

HubDetails.propTypes = {
  hubRecord: PropTypes.object,
};

export default HubDetails;
