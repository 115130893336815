import React from "react";

export const JoinCallIcon = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      className={className}
      width="144"
      height="57"
      viewBox="0 0 144 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <rect x="0.5" y="1" width="143" height="55" rx="27.5" fill="white" />
      <rect x="0.5" y="1" width="143" height="55" rx="27.5" stroke="#005D98" />
      <path
        d="M43.7798 30.4386C42.8294 29.3117 40.5361 28.9291 39.6374 28.9291C39.3688 28.9291 39.1415 28.9498 38.9246 29.0015C38.2324 29.1463 37.7159 29.5805 37.313 29.994C36.2077 29.2393 35.247 28.2882 34.4929 27.1819C34.9061 26.7787 35.34 26.2618 35.4846 25.5691C35.7325 24.3906 35.185 21.6922 34.059 20.7204C33.6045 20.3379 32.9847 20.1001 32.4062 20.1001H32.3648H30.1851C29.4517 20.1001 28.7699 20.4103 28.274 20.9479C27.8195 21.4544 27.5716 22.1161 27.6026 22.7881C27.6129 26.634 29.0695 30.1904 31.6934 32.8164C34.3069 35.432 37.8399 36.8794 41.6414 36.9001C41.6931 36.9001 41.7447 36.9001 41.7861 36.9001C42.4679 36.9001 43.0773 36.6623 43.5629 36.2178C43.7282 36.0627 43.8728 35.8869 43.9967 35.7008C44.255 35.2976 44.3996 34.8221 44.3996 34.3362V32.1341C44.41 31.5344 44.193 30.9141 43.7901 30.4386H43.7798ZM42.6022 34.3362C42.6022 34.5429 42.5092 34.7497 42.3439 34.9048C42.1993 35.0392 42.0133 35.1115 41.7861 35.1115C41.7551 35.1115 41.7241 35.1115 41.6931 35.1115C38.3564 35.1115 35.247 33.8503 32.964 31.5551C30.6603 29.2496 29.3897 26.1378 29.3897 22.7261C29.3691 22.5193 29.4414 22.3022 29.5963 22.1471C29.7513 21.9817 29.9579 21.8887 30.1645 21.8887H32.3752C32.3752 21.8887 32.3855 21.8887 32.3958 21.8887C32.5508 21.8887 32.7574 21.961 32.8917 22.0747C33.4185 22.5296 33.8937 24.4836 33.7388 25.197C33.6665 25.5278 33.3049 25.8586 32.9537 26.1791C32.8297 26.2928 32.7161 26.3962 32.6024 26.5099C32.3855 26.7271 32.3338 27.0682 32.4888 27.3474C33.5941 29.291 35.2057 30.9038 37.1581 32.01C37.2614 32.072 37.3854 32.1031 37.499 32.1031C37.6746 32.1031 37.8605 32.0307 37.9948 31.9066C38.0981 31.7929 38.2118 31.6792 38.3254 31.5551C38.6456 31.2036 38.9762 30.8418 39.3068 30.7694C39.3998 30.7487 39.5134 30.7384 39.6477 30.7384C40.5154 30.7384 42.0443 31.1519 42.4369 31.6171C42.5608 31.7619 42.6228 31.948 42.6228 32.1547V34.3568L42.6022 34.3362Z"
        fill="#005D98"
      />
      <path
        d="M60 34.192C58.096 34.192 56.32 33.136 56.32 30.672H58.832C58.832 31.44 59.344 31.824 60 31.824C60.624 31.824 61.168 31.456 61.168 30.736V22.64H63.712V30.896C63.712 33.216 61.952 34.192 60 34.192ZM69.329 34.176C67.025 34.176 65.121 32.352 65.121 30C65.121 27.648 67.025 25.824 69.329 25.824C71.633 25.824 73.537 27.648 73.537 30C73.537 32.352 71.633 34.176 69.329 34.176ZM69.329 31.92C70.337 31.92 71.105 31.136 71.105 30C71.105 28.864 70.337 28.08 69.329 28.08C68.321 28.08 67.553 28.864 67.553 30C67.553 31.136 68.321 31.92 69.329 31.92ZM76.2233 24.912C75.4073 24.912 74.7193 24.24 74.7193 23.408C74.7193 22.576 75.4073 21.904 76.2233 21.904C77.0713 21.904 77.7433 22.576 77.7433 23.408C77.7433 24.24 77.0713 24.912 76.2233 24.912ZM75.0233 34V26H77.4393V34H75.0233ZM79.4295 34V26H81.8455V26.816C82.2295 26.272 83.0295 25.824 84.0695 25.824C86.0375 25.824 87.1735 27.28 87.1735 29.376V34H84.7575V29.792C84.7575 28.688 84.3255 28.016 83.4615 28.016C82.5495 28.016 81.8455 28.672 81.8455 30.112V34H79.4295ZM96.3763 34.176C93.9443 34.176 92.1683 32.32 92.1683 30C92.1683 27.68 93.9443 25.824 96.3763 25.824C98.6483 25.824 100.072 27.248 100.504 29.12H98.0083C97.7203 28.48 97.2243 28 96.3763 28C95.3683 28 94.6003 28.752 94.6003 30C94.6003 31.248 95.3683 32 96.3763 32C97.2243 32 97.7203 31.52 98.0083 30.88H100.504C100.072 32.752 98.6483 34.176 96.3763 34.176ZM105.13 34.176C103.034 34.176 101.418 32.272 101.418 30C101.418 27.728 103.034 25.824 105.13 25.824C106.202 25.824 106.986 26.368 107.306 26.8V26H109.722V34H107.306V33.2C106.986 33.632 106.202 34.176 105.13 34.176ZM105.674 31.984C106.762 31.984 107.498 31.152 107.498 30C107.498 28.848 106.762 28.016 105.674 28.016C104.586 28.016 103.85 28.848 103.85 30C103.85 31.152 104.586 31.984 105.674 31.984ZM111.711 34V22H114.127V34H111.711ZM116.117 34V22H118.533V34H116.117Z"
        fill="#005D98"
      />
    </svg>
  );
};