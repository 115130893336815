import React from "react";

export const AddTaskIcon = (props) => {
  const { className = "", style = {} } = props;
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M22.7932 3.8856C22.3048 3.4243 21.7214 3.19365 21.0294 3.19365H19.7812V2.06755C19.7812 1.3756 19.2249 0.819336 18.533 0.819336C17.8411 0.819336 17.2848 1.3756 17.2848 2.06755V3.19365H15.6296V2.06755C15.6296 1.3756 15.0733 0.819336 14.3813 0.819336C13.6894 0.819336 13.1331 1.3756 13.1331 2.06755V3.19365H11.4779V2.06755C11.4779 1.3756 10.9216 0.819336 10.2297 0.819336C9.53773 0.819336 8.98146 1.3756 8.98146 2.06755V3.19365H7.32622V2.06755C7.32622 1.3756 6.76995 0.819336 6.07801 0.819336C5.38607 0.819336 4.8298 1.3756 4.8298 2.06755V3.19365H3.58158C2.90321 3.19365 2.30623 3.4243 1.8178 3.8856C1.32937 4.34689 1.08516 4.90316 1.08516 5.55441V22.1475C1.08516 22.7987 1.32937 23.355 1.8178 23.8163C2.30623 24.2776 2.88964 24.5083 3.58158 24.5083H13.6623H20.9616C22.359 24.5083 23.4852 23.3821 23.4852 21.9847V5.55441C23.4852 4.90316 23.2409 4.34689 22.7525 3.8856H22.7932ZM21.0294 22.1475H3.60872V5.55441H4.85693V6.50413C4.85693 7.19608 5.4132 7.75235 6.10514 7.75235C6.79709 7.75235 7.35336 7.19608 7.35336 6.50413V5.55441H9.0086V6.50413C9.0086 7.19608 9.56487 7.75235 10.2568 7.75235C10.9488 7.75235 11.505 7.19608 11.505 6.50413V5.55441H13.1603V6.50413C13.1603 7.19608 13.7165 7.75235 14.4085 7.75235C15.1004 7.75235 15.6567 7.19608 15.6567 6.50413V5.55441H17.3119V6.50413C17.3119 7.19608 17.8682 7.75235 18.5601 7.75235C19.2521 7.75235 19.8084 7.19608 19.8084 6.50413V5.55441H21.0566V22.1475H21.0294Z"
        fill="#005D98"
      />
      <path
        d="M7.38164 9.34375H6.21144C5.77898 9.34375 5.43555 9.68718 5.43555 10.1196V11.2898C5.43555 11.7223 5.77898 12.0657 6.21144 12.0657H7.38164C7.81411 12.0657 8.15753 11.7223 8.15753 11.2898V10.1196C8.15753 9.68718 7.81411 9.34375 7.38164 9.34375ZM7.43252 11.3026C7.43252 11.3026 7.40708 11.3534 7.38164 11.3534L6.16056 11.3026L6.21144 10.0815L7.43252 10.1324V11.3026Z"
        fill="#005D98"
      />
      <path
        d="M17.6467 10.1577H9.27722C9.03135 10.1577 8.83203 10.357 8.83203 10.6029V10.8319C8.83203 11.0777 9.03135 11.277 9.27722 11.277H17.6467C17.8926 11.277 18.0919 11.0777 18.0919 10.8319V10.6029C18.0919 10.357 17.8926 10.1577 17.6467 10.1577Z"
        fill="#005D98"
      />
      <path
        d="M17.6467 13.7695H9.27722C9.03135 13.7695 8.83203 13.9688 8.83203 14.2147V14.4437C8.83203 14.6895 9.03135 14.8889 9.27722 14.8889H17.6467C17.8926 14.8889 18.0919 14.6895 18.0919 14.4437V14.2147C18.0919 13.9688 17.8926 13.7695 17.6467 13.7695Z"
        fill="#005D98"
      />
      <path
        d="M17.5319 17.4209H9.16247C8.9166 17.4209 8.71729 17.6202 8.71729 17.8661V18.095C8.71729 18.3409 8.9166 18.5402 9.16247 18.5402H17.5319C17.7778 18.5402 17.9771 18.3409 17.9771 18.095V17.8661C17.9771 17.6202 17.7778 17.4209 17.5319 17.4209Z"
        fill="#005D98"
      />
      <path
        d="M7.38164 12.9824H6.21144C5.77898 12.9824 5.43555 13.3259 5.43555 13.7583V14.9285C5.43555 15.361 5.77898 15.7044 6.21144 15.7044H7.38164C7.81411 15.7044 8.15753 15.361 8.15753 14.9285V13.7583C8.15753 13.3259 7.81411 12.9824 7.38164 12.9824ZM7.43252 14.9285C7.43252 14.9285 7.40708 14.9794 7.38164 14.9794L6.16056 14.9285L6.21144 13.7074L7.43252 13.7583V14.9285Z"
        fill="#005D98"
      />
      <path
        d="M7.38164 16.6064H6.21144C5.77898 16.6064 5.43555 16.9499 5.43555 17.3823V18.5525C5.43555 18.985 5.77898 19.3284 6.21144 19.3284H7.38164C7.81411 19.3284 8.15753 18.985 8.15753 18.5525V17.3823C8.15753 16.9499 7.81411 16.6064 7.38164 16.6064ZM7.43252 18.5525C7.43252 18.5525 7.40708 18.6034 7.38164 18.6034L6.16056 18.5525L6.21144 17.3315L7.43252 17.3823V18.5525Z"
        fill="#005D98"
      />
    </svg>
  );
};