import "../../styles/WellnessDatePicker.less";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import React, { useState, useRef, useEffect } from "react";
import { HSNewCalendarIcon } from "./Icons";
import { Tooltip, DatePicker, ConfigProvider} from "antd";
import { getCurrentUser } from "@commscopemycloud/humaui/Store/authStore";
import useUserInfo from "../Hooks/useUserInfo";
import { useSelector } from "react-redux";
import { getDateFormat } from "@commscopemycloud/humaui/Utilities/DateTime";
import enGB from "antd/lib/locale/en_GB"; // Import Ant Design's English GB locale
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import updateLocale from "dayjs/plugin/updateLocale"; // Import the plugin

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(updateLocale);


dayjs.updateLocale("en", {
  weekStart: 1, // Monday
});

// Get date format based on user locale
const getDateFormatByLocale = (locale) => {
  const formatter = new Intl.DateTimeFormat(locale);
  const parts = formatter.formatToParts(new Date());
  return parts
    .map((part) => {
      switch (part.type) {
        case "day":
          return "DD";
        case "month":
          return "MM";
        case "year":
          return "YYYY";
        default:
          return part.value;
      }
    })
    .join("");
};

// Function to remove default tooltips
const removeDefaultTooltips = () => {
  const pickerCells = document.querySelectorAll(".ant-picker-cell");
  pickerCells.forEach((cell) => {
    cell.removeAttribute("title"); // Remove default title attribute
  });
};

export const WellnessDatePicker = (props) => {
  const currentUser = useSelector(getCurrentUser);
  const [userInfo] = useUserInfo({ useruuid: currentUser?.useruuid });

  // Determine user locale with fallback
  const userLocale = userInfo.locale || "en-US";
  const dateFormatUserLocale = getDateFormatByLocale(userLocale);

  const {
    onDateSelect,
    DateFormat,
    required,
    timeZone,
    defaultValue,
    defaultText,
    name,
    disabledDate,
    value,
  } = props;

  const datePickerRef = useRef(null);
  const wrapperRef = useRef(null); // Ref for the wrapper div
  const [pickerOpen, setPickerOpen] = useState(false);
  const [hover, setHover] = useState(null);
  const [selectedDate, setSelectedDate] = useState(defaultValue);

  const handleDateSelect = (value) => {
    console.log('value-->>83',value);
    setPickerOpen(false);
    setSelectedDate(value);
    onDateSelect(value);
  };

  const handleOnBlur = () => {
    setPickerOpen(false);
  };

  useEffect(() => {
    // setPickerOpen(false);
    setSelectedDate(defaultValue);
    // onDateSelect(value);
  },[onDateSelect])

  useEffect(() => {
    if (datePickerRef.current) {
      datePickerRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (pickerOpen) {
      // Remove tooltips immediately when the picker opens
      removeDefaultTooltips();
    }
  }, [pickerOpen]);

  const handleWrapperClick = () => {
    if (!pickerOpen) {
      setPickerOpen(true);
    }
  };
  const handleWrapperBlur = (event) => {
    // Check if the blur event is happening outside the DatePicker and wrapper
    const isDatePickerButton =
      event.target.classList.contains("ant-picker-header-super-prev-btn") ||
      event.target.classList.contains("ant-picker-header-super-next-btn") ||
      event.target.closest(".ant-picker-panel");
    if (
      !wrapperRef.current.contains(event.target) &&
      datePickerRef.current !== event.target &&
      !isDatePickerButton
    ) {
      handleOnBlur(); // Call handleOnBlur if clicked outside DatePicker and wrapper
    }
  };

  const onOpenChangePicker = (pickerOpen) => {
    if (!pickerOpen) {
      setPickerOpen(false);
    }
  };

  const handlePanelChange = () => {
    setTimeout(() => {
      removeDefaultTooltips(); // Remove tooltips when month or year changes
    }, 500);
  };

  const dateFormat = selectedDate ? getDateFormat(selectedDate, timeZone) : "";
  let day = dateFormat ? dateFormat.day : defaultText;
  day = dateFormat.dateFormat ? day : defaultText;

  return (
    <div
      className="hs-datepicker-wrapper"
      ref={wrapperRef}
      onClick={handleWrapperClick}
      onBlur={handleWrapperBlur}
    >
      <div className="hs-datepicker-content">
        <div className="hs-datepicker-date">
          <span className="hs-datepicker-day">{day}</span>
          &nbsp;{dateFormat.dateFormat ? dateFormat.dateFormat : ""}
          <div className="hs-datepicker-calendar">
            <Tooltip title="Select Date">
              <div className="action">
                <ConfigProvider locale={enGB}>
                  <DatePicker
                    ref={datePickerRef}
                    className={!pickerOpen ? "hs-datepicker-hide" : ""}
                    required={required}
                    allowClear={false}
                    open={pickerOpen}
                    name={name}
                    disabledDate={disabledDate}
                    onPanelChange={handlePanelChange}
                    format={dateFormatUserLocale}
                    onChange={(value) => handleDateSelect(value)}
                    onOpenChange={onOpenChangePicker}
                    value={dayjs(value).isValid() ? value : null}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    showNow={false}
                    cellRender={(current) => {
                      const formattedCurrentDate =
                        dayjs(current).format(dateFormatUserLocale);
                      return (
                        <Tooltip
                          title={
                            hover === formattedCurrentDate
                              ? formattedCurrentDate
                              : ""
                          }
                          placement="top"
                        >
                          <div
                            className="ant-picker-cell-inner"
                            title={null} // Explicitly set title to null to remove it
                            onMouseEnter={() => setHover(formattedCurrentDate)}
                            onMouseLeave={() => setHover(null)}
                          >
                            {current.date()}
                          </div>
                        </Tooltip>
                      );
                    }}
                  />
                </ConfigProvider>
                <div>
                  <HSNewCalendarIcon />
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};