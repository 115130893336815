import React, { useState } from "react";
import { Button, Modal } from "antd";
import {
  CloseIcon,
  DeviceInventoryIcon,
  PairedIcon,
  UnpairedIcon,
} from "../Common/Icons";
import DeviceGeneralInfoCard from "./DeviceGeneralInfoCard";
import {
  DeviceConnectionIconInfo,
  DeviceInfo,
  StatusInfo,
} from "./DeviceInventoryList";
import EM200ModalInfo from "./EM200ModalInfo";
import HC200ModalInfo from "./HC200ModalInfo";
import RemoteControlModalInfo from "./RemoteControlModalInfo";
import MedicalDeviceModalInfo from "./MedicalDeviceModalInfo";
import { devicesInventory } from "../../utilities/data";

const { device_types } = devicesInventory;

export const getConfirmationMessage = (action) => {
  return `Are you sure you want to ${action}?`;
};

const ConfirmationModal = ({
  visible,
  onConfirm = () => {},
  onCancel,
  title,
  description,
  customLabel,
  customIcon,
  activeDeviceAction,
}) => (
  <Modal
    title={title}
    open={visible}
    onOk={onConfirm}
    onCancel={onCancel}
    closable={false}
    className={`pop-up-modal-container ${
      activeDeviceAction === "unpair_em200" ||
      activeDeviceAction === "unpair_rcu" ||
      activeDeviceAction === "unpair_medical_device"
        ? "pop-up-modal-container-medium"
        : ""
    }`}
    footer={null}
  >
    <p className="custom-modal-description">{description}</p>
    <div
      className={`custom-footer ${
        activeDeviceAction === "unpair_em200" ||
        activeDeviceAction === "unpair_rcu" ||
        activeDeviceAction === "unpair_medical_device"
          ? "custom-footer-medium"
          : ""
      }`}
    >
      <Button className="custom-no-button" onClick={onCancel}>
        <CloseIcon className="custom-cancel-icon" />
        <span className="custom-no-label">Cancel</span>
      </Button>
      <Button
        className="custom-yes-button"
        onClick={() => {
          onConfirm();
          onCancel(); // Close the modal after confirmation
        }}
      >
        {customIcon && (
          <span className="custom-icon-wrapper">{customIcon}</span>
        )}
        <span className="custom-yes-label">{customLabel}</span>
      </Button>
    </div>
  </Modal>
);

const DeviceDetailsHeader = ({
  device,
  isHC200,
  deviceStatus,
  connectionStatus,
  timezone,
  loginUserTimeformat,
  userConfig,
  selectedLanguage,
}) => (
  <div className="device-info-block-container">
    <div className="device-information-details">
      <DeviceInfo device={device} isModalView={true} userConfig={userConfig} selectedLanguage={selectedLanguage}/>
      {!isHC200 && (
        <div className="paired-status-detail">
          {device?.paired_status === "PAIRED" ||
          device?.paired_status === null ? (
            <PairedIcon />
          ) : (
            <UnpairedIcon label="Unpaired" />
          )}
        </div>
      )}
    </div>
    <div className="device-connection-info-container">
      <StatusInfo
        lastConnectTime={device?.lastconnecttime}
        lastDisconnectTime={device?.lastdisconnecttime}
        device={device}
        timezone={timezone}
        loginUserTimeformat={loginUserTimeformat}
      />
      <DeviceConnectionIconInfo
        device={device}
        deviceStatus={deviceStatus}
        connectionStatus={connectionStatus}
      />
    </div>
  </div>
);

const DeviceSpecificModalContent = ({
  device,
  performAction,
  actionsData,
  hubRecord,
  isActionModalVisible,
  hideModal,
  isTVDevice,
  activeDeviceAction,
  handleDeviceActionClick,
  timezone,
  deviceStatus,
  loginUserTimeformat,
  userConfig,
  removeDevice,
  handleCancel,
  setIsSmartCameraModalOpen,
}) => {
  // Dynamically determine the component based on the device type
  const getComponentForDevice = () => {
    if (
      device_types?.smart_camera_devices?.models?.includes(device?.modelnumber)
    ) {
      return HC200ModalInfo;
    }
    if (
      device_types?.remote_control_devices?.models?.includes(
        device?.modelnumber
      )
    ) {
      return RemoteControlModalInfo;
    }
    if (device_types?.sensor_devices?.models?.includes(device?.modelnumber)) {
      return EM200ModalInfo;
    }
    return MedicalDeviceModalInfo; // Default case
  };

  const Component = getComponentForDevice();

  // in case no specific component is found
  if (!Component) {
    return;
  }

  return (
    <Component
      device={device}
      hubRecord={hubRecord}
      performAction={performAction}
      actionsData={actionsData}
      isTVDevice={isTVDevice}
      ConfirmationModal={ConfirmationModal}
      hideModal={hideModal}
      isActionModalVisible={isActionModalVisible}
      activeDeviceAction={activeDeviceAction}
      handleDeviceActionClick={handleDeviceActionClick}
      timezone={timezone}
      deviceStatus={deviceStatus}
      loginUserTimeformat={loginUserTimeformat}
      userConfig={userConfig}
      removeDevice={removeDevice}
      handleCancel={handleCancel}
      setIsSmartCameraModalOpen={setIsSmartCameraModalOpen}
    />
  );
};

export const isDeviceOffline = (device) => {
  // Check if the device is of type hc200
  if (device_types.smart_camera_devices.models.includes(device?.modelnumber)) {
    return device?.deviceconnectstatus?.toLowerCase() === "disconnected";
  }
  // For rc devices, use the device_connection_status parameter
  if (device_types.remote_control_devices.models.includes(device.modelnumber)) {
    return device?.device_connection_status !== true;
  }
  // For em devices, use the device_connected parameter
  if(device_types.sensor_devices.models.includes(device?.modelnumber)){
    return device?.device_connected !== true;
  }
  // Default case
  return true; // Assume offline if no valid type is matched
};

const DeviceInventoryModal = ({
  isModalVisible,
  handleOk,
  handleCancel,
  device,
  selectedDeviceInfo,
  deviceStatus,
  timezone,
  connectionStatus,
  hubRecord,
  performAction,
  actionsData,
  accessoriesData,
  loginUserTimeformat,
  userConfig,
  removeDevice,
  selectedLanguage,
  setIsSmartCameraModalOpen,
}) => {
  const isHC200 = device_types?.smart_camera_devices?.models?.includes(
    device?.modelnumber
  );
  const isTVDevice = accessoriesData.find((d) => d.modelnumber === "tv");

  const [isActionModalVisible, setIsActionModalVisible] = useState({
    unpair_rcu: false,
    find_me: false,
    unpair_em200: false,
    factory_reset_hc200: false,
    restart_hc200: false,
    check_for_updates_hc200: false,
    unpair_medical_device : false,
    remove_device : false,
  });

  const [activeDeviceAction, setactiveDeviceAction] = useState(null);

  const showModal = (modalKey) => {
    setIsActionModalVisible((prevState) => ({
      ...prevState,
      [modalKey]: true,
    }));
  };

  const hideModal = (modalKey) => {
    setIsActionModalVisible((prevState) => ({
      ...prevState,
      [modalKey]: false,
    }));
  };

  const handleDeviceActionClick = (action) => {
    setactiveDeviceAction(action);
    showModal(action);
  };

  return (
    <Modal
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      closable={false}
      centered
      className="device-modal-container"
      style={{ minWidth: "800px" }} // Added min-width
    >
      <div className="modal-container">
        <div className="device-modal-header-container">
          <div className="device-header-icon-title">
            <div className="device-header-icon">
              <DeviceInventoryIcon
                style={{ fill: "#113E57", width: "29px", height: "32px" }}
              />
            </div>
            <div className="device-header-title">Device details</div>
          </div>
          <div className="modal-close-button" onClick={handleCancel}>
            <CloseIcon className="modal-close-icon" />
          </div>
        </div>

        <div className="device-card-container">
          <div
            className={`device-page-container ${
              device?.modelnumber &&
              device_types?.smart_camera_devices?.models?.includes(
                device?.modelnumber
              )
                ? "hc200-styled-device-container "
                : ""
            }`}
          >
            <DeviceDetailsHeader
              device={device}
              isHC200={isHC200}
              timezone={timezone}
              deviceStatus={deviceStatus}
              connectionStatus={connectionStatus}
              loginUserTimeformat={loginUserTimeformat}
              selectedLanguage={selectedLanguage}
              userConfig={userConfig}
            />

            <DeviceGeneralInfoCard
              device={device}
              isViewMoreClicked={true}
              selectedDeviceInfo={selectedDeviceInfo}
            />
          </div>

          <div className="device-info-container">
            <DeviceSpecificModalContent
              device={device}
              performAction={performAction}
              actionsData={actionsData}
              hubRecord={hubRecord}
              isActionModalVisible={isActionModalVisible}
              hideModal={hideModal}
              isTVDevice={isTVDevice}
              activeDeviceAction={activeDeviceAction}
              handleDeviceActionClick={handleDeviceActionClick}
              timezone={timezone}
              deviceStatus={deviceStatus}
              loginUserTimeformat={loginUserTimeformat}
              userConfig={userConfig}
              removeDevice={removeDevice}
              handleCancel={handleCancel}
              setIsSmartCameraModalOpen={setIsSmartCameraModalOpen}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeviceInventoryModal;
